import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import BankSearch from 'components/configuration/customization/features/bankSearch/BankSearch';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';
import { IBanksModalProps } from 'components/common/modals/types';

const BanksModal: React.FC<IBanksModalProps> = ({
    t,
    title,
    open,
    onClose,
    FeatureStore,
    BanksModalStore,
}) => {
    const { handleBanksSave } = FeatureStore!;
    const { checkIds, displayFeatures } = BanksModalStore!;

    const _onSave = async () => {
        handleBanksSave(checkIds, displayFeatures.country);
        await onClose();
    };

    return (
        <DialogWrapper
            open={open}
            onClose={onClose}
            hideDivider
            maxWidth="md"
            className={'CommonModal BanksModal'}>
            <h2 className={'title'}>{title}</h2>
            <Grid container spacing={4}>
                <BankSearch t={t} FeatureStore={BanksModalStore!} isEditable />
            </Grid>
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs={2}>
                    <ButtonWrapper color="secondary" className="closeButton" onClick={onClose}>
                        {t('common:cancel')}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={2}>
                    <ButtonWrapper className="confirmButton" onClick={() => _onSave()}>
                        {t('common:save')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
        </DialogWrapper>
    );
};

export default observer(BanksModal);
