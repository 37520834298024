import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import { ADMIN, DEVELOPER, SUPER_ADMIN } from '../../../constants/roles';
import ApiKeyModal from '../../common/modals/authentication-keys/ApiKey';
import PasswordField from '../../common/passwordField/PasswordField';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import ApiKeyController from './ApiKey.controller';
import Modal from 'components/common/modals/modal/Modal';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import successIcon from '../../../images/password-reset.svg';
import Spinner from 'components/common/spinner/Spinner';
import useAppFeatureFlag from 'hooks/useAppFeatureFlag';

const { publicRuntimeConfig } = require('next/config').default();

function ApiKey({ AuthenticationKeysStore, AppStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'authentication-keys');
    const {
        value: authApiKeyAllowDisable,
        loading: authApiKeyAllowDisableLoading,
    } = useAppFeatureFlag('authApiKeyAllowDisable', false);

    const {
        handleOpen,
        apiKey,
        setAPIKey,
        ApiKeyPasswordFieldStore,
        apiKeyLoading,
        confirmDisableApiKeyModalStore,
        disableApiKeyLoading,
        handleApiKeyAuthEnableChange,
        handleApiKeyAuthStatusSave,
        handleDisableCancel,
        handleDisableConfirm,
        isApiKeyAuthEnabled,
        loading,
        shallShowDisableApiKeyCheckbox,
        apiKeyStatus,
        successModalStore,
    } = AuthenticationKeysStore;
    const { user } = AppStore;
    const allowedRolesToDisable = [ADMIN, SUPER_ADMIN];

    useEffect(() => {
        const cancellablePromise = setAPIKey(AppStore);
        return () => {
            cancellablePromise.cancel();
        };
    }, []);

    if (loading) return <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />;

    return (
        <div className="ApiKeyMain">
            {!authApiKeyAllowDisableLoading &&
                authApiKeyAllowDisable &&
                allowedRolesToDisable.includes(user.role) && (
                    <div className="generalSection">
                        <h2>{t('general')}</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleApiKeyAuthEnableChange}
                                            name={'enableApiKeyAuth'}
                                            checked={isApiKeyAuthEnabled}
                                            disabled={!shallShowDisableApiKeyCheckbox}
                                        />
                                    }
                                    label={t('enableApiKeyAuth')}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={12}>
                                <ButtonWrapper
                                    onClick={() => handleApiKeyAuthStatusSave(AppStore)}
                                    className={'saveBtn'}
                                    disabled={apiKeyLoading || !shallShowDisableApiKeyCheckbox}
                                    loading={disableApiKeyLoading}>
                                    {t('save')}
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                        <Modal
                            Store={confirmDisableApiKeyModalStore}
                            title={t('confirmDisableTitle')}
                            description={t('confirmDisableDescription')}
                            buttons={
                                <>
                                    <ButtonWrapper
                                        color="secondary"
                                        className="modalConfirmButton"
                                        loading={disableApiKeyLoading}
                                        onClick={() => handleDisableConfirm(AppStore)}>
                                        {t('confirmDisableModalConfirm')}
                                    </ButtonWrapper>
                                    <ButtonWrapper
                                        className="modalCloseButton"
                                        onClick={handleDisableCancel}>
                                        {t('confirmDisableModalCancel')}
                                    </ButtonWrapper>
                                </>
                            }
                        />
                    </div>
                )}
            {apiKeyStatus && (
                <div className="keyManagementSection">
                    <h2>{t('keyManagement')}</h2>
                    <div className={'instructions'}>
                        {user.role === DEVELOPER && !apiKey ? (
                            <p className={'noApiKey'}>{t('noApiKeyMessage')}</p>
                        ) : (
                            <>
                                <p className={'apiKeyInstructions'}>{t('apiKeyInstructions')}</p>
                                <p className={'buttonTitle'}>{t(ApiKeyController.apiKeyType)}</p>
                            </>
                        )}
                    </div>
                    <div className="keys">
                        <Grid container spacing={6}>
                            {!publicRuntimeConfig.features.prod && apiKey && (
                                <>
                                    <Grid item xs={12} md={7}>
                                        <PasswordField
                                            className={'apiKeyField'}
                                            label={t('apiKeyLabel')}
                                            value={apiKey}
                                            name={'apiKey'}
                                            PasswordFieldStore={ApiKeyPasswordFieldStore}
                                            onChange={Function.prototype}
                                            dontShowReq
                                            readOnly
                                            required
                                        />
                                    </Grid>
                                    <Grid item md={5} />
                                </>
                            )}
                            {user.role !== DEVELOPER && (
                                <Grid item lg={3} md={4} sm={12}>
                                    <ButtonWrapper
                                        onClick={() => handleOpen(AppStore)}
                                        className={'getApiKey'}
                                        disabled={apiKeyLoading}>
                                        {t('generateApiKey')}
                                    </ButtonWrapper>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    <ApiKeyModal />
                </div>
            )}
            <GenericSuccessModal
                Store={successModalStore}
                title={t('successModalTitle')}
                subtitle={t('successModalDescription')}
                buttonText={t('successModalButton')}
                icon={successIcon}
            />
        </div>
    );
}

ApiKey.propTypes = {
    AppStore: PropTypes.object.isRequired,
    AuthenticationKeysStore: PropTypes.object.isRequired,
};

export default inject('AuthenticationKeysStore', 'AppStore')(observer(ApiKey));
