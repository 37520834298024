import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import ModalStore from '../../common/modals/ModalStore';
import _isEqual from 'lodash/isEqual';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class GeneralStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
        this.GenericErrorStore = new ModalStore();
    }

    @observable
    forceMfa = {
        login: false,
        refund: false,
    };
    @observable
    defaultForceMfa = {
        login: false,
        refund: false,
    };
    @observable
    profileName = '';
    @observable
    defaultProfileName = '';
    @observable
    loading = false;
    @observable
    profile = {
        profileName: '',
    };
    @observable
    defaultForm = {
        profileName: '',
        forceMfa: false,
    };
    @observable
    isFormValid = false;

    isDefaultFields = () => {
        const forceMFAUnchanged = _isEqual(this.forceMfa, this.defaultForceMfa);
        const nameUnchanged = this.profileName === this.defaultProfileName;
        return forceMFAUnchanged && nameUnchanged;
    };

    @action
    setFieldsDefault() {
        this.forceMfa = this.defaultForceMfa;
        this.profileName = this.defaultProfileName;
    }
    @computed
    get saveDisabled() {
        return this.isDefaultFields() || !this.profileName;
    }

    @action
    handleChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'profileName') {
            this.profileName = value;
            this.isFormValid = this.validateForm();
        } else {
            this.forceMfa[name] = checked;
        }
    };

    validateForm = () => {
        return !!this.profileName;
    };

    @action
    handleSubmit = flow(
        function* (memberId) {
            try {
                if (this.profileName) {
                    if (this.profileName !== this.defaultProfileName) {
                        this.loading = true;
                        const parentEmail = encodeURIComponent(this.rootStore.memberEmail);
                        const scope = this.rootStore.scope;
                        yield axios.post(`/api/member/${memberId}/profile`, {
                            profileName: this.profileName,
                            parentEmail,
                            scope,
                        });
                    }

                    if (!_isEqual(this.defaultForceMfa, this.forceMfa)) {
                        //it will overwrite if any of them are same causing no issue
                        yield axios.post(`/api/member/${memberId}/force-mfa`, {
                            forceMfaLogin: this.forceMfa.login,
                            forceMfaRefund: this.forceMfa.refund,
                        });
                        this.defaultForceMfa = this.forceMfa;
                    }

                    yield this.handleFetch(memberId);
                    this.isFormValid = false;
                    this.GenericSuccessStore.openModal();
                }
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleFetch = flow(
        function* (memberId) {
            this.loading = true;
            try {
                const response = yield axios.get(`/api/member/${memberId}/profile`);
                if (this.rootStore.hasForceMfa) {
                    const forceMfaResp = yield axios.get(`/api/member/${memberId}/force-mfa`);
                    this.forceMfa = {
                        login: forceMfaResp.data.forceMfaLogin,
                        refund: forceMfaResp.data.forceMfaRefund,
                    };
                }
                this.profile.profileName = response.data.profileName;
                this.profileName = this.profile.profileName;
                this.defaultForceMfa = { ...this.forceMfa };
                this.defaultProfileName = this.profileName;
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
}

export default GeneralStore;
