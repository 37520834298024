import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../../base/App.controller';
import warning from '../../../../../images/warning.svg';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import DialogWrapper from '../../../wrappers/DialogWrapper';
import { IDeletePublicKeyModal } from '../../types';
import { Itranslation } from 'types';

const styles: any = () => {
    return {
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '100%',
        },
        closeButton: {
            width: '40%',
            color: 'var(--milled-wine)',
            '&:hover': {
                color: 'var(--revolver) !important',
            },
        },
        deleteButton: {
            width: '40%',
            backgroundColor: 'var(--bittersweet)',
            '&:hover, &:focus': {
                backgroundColor: 'var(--bittersweet)',
            },
        },
        firstDescription: {
            marginBottom: '15px',
        },
        root: {
            textAlign: 'center',
        },
    };
};

const DeletePublicKeyModal: React.FC<IDeletePublicKeyModal> = ({
    AppStore,
    AuthenticationKeysStore,
    classes,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'authentication-keys');
    const { publicKeyLoading, handleDelete, DeletePublicKeyModal } = AuthenticationKeysStore!;

    return (
        <DialogWrapper
            open={DeletePublicKeyModal.isOpen}
            onClose={DeletePublicKeyModal.closeModal}
            className={clsx(classes.root, 'DeletePublicKeyModal')}
            hideDivider>
            <img className="delete-warning-img" src={warning} />
            <h2>{t('modalTitle')}</h2>
            <div>{t('warningModalDescription')}</div>
            <div className={classes.buttonContainer}>
                <ButtonWrapper
                    variant="contained"
                    color="secondary"
                    className={classes.closeButton}
                    fullWidth={false}
                    onClick={DeletePublicKeyModal.closeModal}>
                    {t('common:cancel')}
                </ButtonWrapper>
                <ButtonWrapper
                    variant="contained"
                    color="primary"
                    className={classes.deleteButton}
                    loading={publicKeyLoading}
                    fullWidth={false}
                    onClick={() => handleDelete(AppStore)}>
                    {t('delete')}
                </ButtonWrapper>
            </div>
        </DialogWrapper>
    );
};

export default withStyles(styles, { name: 'DeletePublicKeyModal' })(
    inject('AppStore', 'AuthenticationKeysStore')(observer(DeletePublicKeyModal)),
);
