/**
 * Remove any key redundancy from an array
 *
 * @param data
 * @param key
 * @returns unique array
 */
export default function getUniqueRecordsByKey(data, key) {
    const keyVal = {};
    data.map((d) => {
        keyVal[d[key]] = d;
    });
    const arr = [];
    Object.keys(keyVal).map((k) => {
        arr.push(keyVal[k]);
    });
    return arr;
}
