module.exports = {
    TERMS_AND_CONDITIONS: [
        'aisp.consent.termsAndConditions.loader',
        'pisp.consent.termsAndConditions.loader',
        'cbpii.consent.termsAndConditions.loader',
    ],
    CONSENT: ['aisp.consent.text', 'pisp.consent.text'],
    TERMS_AND_CONDITIONS_KEYS: {
        AIS: 'aisp.consent.termsAndConditions.loader',
        PIS: 'pisp.consent.termsAndConditions.loader',
        VARIABLE_RECURRING_PAYMENT: 'vrp.consent.termsAndConditions.loader',
        CBPII: 'cbpii.consent.termsAndConditions.loader',
    },
};
