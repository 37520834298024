import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Webhook from './Webhook';

function TabWebhook({ ConfigurationStore }) {
    return (
        <div className={'TabWebhook'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <Webhook WebhookStore={ConfigurationStore.WebhookStore} />
                </Grid>
            </Grid>
        </div>
    );
}

TabWebhook.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabWebhook));
