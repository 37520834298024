const { publicRuntimeConfig } = require('next/config').default();
const tosLink = `${publicRuntimeConfig.paymentApp}/session/{{SESSION_ID}}/terms-and-conditions`;
const ppLink = 'https://token.io/policies/web-app-privacy-policy';
export const getLegalTextMessages = {
    aisLegal: {
        en: `<div>By clicking on the bank you agree to <a href="${tosLink}" target="_blank">{BUSINESS_NAME}\'s Terms and Conditions</a> and <a href="${ppLink}" target="_blank">{BUSINESS_NAME}\'s Privacy and Cookie notice.</a></div>`,
        fr: `<div>En cliquant sur la banque, vous acceptez les <a href="${tosLink}" target="_blank">Conditions générales de {BUSINESS_NAME}</a> et la <a href="${ppLink}" target="_blank">Politique de confidentialité et de cookies de {BUSINESS_NAME}</a>.</div>`,
        cs: `<div>Kliknutím na banku souhlasíte s <a href="${tosLink}" target="_blank">podmínkami a ujednáními {BUSINESS_NAME}</a> a <a href="${ppLink}" target="_blank">zásadami ochrany osobních údajů a používání cookies {BUSINESS_NAME}</a>.</div>`,
        nl: `<div>Door op de bank te klikken, gaat u akkoord met de <a href="${tosLink}" target="_blank">algemene voorwaarden van {BUSINESS_NAME}</a> en de <a href="${ppLink}" target="_blank">privacy- en cookieverklaring van {BUSINESS_NAME}</a>.</div>`,
        de: `<div>Durch Klicken auf die Bank stimmen Sie den <a href="${tosLink}" target="_blank">Allgemeinen Geschäftsbedingungen von {BUSINESS_NAME}</a> und der <a href="${ppLink}" target="_blank">Datenschutz- und Cookie-Richtlinie von {BUSINESS_NAME}</a> zu.</div>`,
        hu: `<div>A bankra kattintva elfogadja a <a href="${tosLink}" target="_blank">{BUSINESS_NAME} felhasználási feltételeit</a> és a <a href="${ppLink}" target="_blank">{BUSINESS_NAME} adatvédelmi és cookie-szabályzatát</a>.</div>`,
        it: `<div>Cliccando sulla banca, accetti i <a href="${tosLink}" target="_blank">Termini e condizioni di {BUSINESS_NAME}</a> e l'<a href="${ppLink}" target="_blank">Informativa sulla privacy e sui cookie di {BUSINESS_NAME}</a>.</div>`,
        pl: `<div>Klikając na bank, zgadzasz się na <a href="${tosLink}" target="_blank">Warunki {BUSINESS_NAME}</a> oraz <a href="${ppLink}" target="_blank">Politykę prywatności i plików cookie {BUSINESS_NAME}</a>.</div>`,
        pt: `<div>Ao clicar no banco, você concorda com os <a href="${tosLink}" target="_blank">Termos e Condições de {BUSINESS_NAME}</a> e com a <a href="${ppLink}" target="_blank">Política de Privacidade e Cookies de {BUSINESS_NAME}</a>.</div>`,
        si: `<div>S klikom na banko se strinjate s <a href="${tosLink}" target="_blank">pogoji in določili {BUSINESS_NAME}</a> ter z <a href="${ppLink}" target="_blank">politiko zasebnosti in piškotkov {BUSINESS_NAME}</a>.</div>`,
        es: `<div>Al hacer clic en el banco, aceptas los <a href="${tosLink}" target="_blank">Términos y Condiciones de {BUSINESS_NAME}</a> y la <a href="${ppLink}" target="_blank">Política de Privacidad y Cookies de {BUSINESS_NAME}</a>.</div>`,
    },
    pisLegal: {
        en: `<div>By clicking on the bank you agree to <a href="${tosLink}" target="_blank">{BUSINESS_NAME}\'s Terms and Conditions</a> and <a href="${ppLink}" target="_blank">{BUSINESS_NAME}\'s Privacy and Cookie notice.</a></div>`,
        fr: `<div>En cliquant sur la banque, vous acceptez les <a href="${tosLink}" target="_blank">Conditions générales de {BUSINESS_NAME}</a> et la <a href="${ppLink}" target="_blank">Politique de confidentialité et de cookies de {BUSINESS_NAME}</a>.</div>`,
        cs: `<div>Kliknutím na banku souhlasíte s <a href="${tosLink}" target="_blank">podmínkami a ujednáními {BUSINESS_NAME}</a> a <a href="${ppLink}" target="_blank">zásadami ochrany osobních údajů a používání cookies {BUSINESS_NAME}</a>.</div>`,
        nl: `<div>Door op de bank te klikken, gaat u akkoord met de <a href="${tosLink}" target="_blank">algemene voorwaarden van {BUSINESS_NAME}</a> en de <a href="${ppLink}" target="_blank">privacy- en cookieverklaring van {BUSINESS_NAME}</a>.</div>`,
        de: `<div>Durch Klicken auf die Bank stimmen Sie den <a href="${tosLink}" target="_blank">Allgemeinen Geschäftsbedingungen von {BUSINESS_NAME}</a> und der <a href="${ppLink}" target="_blank">Datenschutz- und Cookie-Richtlinie von {BUSINESS_NAME}</a> zu.</div>`,
        hu: `<div>A bankra kattintva elfogadja a <a href="${tosLink}" target="_blank">{BUSINESS_NAME} felhasználási feltételeit</a> és a <a href="${ppLink}" target="_blank">{BUSINESS_NAME} adatvédelmi és cookie-szabályzatát</a>.</div>`,
        it: `<div>Cliccando sulla banca, accetti i <a href="${tosLink}" target="_blank">Termini e condizioni di {BUSINESS_NAME}</a> e l'<a href="${ppLink}" target="_blank">Informativa sulla privacy e sui cookie di {BUSINESS_NAME}</a>.</div>`,
        pl: `<div>Klikając na bank, zgadzasz się na <a href="${tosLink}" target="_blank">Warunki {BUSINESS_NAME}</a> oraz <a href="${ppLink}" target="_blank">Politykę prywatności i plików cookie {BUSINESS_NAME}</a>.</div>`,
        pt: `<div>Ao clicar no banco, você concorda com os <a href="${tosLink}" target="_blank">Termos e Condições de {BUSINESS_NAME}</a> e com a <a href="${ppLink}" target="_blank">Política de Privacidade e Cookies de {BUSINESS_NAME}</a>.</div>`,
        si: `<div>S klikom na banko se strinjate s <a href="${tosLink}" target="_blank">pogoji in določili {BUSINESS_NAME}</a> ter z <a href="${ppLink}" target="_blank">politiko zasebnosti in piškotkov {BUSINESS_NAME}</a>.</div>`,
        es: `<div>Al hacer clic en el banco, aceptas los <a href="${tosLink}" target="_blank">Términos y Condiciones de {BUSINESS_NAME}</a> y la <a href="${ppLink}" target="_blank">Política de Privacidad y Cookies de {BUSINESS_NAME}</a>.</div>`,
    },
    vrpLegal: {
        en: `<div>By clicking on the bank you agree to <a href="${tosLink}" target="_blank">{BUSINESS_NAME}\'s Terms and Conditions</a> and <a href="${ppLink}" target="_blank">{BUSINESS_NAME}\'s Privacy and Cookie notice.</a></div>`,
        fr: `<div>En cliquant sur la banque, vous acceptez les <a href="${tosLink}" target="_blank">Conditions générales de {BUSINESS_NAME}</a> et la <a href="${ppLink}" target="_blank">Politique de confidentialité et de cookies de {BUSINESS_NAME}</a>.</div>`,
        cs: `<div>Kliknutím na banku souhlasíte s <a href="${tosLink}" target="_blank">podmínkami a ujednáními {BUSINESS_NAME}</a> a <a href="${ppLink}" target="_blank">zásadami ochrany osobních údajů a používání cookies {BUSINESS_NAME}</a>.</div>`,
        nl: `<div>Door op de bank te klikken, gaat u akkoord met de <a href="${tosLink}" target="_blank">algemene voorwaarden van {BUSINESS_NAME}</a> en de <a href="${ppLink}" target="_blank">privacy- en cookieverklaring van {BUSINESS_NAME}</a>.</div>`,
        de: `<div>Durch Klicken auf die Bank stimmen Sie den <a href="${tosLink}" target="_blank">Allgemeinen Geschäftsbedingungen von {BUSINESS_NAME}</a> und der <a href="${ppLink}" target="_blank">Datenschutz- und Cookie-Richtlinie von {BUSINESS_NAME}</a> zu.</div>`,
        hu: `<div>A bankra kattintva elfogadja a <a href="${tosLink}" target="_blank">{BUSINESS_NAME} felhasználási feltételeit</a> és a <a href="${ppLink}" target="_blank">{BUSINESS_NAME} adatvédelmi és cookie-szabályzatát</a>.</div>`,
        it: `<div>Cliccando sulla banca, accetti i <a href="${tosLink}" target="_blank">Termini e condizioni di {BUSINESS_NAME}</a> e l'<a href="${ppLink}" target="_blank">Informativa sulla privacy e sui cookie di {BUSINESS_NAME}</a>.</div>`,
        pl: `<div>Klikając na bank, zgadzasz się na <a href="${tosLink}" target="_blank">Warunki {BUSINESS_NAME}</a> oraz <a href="${ppLink}" target="_blank">Politykę prywatności i plików cookie {BUSINESS_NAME}</a>.</div>`,
        pt: `<div>Ao clicar no banco, você concorda com os <a href="${tosLink}" target="_blank">Termos e Condições de {BUSINESS_NAME}</a> e com a <a href="${ppLink}" target="_blank">Política de Privacidade e Cookies de {BUSINESS_NAME}</a>.</div>`,
        si: `<div>S klikom na banko se strinjate s <a href="${tosLink}" target="_blank">pogoji in določili {BUSINESS_NAME}</a> ter z <a href="${ppLink}" target="_blank">politiko zasebnosti in piškotkov {BUSINESS_NAME}</a>.</div>`,
        es: `<div>Al hacer clic en el banco, aceptas los <a href="${tosLink}" target="_blank">Términos y Condiciones de {BUSINESS_NAME}</a> y la <a href="${ppLink}" target="_blank">Política de Privacidad y Cookies de {BUSINESS_NAME}</a>.</div>`,
    },
};
