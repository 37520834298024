module.exports = {
    getMessages: {
        AIS: {
            'en.aisp.consent.text':
                '<div>{BUSINESS_NAME} will access the above information from your selected accounts until {EXPIRATION_DATE} and will provide this information to you and {DISPLAY_NAME} who will use the same in fulfillment of its services to you.</div>',
            'fr.aisp.consent.text':
                "<div>{BUSINESS_NAME} accèdera aux informations ci-dessus à partir de vos comptes sélectionnés jusqu'au {EXPIRATION_DATE} et vous fournira ces informations ainsi qu'à {DISPLAY_NAME} qui les utilisera dans le cadre de ses services.</div>",
            'cs.aisp.consent.text':
                '<div>{BUSINESS_NAME} bude mít přístup k výše uvedeným informacím z vašich vybraných účtů do {EXPIRATION_DATE} a poskytne tyto informace vám a {DISPLAY_NAME}, kteří je použijí při plnění svých služeb pro vás. </div>',
            'nl.aisp.consent.text':
                '<div>{BUSINESS_NAME} zal toegang hebben tot de bovenstaande informatie bij de door jou geselecteerde bankrekening tot {EXPIRATION_DATE} en zal toegang verlenen tot deze informatie voor jou en {DISPLAY_NAME}, die deze informatie gebruiken in zijn dienstverlening aan jou.</div>',
            'de.aisp.consent.text':
                '<div>{BUSINESS_NAME} greift bis zum {EXPIRATION_DATE} auf die oben genannten Informationen von Ihrem ausgewählten Konto zu und stellt diese Informationen Ihnen und {DISPLAY_NAME} zur Verfügung. {DISPLAY_NAME} nutzt die Informationen zur Erfüllung der Dienste für Sie.</div>',
            'hu.aisp.consent.text':
                '<div>A(z) {BUSINESS_NAME} hozzá fog férni a fenti információkhoz az Ön kiválasztott számláin {EXPIRATION_DATE} napjáig, és át fogja adni ezeket az információkat az Ön és a(z) {DISPLAY_NAME} részére, amely az információkat az Önnek nyújtandó szolgáltatás teljesítéséhez fogja használni.</div>',
            'it.aisp.consent.text':
                '<div>{BUSINESS_NAME} accederà alle informazioni di cui sopra dai tuoi account selezionati fino al {EXPIRATION_DATE} e fornirà queste informazioni a te e a {DISPLAY_NAME} che useranno le stesse per il pieno completamento dei suoi servizi.</div>',
            'pl.aisp.consent.text':
                '<div>{BUSINESS_NAME} będzie uzyskiwać dostęp do powyższych informacji z wybranych kont do {EXPIRATION_DATE} i przekaże te informacje Tobie i {DISPLAY_NAME}, którzy będą korzystać z tych samych danych w celu pełnej realizacji swoich usług.</div>',
            'pt.aisp.consent.text':
                '<div>{BUSINESS_NAME} acessará as informações acima de suas contas selecionadas até {EXPIRATION_DATE} e fornecerá essas informações a você e a {DISPLAY_NAME}, que as usarão no cumprimento de seus serviços.</div>',
            'si.aisp.consent.text':
                '<div>{BUSINESS_NAME} bo do zgornjih podatkov dostopal do vaših izbranih računov do {EXPIRATION_DATE} in te podatke posredoval vam in {DISPLAY_NAME}, ki bodo te iste uporabili pri opravljanju svojih storitev za vas.</div>',
            'es.aisp.consent.text':
                '<div>{BUSINESS_NAME} accederá a la información  de sus cuentas seleccionadas hasta el {EXPIRATION_DATE} y les proporcionará esta información a usted y a {DISPLAY_NAME}, quienes utilizarán en cumplimiento de sus servicios para usted.</div>',
        },
        PIS: {
            'en.pisp.consent.text':
                '<div>You can use {BUSINESS_NAME}s payment service to make a secure payment directly from your bank account to the benefit of the {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>We will ask your bank to share your account details. We will only use these details when necessary such as processing a refund request.</div></div>',
            'fr.pisp.consent.text':
                '<div>Vous pouvez utiliser le service sécurisé par {BUSINESS_NAME} pour effectuer un paiement sécurisé directement à partir de votre compte bancaire au profit de {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>Nous demanderons à votre banque de partager les détails de votre compte. Nous n\'utiliserons ces détails que lorsque cela est nécessaire, comme le traitement d\'une demande de remboursement.</div></div>',
            'cs.pisp.consent.text':
                '<div>Pomocí platební služby {BUSINESS_NAME} můžete provést bezpečnou platbu přímo z vašeho bankovního účtu ve prospěch účtu {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>Požádáme vaši banku o sdílení údajů o vašem účtu. Tyto údaje použijeme pouze v případě potřeby, například při zpracování žádosti o vrácení peněz.</div></div>',
            'nl.pisp.consent.text':
                '<div>Je kan {BUSINESS_NAME}\'s betalingsdienst gebruiken om een veilige betaling direct van je bankrekening naar {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>We vragen je bank om je accountgegevens te delen. We gebruiken deze details alleen als het nodig is om een terugbetalingsverzoek uit te voeren</div></div>',
            'de.pisp.consent.text':
                '<div>Mit {BUSINESS_NAME} können Sie bequem und sicher direkt von Ihrem Konto an {DISPLAY_NAME} überweisen.</div><div class="rt-reverse-payments-support"><div>Wir werden Ihre Bank bitten, Ihre Kontodaten mitzuteilen. Wir werden diese Daten nur verwenden, wenn dies erforderlich ist, z.B. im Fall einer Rückerstattung.</div></div>',
            'hu.pisp.consent.text':
                '<div>A(z) {BUSINESS_NAME} fizetési szolgáltatását használhatja, hogy biztonságos fizetést hajtson végre közvetlenül a bankszámlájáról a(z) {DISPLAY_NAME} javára.</div><div class="rt-reverse-payments-support"><div>Kérni fogjuk a bankját, hogy ossza meg velünk a számlaadatait. Ezeket az adatokat csak akkor használjuk fel, ha szükséges, például egy visszatérítési kérelem feldolgozásához</div></div>',
            'it.pisp.consent.text':
                '<div>Puoi utilizzare il servizio di pagamento di {BUSINESS_NAME} per effettuare un pagamento sicuro direttamente dal tuo conto bancario a vantaggio di {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>Chiederemo alla tua banca di condividere i dettagli del tuo conto. Utilizzeremo questi dettagli solo quando necessario, ad esempio l\'elaborazione di una richiesta di rimborso</div></div>',
            'pl.pisp.consent.text':
                '<div>Możesz skorzystać z usługi płatniczej {BUSINESS_NAME}, aby dokonać bezpiecznej płatności bezpośrednio ze swojego konta bankowego na rzecz {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>Poprosimy Twój bank o udostępnienie danych Twojego konta. Będziemy wykorzystywać te dane tylko wtedy, gdy będzie to konieczne, na przykład podczas przetwarzania wniosku o zwrot pieniędzy.</div></div>',
            'pt.pisp.consent.text':
                '<div>Poderá optar por efetuar o pagamento à {DISPLAY_NAME} de forma remota (online) e segura, através da conta bancária por si selecionada, com recurso aos serviços de iniciação de pagamentos disponibilizados pela {BUSINESS_NAME}.</div><div class="rt-reverse-payments-support"><div>Pediremos ao seu banco para compartilhar os detalhes da sua conta. Só usaremos esses detalhes quando necessário, como processar um pedido de reembolso.</div></div>',
            'si.pisp.consent.text':
                '<div>Lahko uporabiš {BUSINESS_NAME}\'s plačilna storitev lahko varno plačilo izvedete neposredno z vašega bančnega računa na dobroimetje {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>Vašo banko bomo prosili, naj nam posreduje podatke o vašem računu. Te podatke bomo uporabili le po potrebi, kot je obdelava zahteve za vračilo kupnine.</div></div>',
            'es.pisp.consent.text':
                '<div>Puede utilizar el servicio de pago de {BUSINESS_NAME} para realizar un pago seguro directamente desde su cuenta bancaria a beneficio de {DISPLAY_NAME}.</div><div class="rt-reverse-payments-support"><div>Le pediremos a su banco que comparta los detalles de su cuenta. Solo usaremos estos detalles cuando sea necesario, como procesar una solicitud de reembolso.</div></div>',
        },
        VRP: {
            'en.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} needs your permission for this setup with {PAYER’S BANK NAME} to make payment(s) with the parameter(s) you have agreed above.</div>',
            'fr.vrp.consent.text':
                "<div>TPP {DISPLAY_NAME} a besoin de votre autorisation pour cette configuration avec {PAYER’S BANK NAME} afin d'effectuer des paiements avec les paramètres que vous avez convenus ci-dessus.</div>",
            'cs.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} potřebuje vaše svolení pro toto nastavení s {PAYER’S BANK NAME}, aby mohl provádět platby s parametry, na kterých jste se dohodli výše.</div>',
            'nl.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} heeft uw toestemming nodig voor deze configuratie met {PAYER’S BANK NAME} om betaling(en) uit te voeren met de parameter(s) die u hierboven bent overeengekomen.</div>',
            'de.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} benötigt Ihre Erlaubnis für diese Einrichtung mit {PAYER’S BANK NAME}, um Zahlungen mit den oben vereinbarten Parametern vorzunehmen.</div>',
            'hu.vrp.consent.text':
                '<div>A(z) {DISPLAY_NAME} TPP-nek az Ön engedélyére van szüksége ehhez a beállításhoz a(z) {PAYER’S BANK NAME} szolgáltatással, hogy fizetéseket hajthasson végre a fent egyeztetett paraméter(ek) alapján.</div>',
            'it.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} ha bisogno della tua autorizzazione per questa configurazione con {PAYER’S BANK NAME} per effettuare pagamenti con i parametri che hai concordato sopra.</div>',
            'pl.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} potrzebuje Twojej zgody na tę konfigurację z {PAYER’S BANK NAME}, aby dokonywać płatności z parametrami, które uzgodniłeś powyżej.</div>',
            'pt.vrp.consent.text':
                '<div>O TPP {DISPLAY_NAME} precisa de sua permissão para esta configuração com {PAYER’S BANK NAME} para fazer pagamentos com os parâmetros que você concordou acima.</div>',
            'si.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} potrebuje vaše dovoljenje za to nastavitev z {PAYER’S BANK NAME}, da lahko izvede plačila s parametri, za katere ste se dogovorili zgoraj.</div>',
            'es.vrp.consent.text':
                '<div>TPP {DISPLAY_NAME} necesita su permiso para esta configuración con {PAYER’S BANK NAME} para realizar pagos con los parámetros que acordó anteriormente.</div>',
        },
    },
};
