import React from 'react';
import { Grid } from '@material-ui/core';

// material-ui
import { makeStyles } from '@material-ui/styles';

// helpers
import sanitizeHTML from 'helpers/sanitize-html/sanitize-html';

interface IProps {
    tags: string[];
    title: string;
    formatTag: (t: string) => string | Node;
}

function Tags({ tags, title, formatTag }: IProps) {
    const classes = useStyles();
    if (tags.length === 0) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>{title}</div>
            <Grid container spacing={2} className={classes.title}>
                <Grid item xs={6}>
                    {'Key'}
                </Grid>
                <Grid item xs={6}>
                    {'Value'}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {tags.map((t) => (
                    <React.Fragment key={t}>
                        <Grid item xs={6}>
                            {t}
                        </Grid>
                        <Grid
                            id={'tag-value'}
                            item
                            xs={6}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHTML(formatTag(t)),
                            }}
                        />
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    );
}

Tags.defaultProps = {
    title: 'Available Tags: ',
    formatTag: () => '',
};

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20,
        fontSize: 'medium',
        opacity: 0.8,
    },
    title: {
        fontWeight: 600,
        marginBottom: 10,
    },
}));

export default Tags;
