module.exports = {
    SINGLE_IMMEDIATE_PAYMENT: 'SINGLE_IMMEDIATE_PAYMENT',
    IP_ADDRESS: 'IP_ADDRESS',
    GEO_LOCATION: 'GEO_LOCATION',
    DELETE: 'DELETE',
    DELETE_REGION: 'DELETE_REGION',
    DELETE_COUNTRY: 'DELETE_COUNTRY',
    ADD: 'ADD',
    ALLOW: 'allow',
    NOT_ALLOWED: 'notAllowed',
    PIS_PAYMENT_NETWORK: 'PIS_PAYMENT_NETWORK',
};
