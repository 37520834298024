import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Toolbar from './Toolbar';
import Button from '../buttons/ButtonSelector';
import formats from './formats';

function SlateToolbar({ disabled }) {
    const ref = useRef();
    return (
        <Toolbar className={'slateToolbar'} ref={ref}>
            {Object.keys(formats).map((f) => (
                <React.Fragment key={f}>
                    <Button isDisabled={disabled.includes(f)} type={formats[f].type} format={f}>
                        {formats[f].icon}
                    </Button>
                </React.Fragment>
            ))}
        </Toolbar>
    );
}

SlateToolbar.propTypes = {
    disabled: PropTypes.array,
};

export default SlateToolbar;
