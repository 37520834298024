import { action, observable } from 'mobx';

class ColorPickerStore {
    @observable
    isOpen = false;

    @action
    showPicker = () => {
        this.isOpen = true;
    };

    @action
    hidePicker = () => {
        this.isOpen = false;
    };
}

export default ColorPickerStore;
