export const acceptedTags = [
    '<div>',
    '<em>',
    '<strong>',
    '<u>',
    '<ol>',
    '<ul>',
    '<li>',
    '<blockquote>',
    '<a href=',
    '<a target="_blank" href=',
    '<h1>',
    '<h2>',
    '<code>',
    '<image src=',
    '<img class="img-consent" src=',
    '<br>',
    '<span class=',
    '<div class="TextArea-flex-box">',
];

export const type = {
    '<div>': 'paragraph',
    '<ol>': 'numbered-list',
    '<ul>': 'bulleted-list',
    '<li>': 'list-item',
    '<blockquote>': 'block-quote',
    '<a href=': 'link',
    '<a target="_blank" href=': 'link',
    '<h1>': 'heading-one',
    '<h2>': 'heading-two',
    '<image src=': 'image',
    '<img class="img-consent" src=': 'image',
    '<span class=': 'paragraph',
    '<div class="TextArea-flex-box">': 'paragraph',
};

export const property = {
    '<em>': {
        italic: true,
    },
    '<strong>': {
        bold: true,
    },
    '<u>': {
        underline: true,
    },
    '<code>': {
        code: true,
    },
};

export const closingTags = {
    '<div>': '</div>',
    '<em>': '</em>',
    '<strong>': '</strong>',
    '<u>': '</u>',
    '<ol>': '</ol>',
    '<ul>': '</ul>',
    '<li>': '</li>',
    '<blockquote>': '</blockquote>',
    '<a href=': '</a>',
    '<a target="_blank" href=': '</a>',
    '<h1>': '</h1>',
    '<h2>': '</h2>',
    '<code>': '</code>',
    '<image src=': '</image>',
    '<img class="img-consent" src=': '>',
    '<br>': '<br>',
    '<span class=': '</span>',
    '<div class="TextArea-flex-box">': '</div>',
};

export const mappedTags = {
    '<a href=': '<a href=".*?">',
    '<a target="_blank" href=': '<a target="_blank" href=".*?">',
    '<image src=': '<image src=".*?">',
    '<img class="img-consent" src=': '<image class="img-consent" src=".*?">',
    '<span class=': '<span class=".*?">',
    '<div class="TextArea-flex-box"': '<div class="TextArea-flex-box">',
};
