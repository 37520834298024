const DISPLAY_NAME = '{DISPLAY_NAME}';
const BUSINESS_NAME = '{BUSINESS_NAME}';
const TPP_LOGO = '{TPP_LOGO}';
const RESELLER_LOGO = '{RESELLER_LOGO}';
const EXPIRATION_DATE = '{EXPIRATION_DATE}';

const AISTags = [BUSINESS_NAME, DISPLAY_NAME, EXPIRATION_DATE];
const PISTags = [BUSINESS_NAME, DISPLAY_NAME];
const VRPTags = [BUSINESS_NAME, DISPLAY_NAME, EXPIRATION_DATE];
const GeneralTags = [TPP_LOGO, RESELLER_LOGO, DISPLAY_NAME];

module.exports = {
    AISTags,
    PISTags,
    VRPTags,
    GeneralTags,
};
