import React from 'react';
import SpinnerMiniSecondary from '../../../images/icon-spinner-small-secondary.png';
import SpinnerMini from '../../../images/icon-spinner-small.png';

import SpinnerNormal from '../../../images/icon-spinner.svg';
import PropTypes from 'prop-types';

const TYPE_LARGE = 'large';
const TYPE_SMALL = 'small';

const COLOR_PRIMARY = 'primary';
const COLOR_SECONDARY = 'secondary';

interface ISpinner {
    type?: 'large' | 'small';
    color?: 'primary' | 'secondary';
    className?: string;
}

type TSpinner = React.FC<ISpinner> & {
    TYPE_LARGE: 'large';
    TYPE_SMALL: 'small';
    COLOR_PRIMARY: 'primary';
    COLOR_SECONDARY: 'secondary';
};

const Spinner: TSpinner = ({ type, color }) => (
    <img
        alt="spinner"
        src={
            type === TYPE_SMALL
                ? color === COLOR_SECONDARY
                    ? SpinnerMiniSecondary
                    : SpinnerMini
                : SpinnerNormal
        }
        className={type === TYPE_SMALL ? 'Spinner-small' : 'Spinner'}
    />
);

Spinner.propTypes = {
    type: PropTypes.oneOf([TYPE_LARGE, TYPE_SMALL]),
    color: PropTypes.oneOf([COLOR_PRIMARY, COLOR_SECONDARY]),
};

Spinner.TYPE_LARGE = TYPE_LARGE;
Spinner.TYPE_SMALL = TYPE_SMALL;

Spinner.COLOR_PRIMARY = COLOR_PRIMARY;
Spinner.COLOR_SECONDARY = COLOR_SECONDARY;

export default Spinner;
