import { TextField, Tooltip, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ChromePicker } from 'react-color';

const styles = () => ({
    popOver: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
});

function ColorPicker({ classes, onChange, Store, name, placeholder, value, tooltipText }) {
    const handleChange = (color) => {
        onChange({
            target: {
                name,
                value: color.hex,
            },
        });
    };

    const textField = (
        <TextField
            name={name}
            value={value}
            placeholder={placeholder}
            onClick={Store.showPicker}
            onChange={(e) => {
                e.persist();
                onChange(e);
            }}
        />
    );

    const renderTextField = () => {
        if (tooltipText) {
            return (
                <Tooltip title={tooltipText} arrow>
                    {textField}
                </Tooltip>
            );
        }
        return textField;
    };

    return (
        <>
            {renderTextField()}
            {Store.isOpen && (
                <div className={clsx(classes.popOver)}>
                    <div className={clsx(classes.cover)} onClick={Store.hidePicker} />
                    <ChromePicker color={value} onClick={handleChange} onChange={handleChange} />
                </div>
            )}
        </>
    );
}

ColorPicker.propTypes = {
    classes: PropTypes.object.isRequired,
    Store: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    tooltipText: PropTypes.string,
};

export default withStyles(styles, { name: 'ColorPicker' })(observer(ColorPicker));
