import { Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import pipe from 'lodash/fp/pipe';

// slate
import { createEditor, Text } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, withReact, Slate } from 'slate-react';

// slate utils
import isHotkey from 'is-hotkey';
import SlateElement from './element/Element';
import SlateLeaf from './Leaf';
import { HOTKEYS, IGNORED_HOTKEYS } from './helpers/constants';
import { toggleMark } from './helpers/togglers';
import SlateToolbar from './toolbar/SlateToolbar';
import { withDragAndDropImages, withLinks } from './plugins';

// styles
import makeStyles from '@material-ui/styles/makeStyles';

// snackbar
import snackbar from 'hooks/useSnackbar';

const { useSnackbar } = snackbar;

// preview and readOnly works exactly the same its just that
// former is used in rich editor modal and
// the later is used while displaying the actual text on rest pages.
// to display consistent data all around.
const useStyles = makeStyles(() => ({
    editable: {
        border: '2px solid transparent',
        minHeight: '550px',
        maxWidth: '600px',
        overflowWrap: 'break-word',
    },
}));

const createEditorWithPlugins = pipe(withReact, withHistory, withLinks);

function RichEditor({ value, setValue, placeholder, disabled, autoFocus }) {
    const { addSnack } = useSnackbar();

    // useMemo => useState: to get hold of the previous editor value.
    const [editor] = useState(
        () =>
            withDragAndDropImages(
                createEditorWithPlugins(createEditor()),
                addSnack,
                !disabled.includes('image'),
            ),
        [],
    );
    const decorate = ([node, path]) => {
        const ranges = [];
        if (!Text.isText(node)) {
            return ranges;
        }

        ranges.push({
            anchor: { path, offset: 0 },
            focus: { path, offset: 1 },
            mydec: true,
        });

        return ranges;
    };
    const classes = useStyles();

    const checkForValidInput = (event, condition) => {
        if (condition) {
            addSnack(`${IGNORED_HOTKEYS} special characters not allowed`, {
                variant: 'info',
                persist: false,
            });
            event.preventDefault();
        }
    };

    return (
        <>
            <Paper elevation={2} className={classes.editable}>
                <Box p={1} m={2} className={'richEditor'}>
                    <Slate
                        editor={editor}
                        value={value}
                        onChange={(v) => {
                            setValue(v);
                        }}>
                        <SlateToolbar disabled={disabled} />
                        <Box pl={2}>
                            <Editable
                                decorate={decorate}
                                renderElement={(props) => <SlateElement {...props} />}
                                renderLeaf={(props) => <SlateLeaf {...props} />}
                                placeholder={placeholder}
                                spellCheck
                                autoFocus={autoFocus}
                                onPaste={(e) => {
                                    const clipboardData = e.clipboardData.getData('Text');
                                    checkForValidInput(e, /[&<>]/.test(clipboardData));
                                    checkForValidInput(
                                        e,
                                        /^data:image\/[a-z]+;base64,/.test(clipboardData),
                                    );
                                }}
                                onKeyDown={(event) => {
                                    checkForValidInput(
                                        event,
                                        event.shiftKey && IGNORED_HOTKEYS.includes(event.key),
                                    );
                                    for (const hotkey in HOTKEYS) {
                                        if (isHotkey(hotkey, event)) {
                                            event.preventDefault();
                                            const mark = HOTKEYS[hotkey];
                                            toggleMark(editor, mark);
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Slate>
                </Box>
            </Paper>
        </>
    );
}

RichEditor.propTypes = {
    value: PropTypes.array.isRequired,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.array,
    autoFocus: PropTypes.bool,
};

RichEditor.defaultProps = {
    placeholder: 'Enter some rich text…',
    setValue: async () => Promise.resolve(),
    disabled: ['block-quote', 'code'],
    autoFocus: false,
};

export default RichEditor;
