import {
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import successIcon from '../../../images/password-reset.svg';
import GenericSuccessModal from '../../common/modals/success/GenericSuccess';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import TextFieldWrapper from '../../common/wrappers/TextFieldWrapper';

function Webhook({ AppStore, WebhookStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-webhook');
    const {
        form,
        handleChange,
        isFormValid,
        loading,
        handleCheck,
        handleFetch,
        handleGenerate,
        handleSubmit,
        webhookConfig,
        disableTestButton,
        testButtonLoading,
        rootStore,
    } = WebhookStore;

    const memberId = rootStore.memberId;

    let events = [
        {
            name: 'transfers',
        },
        {
            name: 'refunds',
        },
        {
            name: 'recurringPayments',
        },
        {
            name: 'vrpConsent',
        },
        {
            name: 'vrpPaymentInit',
        },
        {
            name: 'aisBankOutage',
        },
        {
            name: 'sipBankOutage',
        },
        {
            name: 'paymentStatusChanged',
        },
    ];

    if (!rootStore.member?.sipVersion?.includes('V2')) {
        events = events.filter((event) => {
            return event.name !== 'paymentStatusChanged';
        });
    }

    useEffect(() => {
        handleFetch(memberId);
    }, []);

    return (
        <div className={'Webhook'}>
            <h2>{t('webhook')}</h2>
            <p className={'description'}>{t('webhookDescription')}</p>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="webhookUrl"
                        type="text"
                        label={t('webhookUrl')}
                        value={form.webhookUrl}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl className={'events'}>
                            <FormLabel className={'eventsLabel'}>{t('events')}</FormLabel>
                            <FormGroup className={'checkboxGroup'}>
                                <Grid container>
                                    {events.map((e) => (
                                        <Grid item xs={12} key={e.name}>
                                            <FormControlLabel
                                                className={'eventCheckbox'}
                                                control={
                                                    <Checkbox
                                                        onChange={handleCheck}
                                                        name={e.name}
                                                        checked={!!form[e.name]}
                                                    />
                                                }
                                                label={t(e.name)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'divider'} item xs={12}>
                <Divider />
            </Grid>
            <Grid className={'buttonContainer'} container spacing={4}>
                <Grid item xs={3}>
                    <ButtonWrapper
                        loading={testButtonLoading}
                        className="testButton"
                        onClick={() => handleGenerate(memberId)}
                        disabled={disableTestButton}>
                        {t('common:test')}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={3}>
                    <ButtonWrapper
                        loading={loading}
                        className="submitButton"
                        onClick={() => handleSubmit(memberId)}
                        disabled={!isFormValid}>
                        {t('common:save')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <GenericSuccessModal
                Store={WebhookStore.GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('modalButton')}
                icon={successIcon}
            />
            <GenericSuccessModal
                Store={WebhookStore.GenerateWebhookModal}
                title={t('generateWebhookModalTitle')}
                subtitle={t('generateWebhookModalDescription', { url: webhookConfig.url })}
                buttonText={t('modalButton')}
                icon={successIcon}
            />
        </div>
    );
}

Webhook.propTypes = {
    AppStore: PropTypes.object.isRequired,
    WebhookStore: PropTypes.object.isRequired,
};

export default inject('AppStore')(observer(Webhook));
