import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import General from './General';

function TabGeneral({ ConfigurationStore }) {
    return (
        <div className={'TabGeneral'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <General GeneralStore={ConfigurationStore.GeneralStore} />
                </Grid>
            </Grid>
        </div>
    );
}

TabGeneral.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabGeneral));
