import { Editor, Element as SlateElement } from 'slate';
import serialize from 'helpers/serialize-slate/serialize';
import getAllMatchedGroups from 'helpers/get-all-matched-groups/get-all-matched-groups';
import { MAX_IMAGES } from './constants';

// used for changing the text font / splitting
export const isBlockActive = (editor, format) => {
    try {
        const [match] = Editor.nodes(editor, {
            match: (n) => n.type === format,
        });
        return !!match;
    } catch (e) {
        console.error(e);
        return 0;
    }
};

// used for in-place editing like bold italic, underline, link.
export const isMarkActive = (editor, format) => {
    // when consent text contains images, mark button will search for any marks (a leaf) but image is a separate element.
    // and this is why this condition is necessary to keep things in actual place where they belong.
    try {
        if (!isImageActive(editor)) {
            const marks = Editor.marks(editor);
            return marks ? marks[format] === true : false;
        }
        return false;
    } catch (e) {
        console.error(e);
        return 0;
    }
};

export const isImageActive = (editor) => {
    try {
        const [image] = Editor.nodes(editor, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
        });
        return !!image;
    } catch (e) {
        console.error(e);
        return 0;
    }
};

export const isLinkActive = (editor) => {
    try {
        const [link] = Editor.nodes(editor, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
        });
        return !!link;
    } catch (e) {
        console.error(e);
        return 0;
    }
};

// used for verifying align is active or not
export const isAlignActive = (editor, format) => {
    try {
        const [align] = Editor.nodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'paragraph' &&
                n.align === format,
        });
        return !!align;
    } catch (e) {
        console.error(e);
        return 0;
    }
};

export const underImageLimit = (children, addSnack) => {
    let serialized = '';
    children.map((v) => (serialized += serialize(v)));
    const regex = /src="(.*?)"/gi;
    if (getAllMatchedGroups(regex, serialized).length === MAX_IMAGES) {
        addSnack(`You can only upload at max ${MAX_IMAGES} image(s)`, {
            variant: 'info',
            persist: false,
        });
        return false;
    }
    return true;
};

// used for verifying indent is active or not
export const isIndentActive = (editor) => {
    try {
        const [indent] = Editor.nodes(editor, {
            match: (n) => n.type === 'paragraph',
        });
        return !!indent;
    } catch (e) {
        console.error(e);
        return 0;
    }
};
