import { Checkbox, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';

// icons
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';

function RenderKey({
    obj,
    name,
    isCheck,
    onCheck,
    helpText,
    onChange,
    placeholder,
    label,
    required,
    alt,
}) {
    const renderRow = () => {
        return (
            <Checkbox checked={obj[name]} onChange={onCheck} name={name} className={'checkbox'} />
        );
    };

    if (isCheck) {
        return (
            <Grid container className={'RenderKey'} item xs={12} spacing={2}>
                {alt ? <StyledIcon>{renderRow()}</StyledIcon> : renderRow()}
                <div className={'container'}>
                    <span className={'key'}>{label}</span>
                    {helpText && <span className={'helpText check'}>{helpText}</span>}
                </div>
            </Grid>
        );
    }
    return (
        <Grid container className={'RenderKey'}>
            <Grid item xs={6} className={'input'}>
                <TextFieldWrapper
                    name={name}
                    label={label}
                    onChange={onChange}
                    value={obj[name]}
                    placeholder={placeholder}
                    required={required}
                    hideOptional
                />
            </Grid>
            {helpText && <span className={'helpText field'}>{helpText}</span>}
        </Grid>
    );
}

RenderKey.propTypes = {
    obj: PropTypes.object.isRequired,
    name: PropTypes.string,
    isCheck: PropTypes.bool,
    helpText: PropTypes.string,
    onChange: PropTypes.func,
    onCheck: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    alt: PropTypes.bool,
};

RenderKey.defaultProps = {
    isCheck: false,
    alt: false,
};

export default observer(RenderKey);
