import { Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useSlate } from 'slate-react';
import { decreaseIndent, increaseIndent } from '../../helpers/indent';
import { isIndentActive } from '../../helpers/validations';
import { MAX_INDENT_PADDING } from '../../helpers/constants';

function IndentButton({ format, children }) {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1} className={'indentButton'}>
            <ToggleButton
                value={format}
                onMouseDown={(event) => {
                    event.preventDefault();
                    if (isIndentActive(editor)) {
                        const p = editor.selection.anchor.path[0];
                        let i = editor.children[p].indentPadding;
                        if (i === undefined) {
                            i = 0;
                        }
                        if (format === 'indent-increase' && i + 10 <= MAX_INDENT_PADDING) {
                            increaseIndent(editor, format, i + 10);
                        } else if (format === 'indent-decrease' && i - 10 >= 0) {
                            decreaseIndent(editor, format, i + 10);
                        }
                    }
                }}
                style={{ lineHeight: 1 }}>
                {children}
            </ToggleButton>
        </Box>
    );
}

IndentButton.propTypes = {
    format: PropTypes.string,
    children: PropTypes.object,
};

export default IndentButton;
