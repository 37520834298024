import DOMPurify from 'isomorphic-dompurify';
export default function sanitizeHTML(html) {
    return DOMPurify.sanitize(html, {
        ALLOWED_TAGS: [
            'h1',
            'div',
            'img',
            'blockquote',
            'p',
            'h2',
            'ol',
            'ul',
            'li',
            'a',
            'code',
            'strong',
            'u',
            'em',
            'br',
            'span',
        ],
        ALLOWED_ATTR: ['href', 'src', 'class', 'target'],
    });
}
