import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';

import ModalStore from 'components/common/modals/ModalStore';

// constants
import { IP_ADDRESS } from 'constants/restrictions';

// helpers
import isValidIp from 'helpers/is-valid-ip/isValidIp';
import ManageIpAddressesStore from 'components/configuration/manage-ip-addresses/ManageIpAddressesStore';
import React from 'react';
import { IRestrictionRowIp, Operation } from '../types';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

interface IForm {
    ips: string[];
    name: string;
}

interface DeleteIp {
    ips: {
        ip: string;
        checked: boolean;
    }[];
    name: string;
}

class IpAddressModalStore extends ModalStore {
    constructor(public rootStore: ManageIpAddressesStore) {
        super();
        this.rootStore = rootStore;
    }

    @observable
    errorText = 'invalid ip address';
    @observable
    form: IForm = {
        ips: [],
        name: '',
    };
    @observable
    ip = '';
    @observable
    loading = false;
    @observable
    previousRule: IForm = {
        ips: [],
        name: '',
    };
    @observable
    deleteIp: DeleteIp = {
        ips: [
            {
                ip: '',
                checked: false,
            },
        ],
        name: '',
    };
    @observable
    productType = IP_ADDRESS;
    @observable
    selectedRestrictionRow: Partial<IRestrictionRowIp> = {};
    @observable
    type: Operation = Operation.ADD;
    @observable
    ipCount = 1;

    @action
    handleChange = (event: React.ChangeEvent<any>, index?: number) => {
        if (event.target.name === 'ip' && index !== undefined) {
            this.form.ips[index] = event.target.value;
        } else {
            this.form[event.target.name as keyof IForm] = event.target.value;
        }
    };

    @action
    handleAddIp = () => {
        this.updateIpCount(this.ipCount + 1);
        this.form.ips.push('');
    };

    @action
    handleRemoveIp = (index: number) => {
        const updatedIps = this.form.ips.slice();
        updatedIps.splice(index, 1);
        this.form.ips = updatedIps;
        this.updateIpCount(this.ipCount - 1);
    };

    @action
    updateIpCount = (count: number) => {
        this.ipCount = count;
    };

    @action
    handleCheck = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
        if (name === 'all') {
            const isChecked = event.target.checked;
            this.deleteIp.ips.forEach((item) => {
                item.checked = isChecked;
            });
        } else {
            const isChecked = event.target.checked;
            const index = this.deleteIp.ips.findIndex((item) => item.ip === name);
            this.deleteIp.ips[index].checked = isChecked;
        }
    };

    @action
    setPreviousRule = (rule: IForm) => {
        this.previousRule = this.form = { ...rule };
    };

    @action
    setDeleteIpRule = (rule: IForm) => {
        const { ips, name } = rule;
        const ipsWithDefaults = ips.map((ip) => ({ ip: ip, checked: false }));

        this.deleteIp = {
            ips: ipsWithDefaults,
            name: name,
        };
    };

    @action
    handleDeleteIpRule = flow(
        function* (this: IpAddressModalStore) {
            this.rootStore.openIpWarningModal(Operation.DELETE_IP_RULE);
        }.bind(this),
    );

    @action
    setSelectedRestrictionRow = (row: IRestrictionRowIp) => {
        this.selectedRestrictionRow = row;
    };

    @action
    setType = (type: Operation) => {
        this.type = type;
    };

    @computed
    get isDisable() {
        if (this.type === Operation.DELETE_IP_RULE) {
            return !this.deleteIp.ips.some((ip) => ip.checked);
        }

        return !(
            !!this.form.ips &&
            !!this.form.name &&
            !this.isPreviousRule &&
            this.form.ips.every(isValidIp)
        );
    }

    @computed
    get isPreviousRule() {
        if (!this.previousRule.ips.length || !this.form.ips.length) {
            return false;
        }

        const areIpsEqual =
            this.previousRule.ips.length === this.form.ips.length &&
            this.previousRule.ips.every((ip, index) => ip === this.form.ips[index]);

        return areIpsEqual && this.previousRule.name === this.form.name;
    }

    @action
    handleSubmit = flow(
        function* (this: IpAddressModalStore) {
            this.loading = true;
            try {
                const memberId = this.rootStore.targetMemberId;
                const body = {
                    targetMemberId: memberId,
                    restrictionPayload: {
                        productType: this.productType,
                        rulePayloads: [
                            {
                                name: this.form.name,
                                text: `IpAddressRestrictedFields(${this.formatIpText()})`,
                            },
                        ],
                    },
                };
                yield axios.post('/api/member/payment-initiation-restrictions', body);
                this.handleClose();
                yield this.rootStore.handleFetch();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleModify = flow(
        function* (this: IpAddressModalStore): any {
            this.loading = true;
            try {
                const body = {
                    memberId: this.rootStore.targetMemberId,
                    restrictionId: this.rootStore.restrictionId,
                    ruleId: this.rootStore.ruleId,
                    updatedRule: {
                        id: this.rootStore.ruleId,
                        name: this.form.name,
                        text: `IpAddressRestrictedFields(${this.formatIpText()})`,
                        enabled: this.selectedRestrictionRow.enabled,
                        createdAt: this.selectedRestrictionRow.createdAt,
                    },
                };
                yield axios.put('/api/member/restriction-rule', body);

                this.handleClose();
                yield this.rootStore.handleFetch();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleClose = () => {
        this.closeModal();
        this.ipCount = 1;
        this.form = {
            ips: [],
            name: '',
        };
    };

    formatIpText = () => {
        return this.form.ips
            .map((ip) => `originIpAddress not matches "${this.formatIpRange(ip)}"`)
            .join(' && ');
    };

    formatIpRange = (ip: string) => {
        const ipRegex = '[0-9]{1,3}';
        if (ip.indexOf('*') > -1) {
            return ip.replace('*', ipRegex);
        }

        return ip;
    };
}

export default IpAddressModalStore;
