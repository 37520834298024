import axios, { AxiosResponse } from 'axios';
import ModalStore from 'components/common/modals/ModalStore';
import { PIS_PAYMENT_NETWORK } from 'constants/restrictions';
import { action, flow, observable } from 'mobx';
import type ConfigurationStore from '../ConfigurationStore';
import { Irestriction, Irule } from './types';
import transferDestinationTypes from 'constants/transferDestinationTypes';
import moment from 'moment';
import { v4 } from 'uuid';

type PaymentNetworks = keyof typeof transferDestinationTypes;

const { publicRuntimeConfig } = require('next/config').default();

class PisPaymentNetworkStore {
    constructor(public rootStore: ConfigurationStore) {
        this.rootStore = rootStore;
    }

    GenericErrorStore = new ModalStore();

    GenericSuccessStore = new ModalStore();

    @observable
    loading = false;

    @observable
    form: { [k: string]: boolean } = {};

    @observable
    paymentNetworkOptions: string[] = publicRuntimeConfig.pisPaymentNetworks;

    @observable
    restrictionId: string = '';

    @observable
    disabledPaymentNetworks: Array<{ name: string; ruleId: string }> = [];

    @action
    changeForm = (name: PaymentNetworks, value: boolean) => {
        this.form[name] = value;
    };

    @action
    handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.changeForm(event.target.name as PaymentNetworks, event.target.checked);
    };

    @observable
    restriction: Irestriction | null = null;

    @action
    handleFetch = flow(
        function* (
            this: PisPaymentNetworkStore,
        ): Generator<
            Promise<AxiosResponse<{ restrictions: Irestriction[] }>>,
            void,
            AxiosResponse<{ restrictions: Irestriction[] }>
        > {
            this.loading = true;
            try {
                this.initializeForm();
                const byTargetMemberId = this.rootStore.member.memberId;
                const byRestrictionId = this.restrictionId;
                const res = yield axios.get(
                    `/api/member/payment-initiation-restrictions?byTargetMemberId=${byTargetMemberId}&byRestrictionId=${byRestrictionId}`,
                );

                const restrictions = res?.data?.restrictions || [];
                if (restrictions.length) {
                    const paymentNetworkRestriction = restrictions.filter(
                        (restriction: Irestriction) =>
                            restriction.productType === PIS_PAYMENT_NETWORK,
                    );
                    if (paymentNetworkRestriction.length) {
                        this.restriction = paymentNetworkRestriction[0];
                        this.formatRestrictionList(this.restriction);
                    }
                }
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    initializeForm = () => {
        const form: { [k: string]: boolean } = {};
        this.paymentNetworkOptions.map((network) => {
            form[network] = true;
        });
        this.form = { ...form };
    };

    @action
    formatRestrictionList = (restriction: Irestriction) => {
        const form = { ...this.form };
        restriction.rules.map((rule) => {
            if (rule.enabled) {
                let text = rule.text.replace(
                    'PaymentNetworkRestrictedFields(paymentScheme == ',
                    '',
                );
                const lastIndex = text.lastIndexOf(')');
                text = text.substring(0, lastIndex).replace(/['"]+/g, '');
                if (this.paymentNetworkOptions.includes(text)) {
                    this.disabledPaymentNetworks.push({
                        name: text,
                        ruleId: rule.id,
                    });
                    form[text] = false;
                }
            }
        });
        this.form = { ...form };
    };

    @action
    handleSave = flow(
        function* () {
            this.loading = true;
            try {
                const allUnchecked: string[] = [];
                Object.keys(this.form).map((network) => {
                    if (!this.form[network]) {
                        allUnchecked.push(network);
                    }
                });
                const newRules = this.createRule();
                if (this.restriction) {
                    const body = {
                        restrictionId: this.restriction.id,
                        updatedRestriction: {
                            ...this.restriction,
                            updatedAt: moment(Date.now()).toDate().getTime(),
                            rules: newRules,
                        },
                    };

                    yield axios.put('/api/member/payment-initiation-restrictions', body);
                } else {
                    const body = {
                        targetMemberId: this.rootStore.member.memberId,
                        restrictionPayload: {
                            productType: PIS_PAYMENT_NETWORK,
                            rulePayloads: newRules,
                        },
                    };

                    yield axios.post('/api/member/payment-initiation-restrictions', body);
                }

                this.GenericSuccessStore.openModal();
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    createRule = () => {
        const rules: Irule[] = [];
        Object.keys(this.form).map((network) => {
            const existingRule = this.findExistingRule(network);
            if (existingRule) {
                existingRule.enabled = !this.form[network];
                rules.push(existingRule);
            } else {
                if (!this.form[network]) {
                    rules.push({
                        id: v4(),
                        name: network,
                        text: `PaymentNetworkRestrictedFields(paymentScheme == "${network}")`,
                        enabled: true,
                        createdAt: new Date().toISOString(),
                    });
                }
            }
        });
        return rules;
    };

    findExistingRule = (network: string) => {
        const allExistingRules = this.restriction?.rules || [];
        return allExistingRules.find(
            (rule) => rule.text === `PaymentNetworkRestrictedFields(paymentScheme == "${network}")`,
        );
    };
}

export default PisPaymentNetworkStore;
