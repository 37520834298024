import { Grid } from '@material-ui/core';
import React from 'react';
import DialogWrapper from '../../DialogWrapper';
import ButtonWrapper from '../../ButtonWrapper';
import PropTypes from 'prop-types';

function CaptureInfo({ open, onClose, onSave, disabled, tooltipText, children }) {
    return (
        <DialogWrapper open={open} onClose={onClose} className={'captureInfo'} hideDivider>
            {children}
            <Grid container spacing={3} style={{ margin: 'auto' }}>
                <Grid item xs={6}>
                    <ButtonWrapper
                        variant="contained"
                        color="secondary"
                        className={'closeButton'}
                        onClick={onClose}>
                        {'Cancel'}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={6}>
                    <ButtonWrapper
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        className={'saveButton'}
                        tooltipText={tooltipText}
                        showTooltip
                        onClick={onSave}>
                        {'Save'}
                    </ButtonWrapper>
                </Grid>
            </Grid>
        </DialogWrapper>
    );
}

CaptureInfo.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    children: PropTypes.object,
};

CaptureInfo.defaultProps = {
    disabled: false,
};

export default CaptureInfo;
