import {
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import { AppStoreProps } from 'types';
import AppController from 'base/App.controller';
import successIcon from '../../../images/password-reset.svg';
import GenericSuccessModal from '../../common/modals/success/GenericSuccess';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import { SINGLE_IMMEDIATE_PAYMENT } from 'constants/memberPermissions';
import ProductVersionsStore from './ProductVersionsStore';

interface props extends AppStoreProps {
    ProductVersionsStore?: ProductVersionsStore;
}
type Translation = (str: string, options?: any) => string | undefined;

interface Itranslation {
    t: Translation;
}

const ProductVersions: React.FC<props> = ({ProductVersionsStore , AppStore}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'configuration-product-versions');
    const events = [
        {
            name: 'SIPV1',
        },
        {
            name: 'SIPV2',
        },
    ];

    const {
        form,
        GenericSuccessStore,
        GenericErrorStore,
        isFormFilled,
        permissions,
        handleFetch,
        handleSubmit,
        handleCheck,
    } = ProductVersionsStore!;

    useEffect(() => {
        handleFetch();
    }, []);

    return (
        <div className={'ProductVersions'}>
            <h2>{t('productVersions')}</h2>
            {
                permissions.includes(SINGLE_IMMEDIATE_PAYMENT) ? (
                    <>
                        <p className={'description'}>{t('description')}</p>
                        <Grid container spacing={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl className={'events'}>
                                        <FormLabel className={'eventsLabel'}>{t('versions')}</FormLabel>
                                        <FormGroup className={'checkboxGroup'}>
                                            <Grid container>
                                                {events.map((e) => (
                                                    <Grid item xs={12} key={e.name}>
                                                        <FormControlLabel
                                                            className={'eventCheckbox'}
                                                            control={
                                                                <Checkbox
                                                                    onChange={handleCheck}
                                                                    name={e.name}
                                                                    checked={!!form[e.name]}
                                                                />
                                                            }
                                                            label={t(e.name)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={'divider'} item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid className={'buttonContainer'} container spacing={4}>
                            <Grid item xs={3} />
                            <Grid item xs={3}>
                                <ButtonWrapper
                                    loading={false}
                                    className="submitButton"
                                    onClick={handleSubmit}
                                    disabled={!isFormFilled}>
                                    {t('save')}
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                        <GenericSuccessModal
                            Store={GenericSuccessStore}
                            title={t('modalTitle')}
                            subtitle={t('modalDescription')}
                            buttonText={t('modalButton')}
                            icon={successIcon}
                        />
                        <GenericErrorModal Store={GenericErrorStore} />
                    </>
                ) : (
                    <p >{t('message')}</p>
                )
            }
        </div>
    );
};
export default inject('AppStore')(observer(ProductVersions));
