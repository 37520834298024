import { Checkbox, Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Profile from '../../../general/General';

// icons
import EditIcon from '@material-ui/icons/Edit';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';

import AppController from 'base/App.controller';
import Preview from 'components/common/wrappers/rich-editor/Preview';
import RichEditorModal from 'components/common/modals/rich-editor/RichEditorModal';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';

// helpers
import conditional from 'helpers/conditional/conditional';
import serialize from 'helpers/serialize-slate/serialize.js';
import RenderKey from '../helpers/render-key/RenderKey';
import SectionSave from '../helpers/section-save/SectionSave';

// constants
import { GeneralTags } from 'constants/editorTags';

function General({ AppStore, ConfigurationStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-features');
    const {
        displayFeatures,
        getPrevFeatures,
        handleChange,
        handleCheck,
        GeneralStore,
        memberId,
        helpTextFields,
        loading,
        handleSectionSave,
        fetchResellersLogo,
        resellerLogo,
        handleUpload,
    } = ConfigurationStore.FeatureStore;

    useEffect(() => {
        fetchResellersLogo();
        getPrevFeatures();
    }, []);

    const {
        supportPsr44Flow,
        footerText,
        displayLogoOnBankRedirectScreen,
        displayBeneficiaryDetails,
        enableSplashScreen,
        splashScreen,
        tppLogo,
        displayWebAppBackButton,
        modifyPreSelectedBank,
        enableTextInsteadOfLogo,
        productName,
        productLogo,
        tppLogoSplashScreen,
        webAppButtonsAlignment,
        enableAcceptButtonWithCustomText,
    } = displayFeatures;
    const general = {
        enableTextInsteadOfLogo,
        webAppButtonsAlignment,
        displayBeneficiaryDetails,
        modifyPreSelectedBank,
        enableSplashScreen,
        splashScreen,
        displayLogoOnBankRedirectScreen,
        enableAcceptButtonWithCustomText,
        supportPsr44Flow,
        displayWebAppBackButton,
        footerText,
        productName,
        productLogo,
        tppLogoSplashScreen,
    };

    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState('');

    const notToBeDisplayedList = [
        'productName',
        'productLogo',
        'tppLogoSplashScreen',
        'webAppButtonsAlignment',
    ];

    const onClose = () => {
        setOpen(false);
    };
    const openRichEditorModal = () => {
        setOpen(true);
    };

    const _handleChange = (value) => {
        let serializedInput = '';
        value.map((v) => (serializedInput += serialize(v)));
        serializedInput = serializedInput === '<br>' ? '' : serializedInput;
        handleChange({
            target: {
                name: current,
                value: serializedInput,
            },
        });
    };

    const openEditor = (key) => {
        setCurrent(key);
        openRichEditorModal();
    };

    const formatHtml = (html) => {
        let previewText = html;
        previewText = previewText
            .replace(
                /{TPP_LOGO}/g,
                conditional(
                    tppLogo && tppLogo.data,
                    `<image src="${tppLogo && tppLogo.data}" class="rt-tag-image"></image>`,
                    '',
                ),
            )
            .replace(
                /{RESELLER_LOGO}/g,
                conditional(
                    resellerLogo && resellerLogo.data,
                    `<image src="${
                        resellerLogo && resellerLogo.data
                    }" class="rt-tag-image"></image>`,
                    '',
                ),
            )
            .replace(/{DISPLAY_NAME}/g, GeneralStore.profileName)
            .replace(/\|/g, '<div class="rt-divider"></div>');
        return previewText;
    };

    const renderAlignmentBox = (key) => {
        return (
            <Grid container item xs={12} className={key}>
                <Grid container className={'RenderKey'} item xs={12} spacing={2}>
                    <Checkbox
                        checked={webAppButtonsAlignment === 'COLUMN'}
                        onChange={handleCheck}
                        name={key}
                        className={'checkbox'}
                    />
                    <div className={'container'}>
                        <span className={'key'}>{t(key)}</span>
                        <span className={'helpText check'}>{t(`helpText.${key}`)}</span>
                    </div>
                </Grid>
            </Grid>
        );
    };

    const renderEditor = (key) => {
        if (
            (key === 'splashScreen' && !displayFeatures.enableSplashScreen) ||
            notToBeDisplayedList.includes(key)
        ) {
            return <></>;
        }
        return (
            <Grid container item xs={12} className={key}>
                <Grid item xs={key === 'footerText' ? 1 : 2} className={'key editor'}>
                    {t(key)}:&nbsp;&nbsp;
                </Grid>
                <Grid className={'preview'}>
                    <Preview html={formatHtml(displayFeatures[key])} isOnlyDisplay />
                </Grid>
                <Grid>
                    <StyledIcon onClick={() => openEditor(key)} className={'icon edit'}>
                        <EditIcon />
                    </StyledIcon>
                </Grid>
            </Grid>
        );
    };

    const renderProductDetails = (key) => {
        if (key === 'splashScreen' && displayFeatures.enableSplashScreen) {
            return (
                <Grid container spacing={4} item xs={12} className={key}>
                    <Grid item xs={12} md={8}>
                        <h5 style={{ marginTop: '-30px' }}>{t('uploadProductLogo')}</h5>
                        <Grid container spacing={2}>
                            {productLogo?.data && (
                                <Grid item xs={12}>
                                    <img src={productLogo.data} className={'logo'} />
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={6}
                                className={'buttonContainer'}
                                style={{ marginTop: '-30px' }}>
                                <TextFieldWrapper
                                    className={'uploadLogo'}
                                    name="inputFile"
                                    type="file"
                                    onChange={(e) => handleUpload(e, 'productLogo')}
                                    hideOptional
                                />
                            </Grid>
                        </Grid>
                        <h5>{t('uploadTppLogoSplashScreen')}</h5>
                        <Grid container spacing={2}>
                            {tppLogoSplashScreen?.data && (
                                <Grid item xs={12}>
                                    <img src={tppLogoSplashScreen.data} className={'logo'} />
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={6}
                                className={'buttonContainer'}
                                style={{ marginTop: '-30px' }}>
                                <TextFieldWrapper
                                    className={'uploadLogo'}
                                    name="inputFile"
                                    type="file"
                                    onChange={(e) => handleUpload(e, 'tppLogoSplashScreen')}
                                    hideOptional
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    };

    const _handleSectionSave = async () => {
        await handleSectionSave();
        await GeneralStore.handleSubmit(memberId);
    };

    const hiddenFields = [
        'footerText',
        'splashScreen',
        'tppLogo',
        'productName',
        'productLogo',
        'tppLogoSplashScreen',
        'webAppButtonsAlignment',
    ];

    return (
        <div className={'WebappGeneral'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <Profile
                        AppStore={AppStore}
                        GeneralStore={GeneralStore}
                        memberId={memberId}
                        hideSave
                    />
                    <Grid item xs={12} md={5} className={'productName'}>
                        <TextFieldWrapper
                            label={t('productName')}
                            value={productName}
                            name={'productName'}
                            onChange={handleChange}
                            hideOptional
                        />
                    </Grid>
                    <Grid container spacing={6} item xs={12} className={'customfields'}>
                        {Object.keys(general).map((key) =>
                            !hiddenFields.includes(key) ? (
                                <Grid
                                    container
                                    className={'fields'}
                                    spacing={1}
                                    key={key}
                                    item
                                    xs={12}>
                                    <RenderKey
                                        obj={displayFeatures}
                                        name={key}
                                        isCheck
                                        helpText={
                                            helpTextFields.includes(key) ? t(`helpText.${key}`) : ''
                                        }
                                        onChange={handleChange}
                                        onCheck={handleCheck}
                                        label={t(key)}
                                    />
                                </Grid>
                            ) : (
                                <React.Fragment key={key}>
                                    {key === 'webAppButtonsAlignment' && renderAlignmentBox(key)}
                                    {renderProductDetails(key)}
                                    {renderEditor(key)}
                                </React.Fragment>
                            ),
                        )}
                    </Grid>
                    <Grid className={'divider'} item xs={12}>
                        <Divider />
                    </Grid>
                    <SectionSave
                        loading={loading}
                        onClick={() => _handleSectionSave()}
                        saveButtonText={t('common:save')}
                        tooptipText={t('generalTooltip')}
                    />
                </Grid>
                {open && (
                    <RichEditorModal
                        open={open}
                        onClose={() => onClose()}
                        plainText={displayFeatures[current]}
                        disabled={[
                            'heading-one',
                            'heading-two',
                            'numbered-list',
                            'bulleted-list',
                            'block-quote',
                            'code',
                            'indent-increase',
                            'indent-decrease',
                            'linkV2',
                        ]}
                        buttonText={t('confirm')}
                        onConfirm={_handleChange}
                        title={conditional(
                            current === 'footerText',
                            t('modifyFooter'),
                            t('modifySplashScreen'),
                        )}
                        tags={GeneralTags}
                        formatHtml={formatHtml}
                    />
                )}
            </Grid>
        </div>
    );
}

General.propTypes = {
    AppStore: PropTypes.object.isRequired,
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(General));
