import { Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import uploadIcon from '../../../images/upload.svg';
import DeletePublicKey from '../../common/modals/authentication-keys/public-key/DeletePublicKey';
import PublicKeyModal from '../../common/modals/authentication-keys/public-key/PublicKey';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import TableWrapper, { cellConstructor } from '../../common/wrappers/TableWrapper';
import LinkWrapper from '../../common/wrappers/LinkWrapper';

const { publicRuntimeConfig } = require('next/config').default();
const { externalLinks } = publicRuntimeConfig;

function PublicKey({ AuthenticationKeysStore, AppStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'authentication-keys');
    const {
        publicKeyLoading,
        handleOpen,
        publicKeys,
        getPublicKeys,
        selectedPublicKey,
        updateSelectRow,
        TableWrapperStore,
        DeletePublicKeyModal,
    } = AuthenticationKeysStore;

    useEffect(() => {
        getPublicKeys();
    }, []);

    const cellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'id', field: 'id' }),
        (row) => cellConstructor.text({ row, keyId: 'id', field: 'algorithm' }),
        (row) => cellConstructor.text({ row, keyId: 'id', field: 'publicKey', className: 'cell' }),
    ];

    const header = [t('keyId'), t('keyType'), t('uploadedKey')];

    return (
        <div className="PublicKeyMain">
            <div className={'instructions'}>
                <span className={'publicKeyInstructions'}>{t('publicKeyInstructions')}</span>
                <span>
                    <LinkWrapper target="_blank" rel="noopener" href={externalLinks.dashboardTools}>
                        {t('here')}
                    </LinkWrapper>
                </span>
                <span>{'.'}</span>
            </div>
            <div className="keys">
                <Grid container spacing={2}>
                    {publicKeys?.length > 0 ? (
                        <>
                            <Grid item xs={4}>
                                <ButtonWrapper
                                    onClick={handleOpen}
                                    startIcon={<img src={uploadIcon} />}>
                                    {t('uploadNewKey')}
                                </ButtonWrapper>
                            </Grid>
                            <Grid item xs={5} />
                            <Grid item xs={3}>
                                <ButtonWrapper
                                    disabled={isEmpty(selectedPublicKey)}
                                    onClick={() => DeletePublicKeyModal.openModal()}
                                    className={'delete'}>
                                    {t('delete')}
                                </ButtonWrapper>
                            </Grid>
                        </>
                    ) : (
                        <Grid item lg={3} md={4} sm={12}>
                            <ButtonWrapper
                                onClick={handleOpen}
                                startIcon={<img src={uploadIcon} />}>
                                {t('uploadKey')}
                            </ButtonWrapper>
                        </Grid>
                    )}
                </Grid>
                <TableWrapper
                    header={header}
                    enablePagination
                    disableRowSelect={false}
                    cellTemplates={cellTemplates}
                    callback={updateSelectRow}
                    noDataMessage={t('noPublicKeysMessage')}
                    loading={publicKeyLoading}
                    TableWrapperStore={TableWrapperStore}
                />
            </div>
            <PublicKeyModal />
            <DeletePublicKey />
        </div>
    );
}

PublicKey.propTypes = {
    AppStore: PropTypes.object.isRequired,
    AuthenticationKeysStore: PropTypes.object.isRequired,
};

export default inject('AuthenticationKeysStore', 'AppStore')(observer(PublicKey));
