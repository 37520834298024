import { ListItem, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { IDrawerItemProps } from '../types';

const styles = () => {
    const TAB_COLOR = 'linear-gradient(89.43deg, var(--ghost-white) -1.21%, #FFFFFF 99.91%)';
    return {
        /* Styles applied to the root element. */
        root: {
            padding: '15px',
            color: 'var(--revolver)',

            '& .icon': {
                fill: 'var(--gainsboro)',
            },

            '&.Mui-selected': {
                fill: 'var(--active)',
                color: 'var(--active)',
                background: TAB_COLOR,
                '&:hover': {
                    background: TAB_COLOR,
                },
                '& .selectedDivider': {
                    height: '100%',
                    width: '6px',
                    backgroundColor: 'var(--primary)',
                    position: 'absolute',
                    left: '0',
                },
                '& .icon': {
                    fill: 'var(--active)',
                },
            },
            '&:focus': {
                background: TAB_COLOR,
                zIndex: 1,
            },
            '&:hover': {
                background: TAB_COLOR,
            },
            '& .listText': {
                fontSize: '15px',
                letterSpacing: '0.02em',
                opacity: '0.75',
            },
        },
    };
};

const DrawerItem: React.FC<IDrawerItemProps> = React.forwardRef(function Button(
    props: IDrawerItemProps,
    ref: React.Ref<HTMLInputElement>,
) {
    const { children, classes, className, ...other } = props;
    return (
        <ListItem className={clsx(classes.root, className)} button ref={ref} {...other}>
            {children}
        </ListItem>
    );
});

export default withStyles(styles, { name: 'MuiDrawerItem' })(DrawerItem);
