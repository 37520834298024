import { useSlate } from 'slate-react';
import { Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React from 'react';
import { isMarkActive } from '../../helpers/validations';
import { toggleMark } from '../../helpers/togglers';
import PropTypes from 'prop-types';

function MarkButton({ format, children }) {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1} className={'markButton'}>
            <ToggleButton
                value={format}
                selected={isMarkActive(editor, format)}
                onMouseDown={(event) => {
                    event.preventDefault();
                    toggleMark(editor, format);
                }}
                style={{ lineHeight: 1 }}>
                {children}
            </ToggleButton>
        </Box>
    );
}

MarkButton.propTypes = {
    format: PropTypes.string,
    children: PropTypes.object,
};

export default MarkButton;
