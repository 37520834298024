module.exports = {
    getTranslations: {
        bankSelector: {
            cs: {
                'cs.common.bankSelector.title': 'Vyberte svou banku',
                'cs.common.bankSelector.subtitle': 'Vyberte vaší zemi',
                'cs.common.bankSelector.search.placeholder': 'např. Jméno banky',
            },
            nl: {
                'nl.common.bankSelector.title': 'Selecteer uw bank',
                'nl.common.bankSelector.subtitle': 'Kies je land',
                'nl.common.bankSelector.search.placeholder': 'b.v. Banknaam',
            },
            en: {
                'en.common.bankSelector.title': 'Select Your Bank',
                'en.common.bankSelector.subtitle': 'Select Your Country',
                'en.common.bankSelector.search.placeholder': 'Bank Name',
            },
            fr: {
                'fr.common.bankSelector.title': 'Sélectionnez votre banque',
                'fr.common.bankSelector.subtitle': 'Sélectionnez votre pays',
                'fr.common.bankSelector.search.placeholder': 'par exemple. Nom de banque',
            },
            de: {
                'de.common.bankSelector.title': 'Wählen Sie Ihre Bank aus',
                'de.common.bankSelector.subtitle': 'Wähle dein Land',
                'de.common.bankSelector.search.placeholder': 'z.B. Bank Name',
            },
            hu: {
                'hu.common.bankSelector.title': 'Válassza ki a bankot',
                'hu.common.bankSelector.subtitle': 'Válaszd ki az országod',
                'hu.common.bankSelector.search.placeholder': 'például. A bank neve',
            },
            it: {
                'it.common.bankSelector.title': 'Seleziona la tua banca.',
                'it.common.bankSelector.subtitle': 'Seleziona il tuo paese',
                'it.common.bankSelector.search.placeholder': 'per esempio. Nome della banca',
            },
            pl: {
                'pl.common.bankSelector.title': 'Wybierz swój bank',
                'pl.common.bankSelector.subtitle': 'Wybierz swój kraj',
                'pl.common.bankSelector.search.placeholder': 'np. Nazwa banku',
            },
            pt: {
                'pt.common.bankSelector.title': 'Selecione seu banco',
                'pt.common.bankSelector.subtitle': 'Escolha o seu país',
                'pt.common.bankSelector.search.placeholder': 'por exemplo. Nome do banco',
            },
            si: {
                'si.common.bankSelector.title': 'Izberite svojo banko',
                'si.common.bankSelector.subtitle': 'Izberite državo',
                'si.common.bankSelector.search.placeholder': 'Primer Ime banke',
            },
            es: {
                'es.common.bankSelector.title': 'Seleccione su banco',
                'es.common.bankSelector.subtitle': 'Selecciona tu pais',
                'es.common.bankSelector.search.placeholder': 'p.ej. Nombre del banco',
            },
        },
        consent: {
            cs: {
                'cs.pisp.consent.title': 'Potvrzení platby',
                'cs.pisp.consent.subtitle1': '"Potvrďte prosím následující údaje o platbě',
                'cs.pisp.consent.singlePayment': 'Jednorázová platba',
                'cs.pisp.consent.bankName': 'Z',
                'cs.pisp.consent.refId': 'Transakce ref.',
                'cs.pisp.consent.button.scrollText':
                    'Posuňte se dolů a přečtěte si podmínky a ustanovení',
                'cs.common.button.accept': 'Přijmout',
                'cs.pisp.consent.beneficiary': 'Na',
                'cs.vrp.consent.title': 'Potvrzení nastavení opakované platby',
                'cs.vrp.consent.subtitle1': 'Potvrďte prosím následující platební údaje ',
                'cs.vrp.consent.refId': 'Transakční číslo',
                'cs.vrp.consent.maxAmountPerPayment': 'maximální částka za platbu',
                'cs.vrp.consent.expiration': 'Platby je možné provádět do',
                'cs.vrp.consent.payee.bankName': 'Banka příjemce',
                'cs.vrp.consent.payee.accountNumber': 'Číslo účtu příjemce',
                'cs.vrp.consent.payee.sortCode': 'Třídicí kód příjemce platby',
                'cs.vrp.consent.payee.iban': 'IBAN příjemce platby',
                'cs.vrp.consent.payee.bic': 'BIC příjemce platby',
                'cs.vrp.consent.payee.legalName': 'Oficiální jméno příjemce platby',
                'cs.vrp.consent.payer.bankName': 'Banka plátce',
                'cs.vrp.consent.payer.accountNumber': 'Číslo účtu plátce',
                'cs.vrp.consent.payer.sortCode': 'Kód řazení plátce',
                'cs.vrp.consent.payer.iban': 'IBAN plátce',
                'cs.vrp.consent.payer.bic': 'BIC plátce',
                'cs.vrp.consent.payer.legalName': 'Právní jméno plátce',
            },
            nl: {
                'nl.pisp.consent.title': 'Betalingsbevestiging',
                'nl.pisp.consent.subtitle1': '"Bevestig de volgende betalingsgegevens',
                'nl.pisp.consent.singlePayment': 'Eenmalige betaling van',
                'nl.pisp.consent.bankName': 'Van',
                'nl.pisp.consent.refId': 'Transactie Refno.',
                'nl.pisp.consent.button.scrollText':
                    'Gelieve naar beneden te scrollen om de voorwaarden te lezen',
                'nl.common.button.accept': 'Aanvaarden',
                'nl.pisp.consent.beneficiary': 'Naar',
                'nl.vrp.consent.title': 'Bevestiging voor terugkerende betaling',
                'nl.vrp.consent.subtitle1': 'Bevestig de volgende betalingsgegevens ',
                'nl.vrp.consent.refId': 'Referentie-ID',
                'nl.vrp.consent.maxAmountPerPayment': 'Max bedrag per betaling',
                'nl.vrp.consent.expiration': 'U kunt betalen totdat',
                'nl.vrp.consent.payee.bankName': 'Bank van begunstigde',
                'nl.vrp.consent.payee.accountNumber': 'Rekeningnummer begunstigde',
                'nl.vrp.consent.payee.sortCode': 'Sorteercode begunstigde',
                'nl.vrp.consent.payee.iban': 'IBAN van de begunstigde',
                'nl.vrp.consent.payee.bic': 'BIC van de begunstigde',
                'nl.vrp.consent.payee.legalName': 'Wettelijke naam begunstigde',
                'nl.vrp.consent.payer.bankName': 'Betaalbank',
                'nl.vrp.consent.payer.accountNumber': 'Rekeningnummer betaler',
                'nl.vrp.consent.payer.sortCode': 'Sorteercode betaler',
                'nl.vrp.consent.payer.iban': 'IBAN van de betaler',
                'nl.vrp.consent.payer.bic': 'BIC van de betaler',
                'nl.vrp.consent.payer.legalName': 'Wettelijke naam betaler',
            },
            en: {
                'en.pisp.consent.title': 'Payment confirmation',
                'en.pisp.consent.subtitle1': 'Please confirm the following payment details',
                'en.pisp.consent.singlePayment': 'One-time payment of',
                'en.pisp.consent.bankName': 'From',
                'en.pisp.consent.refId': 'Transaction Ref No.',
                'en.pisp.consent.button.scrollText':
                    'Kindly scroll down to read terms and condition',
                'en.common.button.accept': 'Accept',
                'en.pisp.consent.beneficiary': 'To',
                'en.vrp.consent.title': 'Recurring Payment Setup Confirmation',
                'en.vrp.consent.subtitle1': 'Please confirm the following payment details',
                'en.vrp.consent.refId': 'Reference ID',
                'en.vrp.consent.maxAmountPerPayment': 'Max amount per Payment',
                'en.vrp.consent.expiration': 'Payments can be made until',
                'en.vrp.consent.payee.bankName': 'Payee Bank',
                'en.vrp.consent.payee.accountNumber': 'Payee Account Number',
                'en.vrp.consent.payee.sortCode': 'Payee Sort Code',
                'en.vrp.consent.payee.iban': 'Payee IBAN',
                'en.vrp.consent.payee.bic': 'Payee BIC',
                'en.vrp.consent.payee.legalName': 'Payee Legal Name',
                'en.vrp.consent.payer.bankName': 'Payer Bank',
                'en.vrp.consent.payer.accountNumber': 'Payer Account Number',
                'en.vrp.consent.payer.sortCode': 'Payer Sort Code',
                'en.vrp.consent.payer.iban': 'Payer IBAN',
                'en.vrp.consent.payer.bic': 'Payer BIC',
                'en.vrp.consent.payer.legalName': 'Payer Legal Name',
            },
            fr: {
                'fr.pisp.consent.title': 'Confirmation de paiement',
                'fr.pisp.consent.subtitle1': '"Veuillez confirmer les détails de paiement suivants',
                'fr.pisp.consent.singlePayment': 'Paiement unique de',
                'fr.pisp.consent.bankName': 'À partir de',
                'fr.pisp.consent.refId': 'Transaction Ref n °',
                'fr.pisp.consent.button.scrollText':
                    'Veuillez faire défiler la page vers le bas pour lire les termes et conditions',
                'fr.common.button.accept': "J'accepte",
                'fr.pisp.consent.beneficiary': 'À',
                'fr.vrp.consent.title': 'Confirmation de la configuration du paiement récurrent',
                'fr.vrp.consent.subtitle1': 'Veuillez confirmer les détails de paiement suivants',
                'fr.vrp.consent.refId': "Pièce d'identité",
                'fr.vrp.consent.maxAmountPerPayment': 'Montant maximum par paiement',
                'fr.vrp.consent.expiration': "Les paiements peuvent être effectués jusqu'au",
                'fr.vrp.consent.payee.bankName': 'Banque bénéficiaire',
                'fr.vrp.consent.payee.accountNumber': 'Numéro de compte du bénéficiaire',
                'fr.vrp.consent.payee.sortCode': 'Code de tri du bénéficiaire',
                'fr.vrp.consent.payee.iban': 'IBAN du bénéficiaire',
                'fr.vrp.consent.payee.bic': 'BIC du bénéficiaire',
                'fr.vrp.consent.payee.legalName': 'Nom légal du bénéficiaire',
                'fr.vrp.consent.payer.bankName': 'Banque du payeur',
                'fr.vrp.consent.payer.accountNumber': 'Numéro de compte du payeur',
                'fr.vrp.consent.payer.sortCode': 'Code de tri du payeur',
                'fr.vrp.consent.payer.iban': 'IBAN payeur',
                'fr.vrp.consent.payer.bic': 'BIC du payeur',
                'fr.vrp.consent.payer.legalName': 'Nom légal du payeur',
            },
            de: {
                'de.pisp.consent.title': 'Zahlungsbestätigung',
                'de.pisp.consent.subtitle1': 'Bitte bestätigen Sie die folgenden Zahlungsdetails',
                'de.pisp.consent.singlePayment': 'Einmalige Zahlung von',
                'de.pisp.consent.bankName': 'Von',
                'de.pisp.consent.refId': 'Transaktion Ref-Nr.',
                'de.pisp.consent.button.scrollText':
                    'Bitte scrollen Sie nach unten, um die Allgemeinen Geschäftsbedingungen zu lesen.',
                'de.common.button.accept': 'Akzeptieren',
                'de.pisp.consent.beneficiary': 'Empfänger',
                'de.vrp.consent.title': 'Bestätigung der Einrichtung wiederkehrender Zahlungen',
                'de.vrp.consent.subtitle1': 'Bitte bestätigen Sie die folgenden Zahlungsdetails ',
                'de.vrp.consent.refId': 'Referenz ID',
                'de.vrp.consent.maxAmountPerPayment': 'Höchstbetrag pro Zahlung',
                'de.vrp.consent.expiration': 'Zahlungen sind bis möglich',
                'de.vrp.consent.payee.bankName': 'Bank des Zahlungsempfängers',
                'de.vrp.consent.payee.accountNumber': 'Kontonummer des Zahlungsempfängers',
                'de.vrp.consent.payee.sortCode': 'Bankleitzahl des Zahlungsempfängers',
                'de.vrp.consent.payee.iban': 'IBAN des Zahlungsempfängers',
                'de.vrp.consent.payee.bic': 'BIC des Zahlungsempfängers',
                'de.vrp.consent.payee.legalName': 'Rechtsgültiger Name des Zahlungsempfängers',
                'de.vrp.consent.payer.bankName': 'Zahler Bank',
                'de.vrp.consent.payer.accountNumber': 'Zahlerkontonummer',
                'de.vrp.consent.payer.sortCode': 'Bankleitzahl des Zahlungspflichtigen',
                'de.vrp.consent.payer.iban': 'Zahler-IBAN',
                'de.vrp.consent.payer.bic': 'Zahler-BIC',
                'de.vrp.consent.payer.legalName': 'Rechtlicher Name des Zahlers',
            },
            hu: {
                'hu.pisp.consent.title': 'Fizetési visszaigazolás',
                'hu.pisp.consent.subtitle1': 'Kérjük, erősítse meg a következő fizetési adatokat',
                'hu.pisp.consent.singlePayment': 'Egykori kifizetés',
                'hu.pisp.consent.bankName': 'Tól től',
                'hu.pisp.consent.refId': 'Tranzakció ref.',
                'hu.pisp.consent.button.scrollText':
                    'Kérjük, görgessen lefelé a feltételek elolvasásához.',
                'hu.common.button.accept': 'Elfogad',
                'hu.pisp.consent.beneficiary': 'Kedvezményezett',
                'hu.vrp.consent.title': 'Ismétlődő fizetés beállításának megerősítése',
                'hu.vrp.consent.subtitle1': 'Kérjük, erősítse meg az alábbi fizetési részleteket ',
                'hu.vrp.consent.refId': 'Hivatkozási azonosító',
                'hu.vrp.consent.maxAmountPerPayment': 'Befizetésenkénti maximális összeg',
                'hu.vrp.consent.expiration': 'óráig lehet befizetni',
                'hu.vrp.consent.payee.bankName': 'Kedvezményezett bank',
                'hu.vrp.consent.payee.accountNumber': 'Kedvezményezett számlaszáma',
                'hu.vrp.consent.payee.sortCode': 'Kedvezményezett rendezési kódja',
                'hu.vrp.consent.payee.iban': 'Kedvezményezett IBAN',
                'hu.vrp.consent.payee.bic': 'Kedvezményezett BIC',
                'hu.vrp.consent.payee.legalName': 'Kedvezményezett hivatalos neve',
                'hu.vrp.consent.payer.bankName': 'Fizető Bank',
                'hu.vrp.consent.payer.accountNumber': 'Fizetői számlaszám',
                'hu.vrp.consent.payer.sortCode': 'Fizető rendezési kód',
                'hu.vrp.consent.payer.iban': 'Fizető IBAN',
                'hu.vrp.consent.payer.bic': 'Fizető BIC',
                'hu.vrp.consent.payer.legalName': 'Fizető hivatalos neve',
            },
            it: {
                'it.pisp.consent.title': 'Conferma di pagamento',
                'it.pisp.consent.subtitle1':
                    'Si prega di confermare i seguenti dettagli di pagamento',
                'it.pisp.consent.singlePayment': 'Pagamento una tantum di',
                'it.pisp.consent.bankName': 'A partire dal',
                'it.pisp.consent.refId': 'Transazione Ref No.',
                'it.pisp.consent.button.scrollText':
                    'Scorri in basso per leggere i termini e le condizioni.',
                'it.common.button.accept': 'Accettare',
                'it.pisp.consent.beneficiary': 'Per',
                'it.vrp.consent.title': 'Conferma della configurazione del pagamento ricorrente',
                'it.vrp.consent.subtitle1':
                    'Conferma della configurazione del pagamento ricorrente',
                'it.vrp.consent.refId': 'ID di riferimento',
                'it.vrp.consent.maxAmountPerPayment': 'Importo massimo per pagamento',
                'it.vrp.consent.expiration': 'I pagamenti possono essere effettuati fino al',
                'it.vrp.consent.payee.bankName': 'Banca beneficiaria',
                'it.vrp.consent.payee.accountNumber': 'Numero di conto del beneficiario',
                'it.vrp.consent.payee.sortCode': 'Codice di ordinamento del beneficiario',
                'it.vrp.consent.payee.iban': 'IBAN beneficiario',
                'it.vrp.consent.payee.bic': 'BIC beneficiario',
                'it.vrp.consent.payee.legalName': 'Nome legale del beneficiario',
                'it.vrp.consent.payer.bankName': 'Banca pagante',
                'it.vrp.consent.payer.accountNumber': 'Numero di conto del pagatore',
                'it.vrp.consent.payer.sortCode': 'Codice di ordinamento del pagatore',
                'it.vrp.consent.payer.iban': 'IBAN pagatore',
                'it.vrp.consent.payer.bic': 'BIC pagatore',
                'it.vrp.consent.payer.legalName': 'Nome legale del pagatore',
            },
            pl: {
                'pl.pisp.consent.title': 'Potwierdzenie płatności',
                'pl.pisp.consent.subtitle1': 'Potwierdź następujące szczegóły płatności',
                'pl.pisp.consent.singlePayment': 'Jednorazowa wypłata',
                'pl.pisp.consent.bankName': 'Z',
                'pl.pisp.consent.refId': 'Transakcja Nr ref',
                'pl.pisp.consent.button.scrollText':
                    'Proszę przewinąć w dół, aby przeczytać warunki i zasady',
                'pl.common.button.accept': 'Zaakceptować',
                'pl.pisp.consent.beneficiary': 'Do',
                'pl.vrp.consent.title': 'Potwierdzenie konfiguracji płatności cyklicznych',
                'pl.vrp.consent.subtitle1': 'Proszę potwierdzić następujące szczegóły płatności ',
                'pl.vrp.consent.refId': 'Numer referencyjny',
                'pl.vrp.consent.maxAmountPerPayment': 'Maksymalna kwota na płatność',
                'pl.vrp.consent.expiration': 'Płatności można dokonywać do',
                'pl.vrp.consent.payee.bankName': 'Bank odbiorcy płatności',
                'pl.vrp.consent.payee.accountNumber': 'Numer konta odbiorcy',
                'pl.vrp.consent.payee.sortCode': 'Kod rozliczeniowy odbiorcy płatności',
                'pl.vrp.consent.payee.iban': 'IBAN odbiorcy płatności',
                'pl.vrp.consent.payee.bic': 'BIC odbiorcy płatności',
                'pl.vrp.consent.payee.legalName': 'Nazwa prawna odbiorcy płatności',
                'pl.vrp.consent.payer.bankName': 'Bank płatnika',
                'pl.vrp.consent.payer.accountNumber': 'Numer konta płatnika',
                'pl.vrp.consent.payer.sortCode': 'Kod rozliczeniowy płatnika',
                'pl.vrp.consent.payer.iban': 'IBAN płatnika',
                'pl.vrp.consent.payer.bic': 'BIC płatnika',
                'pl.vrp.consent.payer.legalName': 'Nazwa prawna płatnika',
            },
            pt: {
                'pt.pisp.consent.title': 'Confirmação de pagamento',
                'pt.pisp.consent.subtitle1':
                    'Por favor, confirme os seguintes detalhes de pagamento',
                'pt.pisp.consent.singlePayment': 'Pagamento único de',
                'pt.pisp.consent.bankName': 'A partir de',
                'pt.pisp.consent.refId': 'Transação Ref No.',
                'pt.pisp.consent.button.scrollText':
                    'Por favor, desça para ler os termos e condições',
                'pt.common.button.accept': 'Aceitar',
                'pt.pisp.consent.beneficiary': 'Para',
                'pt.vrp.consent.title': 'Confirmação de configuração de pagamento recorrente',
                'pt.vrp.consent.subtitle1':
                    'Por favor, confirme os seguintes detalhes de pagamento ',
                'pt.vrp.consent.refId': 'referência ID',
                'pt.vrp.consent.maxAmountPerPayment': 'Valor máximo por pagamento',
                'pt.vrp.consent.expiration': 'Os pagamentos podem ser feitos até',
                'pt.vrp.consent.payee.bankName': 'Banco do Beneficiário',
                'pt.vrp.consent.payee.accountNumber': 'Número da conta do beneficiário',
                'pt.vrp.consent.payee.sortCode': 'Código de classificação do beneficiário',
                'pt.vrp.consent.payee.iban': 'IBAN do beneficiário',
                'pt.vrp.consent.payee.bic': 'BIC do Beneficiário',
                'pt.vrp.consent.payee.legalName': 'Nome Legal do Beneficiário',
                'pt.vrp.consent.payer.bankName': 'Banco pagador',
                'pt.vrp.consent.payer.accountNumber': 'Número da conta do pagador',
                'pt.vrp.consent.payer.sortCode': 'Código de classificação do pagador',
                'pt.vrp.consent.payer.iban': 'IBAN do pagador',
                'pt.vrp.consent.payer.bic': 'BIC do pagador',
                'pt.vrp.consent.payer.legalName': 'Nome Legal do Pagador',
            },
            si: {
                'si.pisp.consent.title': 'Potrdilo plačila',
                'si.pisp.consent.subtitle1': 'Prosimo, potrdite naslednje podatke o plačilu',
                'si.pisp.consent.singlePayment': 'Enkratno vnaprejšnje plačilo',
                'si.pisp.consent.bankName': 'Od',
                'si.pisp.consent.refId': 'Transakcije Referenčna št.',
                'si.pisp.consent.button.scrollText':
                    'Pomaknite se navzdol, da preberete pogoje in določila',
                'si.common.button.accept': 'Sprejmi',
                'si.pisp.consent.beneficiary': 'Za',
                'si.vrp.consent.title': 'Ponavljajoča se potrditev nastavitve plačila',
                'si.vrp.consent.subtitle1': 'Prosimo, potrdite naslednje podatke o plačilu ',
                'si.vrp.consent.refId': 'Referenčni ID',
                'si.vrp.consent.maxAmountPerPayment': 'Največji znesek na plačilo',
                'si.vrp.consent.expiration': 'Plačilo je možno do',
                'si.vrp.consent.payee.bankName': 'Banka prejemnika plačila',
                'si.vrp.consent.payee.accountNumber': 'Številka računa prejemnika plačila',
                'si.vrp.consent.payee.sortCode': 'Razvrstitvena koda prejemnika plačila',
                'si.vrp.consent.payee.iban': 'IBAN prejemnika plačila',
                'si.vrp.consent.payee.bic': 'BIC prejemnika plačila',
                'si.vrp.consent.payee.legalName': 'Pravno ime prejemnika plačila',
                'si.vrp.consent.payer.bankName': 'Banka plačnika',
                'si.vrp.consent.payer.accountNumber': 'Številka računa plačnika',
                'si.vrp.consent.payer.sortCode': 'Koda za razvrščanje plačnika',
                'si.vrp.consent.payer.iban': 'Plačnik IBAN',
                'si.vrp.consent.payer.bic': 'BIC plačnika',
                'si.vrp.consent.payer.legalName': 'Uradno ime plačnika',
            },
            es: {
                'es.pisp.consent.title': 'Confirmación de pago',
                'es.pisp.consent.subtitle1': 'Por favor confirme los siguientes detalles de pago',
                'es.pisp.consent.singlePayment': 'Pago único de',
                'es.pisp.consent.bankName': 'Desde',
                'es.pisp.consent.refId': 'Transacción ref.',
                'si.pisp.consent.button.scrollText':
                    'Por favor, desplácese hacia abajo para leer los términos y condiciones',
                'es.common.button.accept': 'Aceptar',
                'es.pisp.consent.beneficiary': 'Beneficiario / a',
                'es.vrp.consent.title': 'Confirmación de configuración de pago recurrente',
                'es.vrp.consent.subtitle1': '"Por favor, confirme los siguientes detalles de pago',
                'es.vrp.consent.refId': 'Identificación de referencia',
                'es.vrp.consent.maxAmountPerPayment': 'Cantidad máxima por pago',
                'es.vrp.consent.expiration': 'Los pagos pueden hacerse hasta',
                'es.vrp.consent.payee.bankName': 'Banco del beneficiario',
                'es.vrp.consent.payee.accountNumber': 'Número de cuenta del beneficiario',
                'es.vrp.consent.payee.sortCode': 'Código de clasificación del beneficiario',
                'es.vrp.consent.payee.iban': 'Beneficiario IBAN',
                'es.vrp.consent.payee.bic': 'Beneficiario BIC',
                'es.vrp.consent.payee.legalName': 'Nombre legal del beneficiario',
                'es.vrp.consent.payer.bankName': 'banco pagador',
                'es.vrp.consent.payer.accountNumber': 'Número de cuenta del pagador',
                'es.vrp.consent.payer.sortCode': 'Código de clasificación del pagador',
                'es.vrp.consent.payer.iban': 'IBAN del pagador',
                'es.vrp.consent.payer.bic': 'BIC del pagador',
                'es.vrp.consent.payer.legalName': 'Nombre legal del pagador',
            },
        },
        paymentConfirmation: {
            cs: {
                'cs.pisp.paymentInitStatus.title': 'Stav zahájení platby',
                'cs.pisp.paymentInitStatus.amount': 'Množství',
                'cs.pisp.paymentInitStatus.date': 'datum',
                'cs.pisp.paymentInitStatus.bankName': 'Banka',
                'cs.pisp.paymentInitStatus.beneficiary': 'Příjemce platby',
                'cs.common.button.continueToMerchant': 'Pokračovat na {MERCHANT_NAME}',
                'cs.pisp.paymentInitStatus.status_code.SUCCESS': 'Úspěch',
                'cs.pisp.paymentInitStatus.status_code.INITIATED':
                    'Čekající - zkontrolujte prosím u své banky nebo obchodníka',
                'cs.pisp.paymentInitStatus.status_code.PROCESSING':
                    'Nevyřízené - Zkontrolujte prosím svou banku nebo obchodníka pro konečný status před zahájením jakékoli další akce nebo znovu odeslat platbu.',
                'cs.pisp.paymentInitStatus.status_code.SENT': 'Poslaný',
                'cs.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Žádost o zahájení platby je neúspěšná z důvodu nedostatečných fondů',
                'cs.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Vaše žádost o zahájení platby je neúspěšná. Obraťte se prosím na svou banku',
                'cs.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Vaše žádost o zahájení platby je neúspěšná',
                'cs.vrp.initStatus.title': 'Stav nastavení opakující se platby',
                'cs.vrp.initStatus.status_message.AUTHORIZED':
                    'Pokud nebudete automaticky přesměrováni zpět na {MERCHANT_NAME} do {SECONDS} s, klikněte níže na tlačítko "Vrátit".',
                'cs.vrp.initStatus.status_message.PENDING':
                    'Čekáme, až vaše banka zahájí nastavení opakujících se plateb. Konečný stav získáte u své banky nebo u obchodníka {MERCHANT_NAME}.',
                'cs.vrp.initStatus.status_message.FAILED':
                    'Váš požadavek na nastavení opakujících se plateb byl neúspěšný, protože se nám nepodařilo kontaktovat banku',
                'cs.vrp.initStatus.status_message.REVOKED':
                    'Váš opakovaný požadavek na nastavení plateb byl neúspěšný, protože tento požadavek byl odvolán.',
                'cs.vrp.initStatus.status_message.REJECTED':
                    'Požadavek byl zamítnut, protože došlo k problému s nastavením opakujících se plateb. Pokud potřebujete další informace, kontaktujte obchodníka {MERCHANT_NAME}.',
            },
            nl: {
                'nl.pisp.paymentInitStatus.title': 'Betalingsinitiatiestatus',
                'nl.pisp.paymentInitStatus.amount': 'Hoeveelheid',
                'nl.pisp.paymentInitStatus.date': 'Datum',
                'nl.pisp.paymentInitStatus.bankName': 'Bank',
                'nl.pisp.paymentInitStatus.beneficiary': 'Betaler',
                'nl.common.button.continueToMerchant': 'Continuer vers {MERCHANT_NAME}',
                'nl.pisp.paymentInitStatus.status_code.SUCCESS': 'Succes',
                'nl.pisp.paymentInitStatus.status_code.INITIATED':
                    'In behandeling - Neem contact op met uw bank of handelaar',
                'nl.pisp.paymentInitStatus.status_code.PROCESSING':
                    'In afwachting - Neem contact op met uw bank of handelaar voor de definitieve status voordat u verdere actie onderneemt of de betaling opnieuw indient.',
                'nl.pisp.paymentInitStatus.status_code.SENT': 'Verstuurd',
                'nl.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Uw betalingsopvraag is niet succesvol vanwege onvoldoende fondsen',
                'nl.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Uw betalingsopvraag is niet succesvol. Neem contact op met uw bank',
                'nl.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Uw betalingsopvraag is niet succesvol',
                'nl.vrp.initStatus.title': 'Instellingsstatus terugkerende betaling',
                'nl.vrp.initStatus.status_message.AUTHORIZED':
                    'Als u niet binnen {SECONDS} seconden automatisch wordt teruggestuurd naar {MERCHANT_NAME}, klikt u hieronder op de knop "Retourneren".',
                'nl.vrp.initStatus.status_message.PENDING':
                    'We wachten tot uw bank de instelling voor terugkerende betalingen start. Neem contact op met uw bank of de {MERCHANT_NAME} voor de definitieve status.',
                'nl.vrp.initStatus.status_message.FAILED':
                    'Uw verzoek voor het instellen van terugkerende betalingen is niet gelukt omdat we de bank niet kunnen bereiken',
                'nl.vrp.initStatus.status_message.REVOKED':
                    'Uw verzoek voor het instellen van terugkerende betalingen is mislukt omdat dit verzoek is ingetrokken.',
                'nl.vrp.initStatus.status_message.REJECTED':
                    'Uw verzoek voor het instellen van terugkerende betalingen is mislukt omdat dit verzoek is ingetrokken.',
            },
            en: {
                'en.pisp.paymentInitStatus.title': 'Payment Initiation Status',
                'en.pisp.paymentInitStatus.amount': 'Amount',
                'en.pisp.paymentInitStatus.date': 'Date',
                'en.pisp.paymentInitStatus.bankName': 'Bank',
                'en.pisp.paymentInitStatus.beneficiary': 'Payee',
                'en.common.button.continueToMerchant': 'Continue to {MERCHANT_NAME}',
                'en.pisp.paymentInitStatus.status_code.SUCCESS': 'Success',
                'en.pisp.paymentInitStatus.status_code.INITIATED':
                    'Pending- Please check with your bank or merchant',
                'en.pisp.paymentInitStatus.status_code.PROCESSING':
                    'Pending - Please check with your bank or merchant for the final status before taking any further action or resubmitting the payment.',
                'en.pisp.paymentInitStatus.status_code.SENT': 'Sent',
                'en.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Your payment initiation request is unsuccessful due to insufficient funds',
                'en.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Your payment initiation request is unsuccessful. Please contact your bank',
                'en.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Your payment initiation request is unsuccessful',
                'en.vrp.initStatus.title': 'Recurring Payment Setup Status',
                'en.vrp.initStatus.status_message.AUTHORIZED':
                    'If you are not automatically redirected back to {MERCHANT_NAME} in {SECONDS} seconds, click the "Return" button below.',
                'en.vrp.initStatus.status_message.PENDING':
                    'We are waiting for your bank to initiate the recurring payment setup. Please check with your bank or the {MERCHANT_NAME} for the final status.',
                'en.vrp.initStatus.status_message.FAILED':
                    'Your recurring payment setup request is unsuccessful as we are unable to reach the bank',
                'en.vrp.initStatus.status_message.REVOKED':
                    'Your recurring payment setup request is unsuccessful as this request has been revoked.',
                'en.vrp.initStatus.status_message.REJECTED':
                    'The request has been rejected as there was a problem with your recurring payment setup. Contact the {MERCHANT_NAME} if you require more information.',
            },
            fr: {
                'fr.pisp.paymentInitStatus.title': 'Statut d initiation des paiements',
                'fr.pisp.paymentInitStatus.amount': 'Quantité',
                'fr.pisp.paymentInitStatus.date': 'Date',
                'fr.pisp.paymentInitStatus.bankName': 'Banque',
                'fr.pisp.paymentInitStatus.beneficiary': 'Bénéficiaire',
                'fr.common.button.continueToMerchant': 'Continuer vers {MERCHANT_NAME}',
                'fr.pisp.paymentInitStatus.status_code.SUCCESS': 'Succès',
                'fr.pisp.paymentInitStatus.status_code.INITIATED':
                    'En attente, veuillez vérifier avec votre banque ou votre marchand',
                'fr.pisp.paymentInitStatus.status_code.PROCESSING':
                    'En attente - veuillez vérifier auprès de votre banque ou de votre marchand pour le statut final avant de prendre toute action supplémentaire ou de soumettre à nouveau le paiement.',
                'fr.pisp.paymentInitStatus.status_code.SENT': 'Expédié',
                'fr.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    "Votre demande d'initiation de paiement est infructueuse en raison de fonds insuffisants",
                'fr.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    "Votre demande d'initiation de paiement est infructueuse. S'il vous plaît contacter votre banque",
                'fr.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    "Votre demande d'initiation de paiement est infructueuse",
                'fr.vrp.initStatus.title': 'État de la configuration des paiements récurrents',
                'fr.vrp.initStatus.status_message.AUTHORIZED':
                    'Si vous n\'êtes pas automatiquement redirigé vers {MERCHANT_NAME} dans {SECONDS} secondes, cliquez sur le bouton "Retour" ci-dessous.',
                'fr.vrp.initStatus.status_message.PENDING':
                    "Nous attendons que votre banque initie la configuration des paiements récurrents. Veuillez vérifier auprès de votre banque ou du {MERCHANT_NAME} pour connaître l'état final'",
                'fr.vrp.initStatus.status_message.FAILED':
                    'Votre demande de configuration de paiement récurrent a échoué car nous ne pouvons pas joindre la banque',
                'fr.vrp.initStatus.status_message.REVOKED':
                    'Votre demande de configuration de paiement récurrent a échoué car cette demande a été révoquée.',
                'fr.vrp.initStatus.status_message.REJECTED':
                    "La demande a été rejetée en raison d'un problème avec votre configuration de paiement récurrent. Contactez le {MERCHANT_NAME} si vous avez besoin de plus d'informations.",
            },
            de: {
                'de.pisp.paymentInitStatus.title': 'Zahlungs-Initiierungsstatus.',
                'de.pisp.paymentInitStatus.amount': 'Höhe',
                'de.pisp.paymentInitStatus.date': 'Datum',
                'de.pisp.paymentInitStatus.bankName': 'Bank',
                'de.pisp.paymentInitStatus.beneficiary': 'Zahlungsempfänger',
                'de.common.button.continueToMerchant': 'Weiter zu {MERCHANT_NAME}',
                'de.pisp.paymentInitStatus.status_code.SUCCESS': 'Erfolg',
                'de.pisp.paymentInitStatus.status_code.INITIATED':
                    'Pending- Bitte überprüfen Sie mit Ihrer Bank oder Ihrem Händler',
                'de.pisp.paymentInitStatus.status_code.PROCESSING':
                    'Angemeldet - Bitte erkundigen Sie sich an Ihre Bank oder Händler für den endgültigen Status, bevor Sie weitere Maßnahmen ergreifen oder die Zahlung erneut einreichen.',
                'de.pisp.paymentInitStatus.status_code.SENT': 'Gesendet',
                'de.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Ihre Zahlungsanwaltsanfrage ist aufgrund unzureichender Fonds nicht erfolgreich',
                'de.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Ihre Zahlungsanwaltsanfrage ist nicht erfolgreich. Bitte wenden Sie sich an Ihre Bank',
                'de.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Ihre Zahlungsanwaltsanfrage ist nicht erfolgreich',
                'de.vrp.initStatus.title': 'Einrichtungsstatus für wiederkehrende Zahlungen',
                'de.vrp.initStatus.status_message.AUTHORIZED':
                    'Wenn Sie in {SECONDS} Sekunden nicht automatisch zu {MERCHANT_NAME} zurückgeleitet werden, klicken Sie unten auf die Schaltfläche "Zurück".',
                'de.vrp.initStatus.status_message.PENDING':
                    'Wenn Sie in {SECONDS} Sekunden nicht automatisch zu {MERCHANT_NAME} zurückgeleitet werden, klicken Sie unten auf die Schaltfläche "Zurück".',
                'de.vrp.initStatus.status_message.FAILED':
                    'Ihre Anfrage zur Einrichtung einer wiederkehrenden Zahlung war nicht erfolgreich, da wir die Bank nicht erreichen können',
                'de.vrp.initStatus.status_message.REVOKED':
                    'Ihre Anfrage zur Einrichtung einer wiederkehrenden Zahlung war nicht erfolgreich, da diese Anfrage widerrufen wurde.',
                'de.vrp.initStatus.status_message.REJECTED':
                    'Die Anfrage wurde abgelehnt, da ein Problem mit der Einrichtung Ihrer wiederkehrenden Zahlung aufgetreten ist. Wenden Sie sich an {MERCHANT_NAME}, wenn Sie weitere Informationen benötigen.',
            },
            hu: {
                'hu.pisp.paymentInitStatus.title': 'Fizetési kezdeményezési állapot',
                'hu.pisp.paymentInitStatus.amount': 'Összeg',
                'hu.pisp.paymentInitStatus.date': 'Dátum',
                'hu.pisp.paymentInitStatus.bankName': 'Bank',
                'hu.pisp.paymentInitStatus.beneficiary': 'Kedvezményezett',
                'hu.common.button.continueToMerchant': 'Tovább a {MERCHANT_NAME} oldalára',
                'hu.pisp.paymentInitStatus.status_code.SUCCESS': 'Siker',
                'hu.pisp.paymentInitStatus.status_code.INITIATED':
                    'Függőben van, kérjük, ellenőrizze bankjával vagy kereskedőjével',
                'hu.pisp.paymentInitStatus.status_code.PROCESSING':
                    'Függőben - Kérjük, ellenőrizze bankjával vagy kereskedőjét a végső állapotért, mielőtt további intézkedéseket végezne vagy küldje el a fizetést.',
                'hu.pisp.paymentInitStatus.status_code.SENT': 'Küldött',
                'hu.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'A fizetési megkezdési kérelem sikertelensége nem megfelelő pénzeszközök miatt',
                'hu.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'A fizetési beavatkozási kérelem sikertelen. Kérjük, lépjen kapcsolatba a bankjával',
                'hu.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'A fizetési beavatkozási kérelem sikertelen',
                'hu.vrp.initStatus.title': 'Ismétlődő fizetési beállítás állapota',
                'hu.vrp.initStatus.status_message.AUTHORIZED':
                    'Ha a rendszer {SECONDS} másodpercen belül nem irányítja vissza automatikusan a(z) {MERCHANT_NAME} kereskedőhöz, kattintson a lenti "Visszatérés" gombra.',
                'hu.vrp.initStatus.status_message.PENDING':
                    'Várjuk, hogy bankja kezdeményezze az ismétlődő fizetés beállítását. Kérjük, ellenőrizze bankjánál vagy a(z) {MERCHANT_NAME} kereskedőnél a végső állapotot.',
                'hu.vrp.initStatus.status_message.FAILED':
                    'Az ismétlődő fizetés beállítási kérése sikertelen, mert nem tudjuk elérni a bankot',
                'hu.vrp.initStatus.status_message.REVOKED':
                    'Az ismétlődő fizetési beállítási kérelme sikertelen, mivel ezt a kérést visszavonták',
                'hu.vrp.initStatus.status_message.REJECTED':
                    'A kérelmet elutasították, mivel probléma lépett fel az ismétlődő fizetés beállításával. Ha további információra van szüksége, forduljon a(z) {MERCHANT_NAME} kereskedőhöz.',
            },
            it: {
                'it.pisp.paymentInitStatus.title': 'Pagamento Stato di inizio',
                'it.pisp.paymentInitStatus.amount': 'Quantità',
                'it.pisp.paymentInitStatus.date': 'Data',
                'it.pisp.paymentInitStatus.bankName': 'Banca',
                'it.pisp.paymentInitStatus.beneficiary': 'Beneficiario',
                'it.common.button.continueToMerchant': 'Continua su {MERCHANT_NAME}',
                'it.pisp.paymentInitStatus.status_code.SUCCESS': 'Successo',
                'it.pisp.paymentInitStatus.status_code.INITIATED':
                    'In attesa- Si prega di verificare con la tua banca o commerciante',
                'it.pisp.paymentInitStatus.status_code.PROCESSING':
                    'In attesa - Si prega di verificare con la tua banca o commerciante per lo stato finale prima di intraprendere ulteriori azioni o inviare nuovamente il pagamento del pagamento.',
                'it.pisp.paymentInitStatus.status_code.SENT': 'Spedito',
                'it.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'La tua richiesta di avvio del pagamento è infruttuosa a causa di fondi insufficienti',
                'it.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'La tua richiesta di avvio del pagamento è infruttuosa. Si prega di contattare la tua banca',
                'it.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'La tua richiesta di avvio del pagamento è infruttuosa',
                'it.vrp.initStatus.title': 'Stato di configurazione del pagamento ricorrente',
                'it.vrp.initStatus.status_message.AUTHORIZED':
                    'Se non vieni reindirizzato automaticamente a {MERCHANT_NAME} entro {SECONDS} secondi, fai clic sul pulsante "Restituisci" di seguito.',
                'it.vrp.initStatus.status_message.PENDING':
                    'Stiamo aspettando che la tua banca avvii la configurazione del pagamento ricorrente. Verifica con la tua banca o con {MERCHANT_NAME} lo stato finale',
                'it.vrp.initStatus.status_message.FAILED':
                    'La tua richiesta di configurazione del pagamento ricorrente non va a buon fine poiché non siamo in grado di raggiungere la banca',
                'it.vrp.initStatus.status_message.REVOKED':
                    'La tua richiesta di configurazione del pagamento ricorrente non è andata a buon fine poiché questa richiesta è stata revocata.',
                'it.vrp.initStatus.status_message.REJECTED':
                    'La richiesta è stata rifiutata perché si è verificato un problema con la configurazione del pagamento ricorrente. Contatta {MERCHANT_NAME} se hai bisogno di ulteriori informazioni.',
            },
            pl: {
                'pl.pisp.paymentInitStatus.title': 'Stan inicjacji płatności',
                'pl.pisp.paymentInitStatus.amount': 'Kwota',
                'pl.pisp.paymentInitStatus.date': 'Data',
                'pl.pisp.paymentInitStatus.bankName': 'Bank',
                'pl.pisp.paymentInitStatus.beneficiary': 'Odbiorca płatności',
                'pl.common.button.continueToMerchant': 'Przejdź do sprzedawcy {MERCHANT_NAME}',
                'pl.pisp.paymentInitStatus.status_code.SUCCESS': 'Powodzenie',
                'pl.pisp.paymentInitStatus.status_code.INITIATED':
                    'Oczekujące - sprawdź z bankiem lub sprzedawcą',
                'pl.pisp.paymentInitStatus.status_code.PROCESSING':
                    'W oczekiwaniu - sprawdź z bankiem lub sprzedawcą do ostatniego statusu przed podjęciem dalszych działań lub ponownego przesłania płatności.',
                'pl.pisp.paymentInitStatus.status_code.SENT': 'Wysłano',
                'pl.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Twoje wniosek o wszczęcie płatności jest nieudany z powodu niewystarczających środków',
                'pl.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Twoje żądanie inicjacji płatności nie powiodło się. Skontaktuj się z brzegiem',
                'pl.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Twoje żądanie inicjacji płatności nie powiodło się',
                'pl.vrp.initStatus.title': 'Status konfiguracji płatności cyklicznych',
                'pl.vrp.initStatus.status_message.AUTHORIZED':
                    'Jeśli nie nastąpi automatyczne przekierowanie z powrotem do sprzedawcy {MERCHANT_NAME} w ciągu {SECONDS} sekund, kliknij przycisk "Powrót" poniżej.',
                'pl.vrp.initStatus.status_message.PENDING':
                    'Czekamy, aż Twój bank zainicjuje konfigurację płatności cyklicznych. Sprawdź w swoim banku lub u sprzedawcy {MERCHANT_NAME} ostateczny stan.',
                'pl.vrp.initStatus.status_message.FAILED':
                    'Twoje żądanie konfiguracji płatności cyklicznej nie powiodło się, ponieważ nie możemy skontaktować się z bankiem',
                'pl.vrp.initStatus.status_message.REVOKED':
                    'Twoja prośba o konfigurację płatności cyklicznych nie powiodła się, ponieważ została wycofana.',
                'pl.vrp.initStatus.status_message.REJECTED':
                    'Prośba została odrzucona, ponieważ wystąpił problem z konfiguracją płatności cyklicznych. Skontaktuj się z {MERCHANT_NAME}, jeśli potrzebujesz więcej informacji.',
            },
            pt: {
                'pt.pisp.paymentInitStatus.title': 'Status de iniciação de pagamento',
                'pt.pisp.paymentInitStatus.amount': 'Montante',
                'pt.pisp.paymentInitStatus.date': 'Encontro',
                'pt.pisp.paymentInitStatus.bankName': 'Banco',
                'pt.pisp.paymentInitStatus.beneficiary': 'Beneficiária',
                'pt.common.button.continueToMerchant': 'Continue para {MERCHANT_NAME}',
                'pt.pisp.paymentInitStatus.status_code.SUCCESS': 'Sucesso',
                'pt.pisp.paymentInitStatus.status_code.INITIATED':
                    'Pendente - por favor, verifique com o seu banco ou comerciante',
                'pt.pisp.paymentInitStatus.status_code.PROCESSING':
                    'Pendendo - por favor, verifique com seu banco ou comerciante para o status final antes de tomar qualquer outra ação ou reenviar o pagamento.',
                'pt.pisp.paymentInitStatus.status_code.SENT': 'Enviei',
                'pt.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Seu pedido de iniciação de pagamento não é bem sucedido devido a fundos insuficientes',
                'pt.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Seu pedido de iniciação de pagamento não é bem sucedido. Entre em contato com seu banco',
                'pt.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Seu pedido de iniciação de pagamento não é bem-sucedido',
                'pt.vrp.initStatus.title': 'Status de configuração de pagamento recorrente',
                'pt.vrp.initStatus.status_message.AUTHORIZED':
                    'Se você não for redirecionado automaticamente para {MERCHANT_NAME} em {SECONDS} segundos, clique no botão "Voltar" abaixo.',
                'pt.vrp.initStatus.status_message.PENDING':
                    'Estamos aguardando seu banco iniciar a configuração de pagamento recorrente. Verifique com seu banco ou com o {MERCHANT_NAME} o status final.',
                'pt.vrp.initStatus.status_message.FAILED':
                    'Sua solicitação de configuração de pagamento recorrente não foi bem-sucedida, pois não conseguimos entrar em contato com o banco',
                'pt.vrp.initStatus.status_message.REVOKED':
                    'Sua solicitação de configuração de pagamento recorrente não foi bem-sucedida, pois essa solicitação foi revogada',
                'pt.vrp.initStatus.status_message.REJECTED':
                    'A solicitação foi rejeitada porque houve um problema com sua configuração de pagamento recorrente. Entre em contato com o {MERCHANT_NAME} se precisar de mais informações.',
            },
            si: {
                'si.pisp.paymentInitStatus.title': 'Status plačila',
                'si.pisp.paymentInitStatus.amount': 'Znesek',
                'si.pisp.paymentInitStatus.date': 'Datum',
                'si.pisp.paymentInitStatus.bankName': 'Banka',
                'si.pisp.paymentInitStatus.beneficiary': 'Prejemnik plačila',
                'si.common.button.continueToMerchant': 'Nadaljujte do trgovca {MERCHANT_NAME}',
                'si.pisp.paymentInitStatus.status_code.SUCCESS': 'Uspeh.',
                'si.pisp.paymentInitStatus.status_code.INITIATED':
                    'Čakanja - prosim preverite z banko ali trgovcem',
                'si.pisp.paymentInitStatus.status_code.PROCESSING':
                    'V teku - Prosimo, preverite pri banki ali trgovcu za končni status, preden sprejmete nadaljnje ukrepanje ali ponovno predložite plačilo.',
                'si.pisp.paymentInitStatus.status_code.SENT': 'Pošlje',
                'si.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Zahteva za začetek plačila je neuspešna zaradi nezadostnih sredstev',
                'si.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Vaša zahteva za začetek plačila je neuspešna. Obrnite se na banko',
                'si.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Zahteva za začetek plačila je neuspešna',
                'si.vrp.initStatus.title': 'Stanje nastavitve ponavljajočega se plačila',
                'si.vrp.initStatus.status_message.AUTHORIZED':
                    'Če niste samodejno preusmerjeni nazaj k trgovcu {MERCHANT_NAME} v {SECONDS} sekundah, kliknite spodnji gumb "Vrni".',
                'si.vrp.initStatus.status_message.PENDING':
                    'Čakamo, da vaša banka sproži nastavitev ponavljajočih se plačil. Za končno stanje preverite pri svoji banki ali trgovcu {MERCHANT_NAME}.',
                'si.vrp.initStatus.status_message.FAILED':
                    'Vaša zahteva za ponavljajoče se nastavitev plačila ni uspela, ker ne moremo priti do banke',
                'si.vrp.initStatus.status_message.REVOKED':
                    'Vaša zahteva za ponavljajoče se nastavitev plačila ni uspela, ker je bila ta zahteva preklicana.',
                'si.vrp.initStatus.status_message.REJECTED':
                    'Zahteva je bila zavrnjena, ker je prišlo do težave z nastavitvijo ponavljajočih se plačil. Če potrebujete več informacij, se obrnite na trgovca {MERCHANT_NAME}.',
            },
            es: {
                'es.pisp.paymentInitStatus.title': 'Estado de iniciación de pago',
                'es.pisp.paymentInitStatus.amount': 'Monto',
                'es.pisp.paymentInitStatus.date': 'Fecha',
                'es.pisp.paymentInitStatus.bankName': 'Banco',
                'es.pisp.paymentInitStatus.beneficiary': 'Tenedora',
                'es.common.button.continueToMerchant': 'Continuar hacia {MERCHANT_NAME}',
                'es.pisp.paymentInitStatus.status_code.SUCCESS': 'Éxito',
                'es.pisp.paymentInitStatus.status_code.INITIATED':
                    'Pendiente- Por favor, consulte con su banco o comerciante',
                'es.pisp.paymentInitStatus.status_code.PROCESSING':
                    'Pendiente: consulte con su banco o comerciante por el estado final antes de tomar cualquier otra acción o reenviar el pago.',
                'es.pisp.paymentInitStatus.status_code.SENT': 'Enviado',
                'es.pisp.paymentInitStatus.status_code.INSUFFICIENT_FUNDS':
                    'Su solicitud de iniciación de pago no tiene éxito debido a fondos insuficientes',
                'es.pisp.paymentInitStatus.status_code.FAILURE_INVALID_AMOUNT':
                    'Su solicitud de iniciación de pago no tiene éxito. Por favor, póngase en contacto con su banco',
                'es.pisp.paymentInitStatus.status_code.UNSUCCESSFUL':
                    'Su solicitud de iniciación de pago no tiene éxito',
                'es.vrp.initStatus.title': 'Estado de configuración de pago recurrente',
                'es.vrp.initStatus.status_message.AUTHORIZED':
                    'Si no se le redirige automáticamente a {MERCHANT_NAME} en {SEGUNDOS} segundos, haga clic en el botón "Volver" a continuación.',
                'es.vrp.initStatus.status_message.PENDING':
                    'Estamos esperando que su banco inicie la configuración de pagos recurrentes. Consulte con su banco o {MERCHANT_NAME} para conocer el estado final.',
                'es.vrp.initStatus.status_message.FAILED':
                    'Su solicitud de configuración de pago recurrente no tiene éxito porque no podemos comunicarnos con el banco',
                'es.vrp.initStatus.status_message.REVOKED':
                    'Su solicitud de configuración de pago recurrente no se ha realizado correctamente porque esta solicitud ha sido revocada',
                'es.vrp.initStatus.status_message.REJECTED':
                    'La solicitud ha sido rechazada porque hubo un problema con la configuración de su pago recurrente. Comuníquese con {MERCHANT_NAME} si necesita más información.',
            },
        },
        bankRedirect: {
            cs: {
                'cs.common.bankSpinner.label': 'Budete přesměrováni',
            },
            nl: {
                'nl.common.bankSpinner.label': 'Je wordt doorgestuurd',
            },
            en: {
                'en.common.bankSpinner.label': 'You are being redirected',
            },
            fr: {
                'fr.common.bankSpinner.label': 'Vous êtes redirigé',
            },
            de: {
                'de.common.bankSpinner.label': 'Du wirst umgleitet',
            },
            hu: {
                'hu.common.bankSpinner.label': 'Átirányítás folyamatban',
            },
            it: {
                'it.common.bankSpinner.label': 'Stai per essere reindirizzato',
            },
            pl: {
                'pl.common.bankSpinner.label': 'Zostałeś przekierowany',
            },
            pt: {
                'pt.common.bankSpinner.label': 'Você está sendo redirecionado',
            },
            si: {
                'si.common.bankSpinner.label': 'Preusmerjeni ste',
            },
            es: {
                'es.common.bankSpinner.label': 'Usted está siendo redirigido',
            },
        },
    },
};
