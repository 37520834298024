import { Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../base/App.controller';
import { API_KEY } from '../../constants/authenticationTypes';
import DefaultContainer from '../../layout/DefaultContainer';
import LinkWrapper from '../common/wrappers/LinkWrapper';
import ApiKey from './api-key/ApiKey';
import PublicKey from './public-key/PublicKey';

const { publicRuntimeConfig } = require('next/config').default();
const { externalLinks } = publicRuntimeConfig;

function AuthenticationKeys({ AuthenticationKeysStore, refreshLinks, AppStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'authentication-keys');
    const { authType, handleTabChange, isTpp, isAdmin, AUTH_TYPES } = AuthenticationKeysStore;

    const tabs = Object.keys(AUTH_TYPES).map((type) => {
        return {
            value: AUTH_TYPES[type],
            label: t(type),
        };
    });

    const ActiveTab = authType === API_KEY ? ApiKey : PublicKey;

    return (
        <div className="AuthenticationKeys">
            <DefaultContainer>
                {!isTpp && !isAdmin && <h1>{t('title')}</h1>}
                <div className={'description'}>
                    <span>{t('description')}</span>
                    <span>
                        <LinkWrapper
                            target="_blank"
                            rel="noopener"
                            href={externalLinks.documentation.ALL}>
                            {t('documentation')}
                        </LinkWrapper>
                    </span>
                    <span>{'.'}</span>
                </div>
                <Tabs
                    value={authType}
                    onChange={async (e, value) => {
                        await handleTabChange(AppStore, value);
                        refreshLinks();
                    }}>
                    {tabs.map((tab) => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
                <ActiveTab />
            </DefaultContainer>
        </div>
    );
}

AuthenticationKeys.propTypes = {
    AppStore: PropTypes.object.isRequired,
    AuthenticationKeysStore: PropTypes.object.isRequired,
    refreshLinks: PropTypes.func.isRequired,
};

export default inject('AuthenticationKeysStore', 'AppStore')(observer(AuthenticationKeys));
