import axios from 'axios';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';

import ModalStore from 'components/common/modals/ModalStore';
import IpAddressModalStore from 'components/common/modals/ip-address/IpAddressModalStore';
import TableWrapperStore from 'components/common/wrappers/TableWrapperStore';

// constants
import { IP_ADDRESS } from 'constants/restrictions';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class ManageIpAddressesStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
        this.GenericErrorStore = new ModalStore();
        this.TableWrapperStore = new TableWrapperStore();
        this.IpAddressModalStore = new IpAddressModalStore(this);
    }
    IpAddressRestrictionWarningModal = new ModalStore();

    @observable
    loading = true;
    @observable
    ipRuleText = '';
    @observable
    ipRuleName = '';
    @observable
    restrictionId = '';
    @observable
    restrictions = [];
    @observable
    rowId = '';
    @observable
    selectedRestriction = {};
    @observable
    targetMemberId = '';

    @action
    openIpAddressModal = (type) => {
        this.IpAddressModalStore.openModal(type);
    };

    @action
    openDeleteIpAddressModal = (type) => {
        const ips = this.convertIpRuleTextToArray(this.ipRuleText);

        this.IpAddressModalStore.setDeleteIpRule({
            ips: ips,
            name: this.ipRuleName,
        });
        this.IpAddressModalStore.setSelectedRestrictionRow(this.selectedRestriction);
        this.IpAddressModalStore.openModal(type);
    };

    @action
    openIpWarningModal = (type) => {
        this.IpAddressRestrictionWarningModal.openModal(type);
    };

    @action
    setValues = (restrictionId, ipRuleText, ipRuleName, ruleId) => {
        this.restrictionId = restrictionId;
        this.ipRuleText = ipRuleText;
        this.ipRuleName = ipRuleName;
        this.ruleId = ruleId;
    };

    @action
    handleDelete = flow(
        function* () {
            this.loading = true;
            try {
                const memberId = this.rootStore.memberId;
                const deleteIps = this.IpAddressModalStore.deleteIp.ips.filter(
                    ({ checked }) => checked,
                );

                const deleteAllIpFromRule =
                    deleteIps.length === this.IpAddressModalStore.deleteIp.ips.length;
                if (deleteAllIpFromRule) {
                    yield axios.delete(
                        `/api/member/payment-initiation-restrictions?memberId=${memberId}&restrictionId=${this.restrictionId}`,
                    );
                } else {
                    const body = {
                        memberId: this.targetMemberId,
                        restrictionId: this.restrictionId,
                        ruleId: this.ruleId,
                        updatedRule: {
                            id: this.ruleId,
                            name: this.ipRuleName,
                            text: `IpAddressRestrictedFields(${this.formatIpText()})`,
                            enabled: this.selectedRestriction.enabled,
                            createdAt: this.selectedRestriction.createdAt,
                        },
                    };
                    yield axios.put('/api/member/restriction-rule', body);
                }

                this.IpAddressModalStore.closeModal();
                this.IpAddressRestrictionWarningModal.closeModal();
                this.handleResetValues();
                yield this.handleFetch();
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleFetch = flow(
        function* () {
            this.loading = true;
            this.selectedRestriction = {};
            try {
                this.targetMemberId = this.rootStore.memberId;
                const byRestrictionId = this.restrictionId;
                const res = yield axios.get(
                    `/api/member/payment-initiation-restrictions?byTargetMemberId=${this.targetMemberId}&byRestrictionId=${byRestrictionId}`,
                );
                this.restrictions = res.data.restrictions;
                this.restrictions = this.restrictions.filter(
                    (restriction) => restriction.productType === IP_ADDRESS,
                );
                const rulesList = this.restrictions.length
                    ? this.formatRuleList(this.restrictions[0].rules, this.restrictions[0].id)
                    : [];
                if (rulesList.length) {
                    this.selectedRestriction = this.restrictions[0];
                    this.setValues(
                        this.restrictions[0].id,
                        rulesList[0]?.text,
                        rulesList[0]?.name,
                        rulesList[0]?.id,
                    );
                }

                this.TableWrapperStore.setData(rulesList);
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    updateIpRestrictionRule = (type) => {
        const ips = this.convertIpRuleTextToArray(this.ipRuleText);

        this.IpAddressModalStore.setPreviousRule({
            ips: ips,
            name: this.ipRuleName,
        });

        this.IpAddressModalStore.setSelectedRestrictionRow(this.selectedRestriction);
        this.openIpAddressModal(type);
    };

    @action
    handleResetValues = () => {
        this.selectedRestriction = {};
        this.ruleId = '';
        this.restrictionId = '';
        this.isRule = false;
    };

    formatRuleList = (ruleList, id) => {
        ruleList.map((rule, index) => {
            ruleList[index] = {
                ...rule,
                text: this.formatIpRangeRegexToWild(rule.text),
                productType: IP_ADDRESS,
                restrictionId: id,
            };
        });
        return ruleList;
    };

    convertIpRuleTextToArray = (ipRuleText) => {
        const ipRegex = /originIpAddress not matches "([\d.*]+)"/g;
        const matches = ipRuleText.matchAll(ipRegex);
        const ips = Array.from(matches, (match) => match[1]);

        return ips;
    };

    formatIpRangeRegexToWild = (ipRule) => {
        const ipRegex = '[0-9]{1,3}';
        if (ipRule.indexOf(ipRegex) > -1) {
            return ipRule.replace(ipRegex, '*');
        }

        return ipRule;
    };

    formatIpText = () => {
        return this.IpAddressModalStore.deleteIp.ips
            .filter(({ checked }) => !checked)
            .map(({ ip }) => `originIpAddress not matches "${this.formatIpRangeWildToRegex(ip)}"`)
            .join(' && ');
    };

    formatIpRangeWildToRegex = (ip) => {
        const ipRegex = '[0-9]{1,3}';
        if (ip.indexOf('*') > -1) {
            return ip.replace('*', ipRegex);
        }

        return ip;
    };
}

export default ManageIpAddressesStore;
