import escapeHTML from '../escape-html/escape-html';
import { Text } from 'slate';

export default function serialize(node) {
    if (Text.isText(node)) {
        let string = escapeHTML(node.text);
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underline) {
            string = `<u>${string}</u>`;
        }
        if (node.code) {
            string = `<code>${string}</code>`;
        }
        return string;
    }

    const children = node.children.map((n) => serialize(n)).join('');

    if (node.type === 'paragraph' && node.children.length === 1 && node.children[0].text === '') {
        return '<br>';
    }

    if (node.type === 'image') {
        return `<image src="${node.url}" class="img-consent">${children}</image>`;
    }
    if (children) {
        switch (node.type) {
            case 'block-quote':
                return `<blockquote><div>${children}</div></blockquote>`;
            case 'paragraph':
                if (node.align) {
                    return `<div class="rt-${node.align}">${children}</div>`;
                }
                if (node.indent) {
                    const firstClose = children.indexOf('>');
                    const lastOpen = children.lastIndexOf('<');
                    const child =
                        children.indexOf('<div>') === 0
                            ? children.slice(firstClose + 1, lastOpen)
                            : children;
                    const x = child.length - 1;
                    let y;
                    if (child.includes('</image>')) {
                        y = child.indexOf('</image>');
                        y = y + 7;
                    }
                    const divChild = child.slice(0, y + 1);
                    const spanChild = child.slice(y + 1, x + 1);
                    return `<div class="TextArea-flex-box">${divChild}<span class="Indent-${node.indentPadding}">${spanChild}</span></div>`;
                }
                return `<div>${children}</div>`;
            case 'link':
                return `<a href="${escapeHTML(node.url)}" target="_blank">${children}</a>`;
            case 'heading-one':
                return `<h1>${children}</h1>`;
            case 'heading-two':
                return `<h2>${children}</h2>`;
            case 'list-item':
                return `<li>${children}</li>`;
            case 'numbered-list':
                return `<ol>${children}</ol>`;
            case 'bulleted-list':
                return `<ul>${children}</ul>`;
        }
    }
    return children;
}
