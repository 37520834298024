/**
 * Sort object w.r.t. key
 *
 * @param obj
 * @param key
 * @returns sorted array
 */
export default function sortByKey(obj, key) {
    return obj.sort(function (a, b) {
        return a[key] > b[key] ? 1 : -1;
    });
}
