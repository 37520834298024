import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import { IIpAddressModalProps, Operation } from '../types';
import { Itranslation } from 'types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const IpAddressModal: React.FC<IIpAddressModalProps> = ({ AppStore, IpAddressModalStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'configuration-manageIpAddress',
        'common',
    ]);

    const {
        form,
        deleteIp,
        isDisable,
        isPreviousRule,
        loading,
        handleCheck,
        handleChange,
        handleClose,
        handleModify,
        handleSubmit,
        handleDeleteIpRule,
        previousRule,
        type,
        ipCount,
        updateIpCount,
        handleAddIp,
        handleRemoveIp,
    } = IpAddressModalStore;

    useEffect(() => {
        if (type !== Operation.ADD_IP_RESTRICTION && type !== Operation.ADD) {
            updateIpCount(form.ips.length);
        }
    }, [form.ips]);

    const _renderRow = () => {
        const isAllChecked = deleteIp.ips.every((ip) => ip.checked);

        return (
            <Grid container spacing={4} className="checkbox">
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAllChecked}
                                className="allCheckbox"
                                onChange={(event) => handleCheck('all', event)}
                                name={'all'}
                            />
                        }
                        label={'All'}
                    />
                </Grid>
                {deleteIp.ips.map(({ ip, checked }) => (
                    <React.Fragment key={ip}>
                        <Grid key={ip} item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => handleCheck(ip, event)}
                                        name={ip}
                                    />
                                }
                                label={ip}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        );
    };

    return (
        <DialogWrapper
            open={IpAddressModalStore.isOpen}
            className={'IpAddressModal'}
            title={t(`${IpAddressModalStore.type}`)}
            onClose={handleClose}>
            {type !== Operation.DELETE_IP_RULE && (
                <Grid item xs={12}>
                    <span className="ipInfo">{t('ipInfo')}</span>
                </Grid>
            )}
            <div className={'formContainer'}>
                {(type === 'modifyIpRestriction' || type === 'modifyIpRule') && !isPreviousRule && (
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextFieldWrapper
                                name={'name'}
                                label={t('previousRuleName')}
                                value={previousRule.name}
                                disabled
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={12} className={'previousIp'}>
                            <TextField
                                name={'ip'}
                                value={previousRule.ips}
                                label={t('previousIp')}
                                disabled
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextFieldWrapper
                            name="name"
                            type="text"
                            label={t('name')}
                            value={type === Operation.DELETE_IP_RULE ? deleteIp.name : form.name}
                            onChange={handleChange}
                            disabled={type === Operation.DELETE_IP_RULE}
                            required
                        />
                    </Grid>
                    {type === Operation.DELETE_IP_RULE
                        ? _renderRow()
                        : Array.from({ length: ipCount }, (_, index) => (
                              <React.Fragment key={index}>
                                  <Grid item xs={10}>
                                      <TextFieldWrapper
                                          name="ip"
                                          type="text"
                                          label={t('ip')}
                                          value={form.ips[index]}
                                          placeholder={t('enterIp')}
                                          onChange={(event) => handleChange(event, index)}
                                          required
                                      />
                                  </Grid>
                                  {ipCount > 1 && index < ipCount - 1 && (
                                      <Grid container xs={2} justify="flex-end" alignItems="center">
                                          <StyledIcon
                                              onClick={() => handleRemoveIp(index)}
                                              className={'icon edit removeIp'}>
                                              <RemoveCircleOutlineIcon />
                                          </StyledIcon>
                                      </Grid>
                                  )}
                              </React.Fragment>
                          ))}
                    {type !== Operation.DELETE_IP_RULE && (
                        <React.Fragment>
                            <Grid container xs={2} justify="flex-end" alignItems="center">
                                <StyledIcon onClick={handleAddIp} className="icon edit addIp">
                                    <AddCircleOutlineIcon />
                                </StyledIcon>
                            </Grid>
                            <Grid item xs={12}>
                                <span className={'ipAddressExamples'}>
                                    {t('ipAddressExamples')}
                                </span>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={handleClose}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        {type === Operation.DELETE_IP_RULE ? (
                            <ButtonWrapper
                                size={'large'}
                                className={'deleteRuleButton'}
                                onClick={handleDeleteIpRule}
                                loading={loading}
                                disabled={isDisable}>
                                {t('common:delete')}
                            </ButtonWrapper>
                        ) : (
                            <ButtonWrapper
                                size={'large'}
                                color={'primary'}
                                className={'saveButton'}
                                onClick={type === 'addIpRestriction' ? handleSubmit : handleModify}
                                loading={loading}
                                disabled={isDisable}>
                                {t('common:save')}
                            </ButtonWrapper>
                        )}
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore')(observer(IpAddressModal));
