import { Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useSlate } from 'slate-react';
import { isBlockActive } from '../../helpers/validations';
import { toggleBlock } from '../../helpers/togglers';

function BlockButton({ format, children }) {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1} className={'blockButton'}>
            <ToggleButton
                value={format}
                selected={isBlockActive(editor, format)}
                onMouseDown={(event) => {
                    event.preventDefault();
                    toggleBlock(editor, format);
                }}
                style={{ lineHeight: 1 }}>
                {children}
            </ToggleButton>
        </Box>
    );
}

BlockButton.propTypes = {
    format: PropTypes.string,
    children: PropTypes.object,
};

export default BlockButton;
