import { Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../../base/App.controller';
import { ECDSA_SHA256, ED25519, RS256 } from '../../../../../constants/keyAlgorithms';
import CustomThemeProvider from '../../../theme/CustomThemeProvider';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import DialogWrapper from '../../../wrappers/DialogWrapper';
import FormWrapper from '../../../wrappers/FormWrapper';
import SelectWrapper from '../../../wrappers/SelectWrapper';
import TextFieldWrapper from '../../../wrappers/TextFieldWrapper';
import { IPublicKeyModal } from '../../types';
import { Itranslation } from 'types';

const PublicKeyModal: React.FC<IPublicKeyModal> = ({ AppStore, AuthenticationKeysStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'authentication-keys',
        'common',
    ]);
    const {
        publicKeyLoading,
        handleUpload,
        PublicKeyModal,
        isFilled,
        form,
        handleChange,
    } = AuthenticationKeysStore!;

    const keyAlgorithms = [ED25519, ECDSA_SHA256, RS256];

    const ref = React.createRef();

    const handleSubmit = () => {
        return handleUpload(AppStore);
    };

    return (
        <CustomThemeProvider alt>
            <DialogWrapper
                open={PublicKeyModal.isOpen}
                onClose={PublicKeyModal.closeModal}
                className={'PublicKeyModal'}
                title={t('uploadPublicKey')}>
                <div className={'formContainer'}>
                    <FormWrapper
                        className="form sectionParameters"
                        formRef={ref}
                        onSubmit={handleSubmit}
                        isValid={isFilled}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <TextFieldWrapper
                                    name="publicKey"
                                    type="text"
                                    label={t('publicKeyLabel')}
                                    value={form.publicKey}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectWrapper
                                    name="keyAlgorithm"
                                    label={t('keyAlgorithm')}
                                    value={form.keyAlgorithm}
                                    onChange={handleChange}
                                    required>
                                    {keyAlgorithms.map((s) => (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </SelectWrapper>
                            </Grid>
                        </Grid>
                    </FormWrapper>
                </div>
                <div className={'buttonContainer'}>
                    <Grid container justify="space-between" spacing={5}>
                        <Grid item xs={6}>
                            <ButtonWrapper
                                color="secondary"
                                className={'closeButton'}
                                onClick={PublicKeyModal.closeModal}>
                                {t('common:cancel')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonWrapper
                                size={'large'}
                                color="primary"
                                className={'saveButton'}
                                loading={publicKeyLoading}
                                disabled={!isFilled}
                                onClick={handleSubmit}>
                                {t('common:save')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </div>
            </DialogWrapper>
        </CustomThemeProvider>
    );
};

export default inject('AppStore', 'AuthenticationKeysStore')(observer(PublicKeyModal));
