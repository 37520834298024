import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import GeoLocationComponent from './GeoLocationComponent';
import AppController from 'base/App.controller';
import { Itranslation } from './types';

const TabGeolocation: React.FC<any> = ({ ConfigurationStore, AppStore }) => {
    const { t }: Itranslation = AppController.getTranslation(
        AppStore?.scope,
        'configuration-geolocation',
    );
    return (
        <div className={'geoLocations'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <GeoLocationComponent
                        t={t}
                        GeoLocationStore={ConfigurationStore.GeoLocationStore}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

TabGeolocation.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabGeolocation));
