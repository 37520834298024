import React from 'react';
import { observer } from 'mobx-react';
import makeStyles from '@material-ui/styles/makeStyles';

// Components
import DialogWrapper from 'components/common/wrappers/DialogWrapper';

interface Props {
    className?: string;
    Store: any;
    disableBackdropClick: boolean;
    disableEscapeKeyDown: boolean;
    buttons: React.ReactNode;
    title: string;
    description?: string;
}

function Modal({
    className,
    Store,
    disableBackdropClick,
    disableEscapeKeyDown,
    buttons,
    title,
    description,
}: Props) {
    const classes = useStyles();
    const _onClose = async () => {
        Store.closeModal();
    };

    return (
        <DialogWrapper
            open={Store.isOpen}
            onClose={_onClose}
            disableEscapeKeyDown={disableEscapeKeyDown}
            disableBackdropClick={disableBackdropClick}
            hideDivider
            className={className}>
            <h2>{title}</h2>
            {description && <div className={'description'}>{description}</div>}
            <br />
            <div className={classes.buttonsRow}>{buttons}</div>
        </DialogWrapper>
    );
}

export default observer(Modal);

const useStyles = makeStyles(() => {
    return {
        buttonsRow: {
            display: 'flex',
            '& > button': {
                marginLeft: 8,
            },
        },
    };
});
