import { Grid, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../base/App.controller';
import warning from '../../../../images/warning.svg';
import ButtonWrapper from '../../wrappers/ButtonWrapper';
import DialogWrapper from '../../wrappers/DialogWrapper';
import { IApiKeyModal } from '../types';
import { Itranslation } from 'types';

const styles: any = () => {
    return {
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '100%',
        },
        closeButton: {
            color: 'var(--milled-wine)',
            '&:hover': {
                color: 'var(--revolver) !important',
            },
        },
        resetButton: {
            backgroundColor: 'var(--bittersweet)',
            '&:hover, &:focus': {
                backgroundColor: 'var(--bittersweet)',
            },
        },
        firstDescription: {
            marginBottom: '15px',
        },
        root: {
            textAlign: 'center',
        },
    };
};

const ApiKeyModal: React.FC<IApiKeyModal> = ({ AppStore, AuthenticationKeysStore, classes }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'authentication-keys');
    const { apiKeyLoading, handleCreate, ApiKeyModal } = AuthenticationKeysStore!;

    return (
        <DialogWrapper
            open={ApiKeyModal.isOpen}
            onClose={ApiKeyModal.closeModal}
            className={clsx(classes.root, 'ApiKeyModal')}
            hideDivider>
            <img className="api-key-modal-img" src={warning} />
            <h2>{t('modalTitle')}</h2>
            <div className={classes.firstDescription}>{t('modalDescription1')}</div>
            <div>{t('modalDescription2')}</div>
            <Grid container spacing={3} className={classes.buttonContainer}>
                <Grid item xs={6}>
                    <ButtonWrapper
                        variant="contained"
                        color="secondary"
                        className={classes.closeButton}
                        onClick={ApiKeyModal.closeModal}>
                        {t('cancel')}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={6}>
                    <ButtonWrapper
                        variant="contained"
                        color="primary"
                        className={classes.resetButton}
                        loading={apiKeyLoading}
                        onClick={() => handleCreate(AppStore)}>
                        {t('resetKey')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
        </DialogWrapper>
    );
};

export default withStyles(styles, { name: 'ApiKeyModal' })(
    inject('AppStore', 'AuthenticationKeysStore')(observer(ApiKeyModal)),
);
