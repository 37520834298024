import { Divider, Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AppController from '../../../../../base/App.controller';
import { Preview } from 'components/common/wrappers/rich-editor';
import RichEditorModal from 'components/common/modals/rich-editor/RichEditorModal';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';

// icons
import EditIcon from '@material-ui/icons/Edit';

// helpers
import serialize from 'helpers/serialize-slate/serialize.js';
import SectionSave from '../helpers/section-save/SectionSave';

// constants
import { getLanguages } from 'constants/languages';

function Consent({ AppStore, ConfigurationStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-features');
    const {
        getPrevFeatures,
        handleTranslationChange,
        loading,
        handleConsentSave,
        consentKeys,
        rootStore,
        region,
        language,
        setLanguage,
        setRegion,
        populateTAndCRegionalConfigurations,
        tAndCTranslations,
        handleTAndCSectionSave,
    } = ConfigurationStore.FeatureStore;

    useEffect(() => {
        getPrevFeatures();
        populateTAndCRegionalConfigurations();
    }, []);

    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState('');

    const onClose = () => {
        setOpen(false);
    };
    const openRichEditorModal = (cK) => {
        setCurrent(cK);
        setOpen(true);
    };

    const _handleChange = (value) => {
        let serializedInput = '';
        value.map((v) => (serializedInput += serialize(v)));
        serializedInput = serializedInput === '<br>' ? '' : serializedInput;
        handleTranslationChange(current, serializedInput);
        handleConsentSave(current, serializedInput);
    };

    const _handleSave = async () => {
        await handleTAndCSectionSave();
        rootStore.GenericSuccessStore.openModal();
    };

    const _handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const _handleRegionChange = (e) => {
        setRegion(e.target.value);
    };

    const formatHtml = (previewText) => {
        return `<div class='preview-t-and-c-text'>${previewText}</div>`;
    };

    return (
        <div className={'Consent'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <h2>{t('tCLinks')}</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={4} className={'language'}>
                            <SelectWrapper
                                name="region"
                                label={t('region')}
                                value={region}
                                onChange={_handleRegionChange}
                                required>
                                {['eu', 'gb'].map((c) => (
                                    <MenuItem key={c} value={c}>
                                        {c.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={4} className={'language'}>
                            <SelectWrapper
                                name="language"
                                label={t('language')}
                                value={language}
                                onChange={_handleLanguageChange}
                                required>
                                {getLanguages.map((c) => (
                                    <MenuItem key={c.code} value={c.code}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={'content'} justify="flex-start">
                        {consentKeys().map((cK) => (
                            <Grid container key={cK} item xs={12}>
                                <Grid item xs={2} className={'termskey'}>
                                    {t(
                                        tAndCTranslations[region]?.translations[
                                            `${language}.${cK}`
                                        ].messageKey
                                            .split('.')[1]
                                            .toUpperCase(),
                                    )}
                                </Grid>
                                <Grid className={'editor'}>
                                    <Preview
                                        html={`<div class='preview-t-and-c-text'>${
                                            tAndCTranslations[region]?.translations[
                                                `${language}.${cK}`
                                            ].text
                                        }</div>`}
                                        isOnlyDisplay
                                    />
                                </Grid>
                                <Grid>
                                    <StyledIcon
                                        onClick={() => openRichEditorModal(cK)}
                                        className={'icon edit'}>
                                        <EditIcon />
                                    </StyledIcon>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid className={'divider'} item xs={12}>
                        <Divider />
                    </Grid>
                    <SectionSave
                        loading={loading}
                        onClick={() => _handleSave()}
                        saveButtonText={t('common:save')}
                    />
                </Grid>
                {open && (
                    <RichEditorModal
                        open={open}
                        onClose={() => onClose()}
                        plainText={
                            tAndCTranslations[region]?.translations[`${language}.${current}`].text
                        }
                        disabled={[
                            'heading-one',
                            'heading-two',
                            'numbered-list',
                            'bulleted-list',
                            'block-quote',
                            'code',
                            'image',
                            'indent-increase',
                            'indent-decrease',
                            'linkV2',
                        ]}
                        buttonText={t('confirm')}
                        onConfirm={_handleChange}
                        title={t('modifyTC')}
                        displayKey={t(
                            tAndCTranslations[region]?.translations[
                                `${language}.${current}`
                            ].messageKey
                                .split('.')[1]
                                .toUpperCase(),
                        )}
                        formatHtml={formatHtml}
                    />
                )}
            </Grid>
        </div>
    );
}

Consent.propTypes = {
    AppStore: PropTypes.object.isRequired,
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(Consent));
