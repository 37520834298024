import { countries } from 'constants/countries';
import sortByKey from '../sort-by-key/sort-by-key';

/**
 * Sort the given list of unsorted 2 word country code list
 * @param unsorted
 * @returns sorted countries
 */
export default function sortedCountries(unsorted) {
    const sorted = [];
    unsorted.map((c) => {
        sorted.push(countries.filter((f) => f.code === c)[0]);
    });
    return sortByKey(sorted, 'name')
        .filter((s) => s !== undefined)
        .map((s) => s.code);
}
