import { Box, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import isUrl from 'is-url';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
const { publicRuntimeConfig } = require('next/config').default();

// wrappers
import TextFieldWrapper from '../../../TextFieldWrapper';

// helpers
import compareArrays from 'helpers/compare-arrays/compare-arrays';

// slate utils
import { useSlate } from 'slate-react';
import { insertLink, unwrapLink } from '../../helpers/links';
import { isLinkActive } from '../../helpers/validations';
import CaptureInfo from '../../modal/CaptureInfo';

// styles
import { makeStyles } from '@material-ui/styles';

// makes CaptureInfo independent of Theme Provider
const useStyles = makeStyles(() => {
    return {
        input: {
            background: 'var(--ghost-white)',
        },
        selected: {
            background: 'var(--ghost-white)',
            marginBottom: '40px',
        },
    };
});

function LinkButton({ format, children, isV2 }) {
    const editor = useSlate();
    const [open, setOpen] = useState(false);
    const tosLink = `${publicRuntimeConfig.paymentApp}/session/{{SESSION_ID}}/terms-and-conditions`;
    const ppLink = 'https://token.io/policies/web-app-privacy-policy';
    const [link, setLink] = useState('');
    const [selected, setSelected] = useState('');
    const [v2LinkType, setV2LinkType] = useState('TOS');
    const classes = useStyles();

    // holds the editor selection
    const editorSelection = useRef(editor.selection);
    useEffect(() => {
        setSelected('');
        if (isV2) setLink(tosLink);

        if (open) {
            if (editor.selection && editor.selection.anchor) {
                const child = editor.children[editor.selection.anchor.path[0]];
                const inner = child.children[editor.selection.anchor.path[1]].text;
                let start = editor.selection.anchor.offset;
                let end = editor.selection.focus.offset;
                // swap if selected from right to left
                if (start > end) {
                    const temp = start;
                    start = end;
                    end = temp;
                }
                if (compareArrays(editor.selection.anchor.path, editor.selection.focus.path)) {
                    setSelected(inner?.slice(start, end));
                }
            }
            editorSelection.current = editor.selection;
        }
    }, [open]);

    const handleV2LinkTypeChange = (e) => {
        e.preventDefault();
        setV2LinkType(e.target.value);
        if (e.target.value === 'TOS') setLink(tosLink);
        else if (e.target.value === 'PP') setLink(ppLink);
        else if (e.target.value === 'CUSTOM') setLink('');
    };

    const handleChange = (e) => {
        e.preventDefault();
        setLink(e.target.value);
    };

    const handleSave = (e) => {
        e.preventDefault();
        editor.selection = editorSelection.current;
        insertLink(editor, link);
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const v2LinkSelector = () => {
        return (
            <>
                <RadioGroup
                    aria-label="selectedTextLinkType"
                    name="selectedTextLinkType"
                    value={v2LinkType}
                    onChange={handleV2LinkTypeChange}
                    className={'radioGroup'}>
                    <FormControlLabel
                        value={'TOS'}
                        control={<Radio />}
                        label={'Link to Terms of service page {{TOS_LINK}}'}
                        className="radioOption"
                    />
                    <FormControlLabel
                        value={'PP'}
                        control={<Radio />}
                        label={'Link to Privacy policy page {{PP_LINK}}'}
                        className="radioOption"
                    />
                    <FormControlLabel
                        value={'CUSTOM'}
                        control={<Radio />}
                        label={'External link'}
                        className="radioOption"
                    />
                </RadioGroup>
                {v2LinkType === 'CUSTOM' && (
                    <TextFieldWrapper
                        name="link"
                        type="text"
                        value={link}
                        InputProps={{
                            className: classes.input,
                        }}
                        placeholder="Enter the URL"
                        onChange={handleChange}
                        hideOptional
                        autoFocus
                    />
                )}
            </>
        );
    };

    return (
        <Box ml={1} mt={1} className={'linkButton'}>
            <ToggleButton
                value={format}
                selected={isLinkActive(editor)}
                onMouseDown={(event) => {
                    event.preventDefault();
                    if (isLinkActive(editor)) {
                        unwrapLink(editor);
                        return;
                    }
                    setLink('');
                    setOpen(true);
                }}
                style={{ lineHeight: 1 }}>
                {children}
            </ToggleButton>
            <CaptureInfo
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                disabled={!isUrl(link)}
                tooltipText={'Enter a valid link to continue.'}>
                <div style={{ margin: '30px 0' }}>
                    <TextFieldWrapper
                        name="selected"
                        label="Selected text"
                        value={selected || 'Multiple lines selected'}
                        InputProps={{
                            className: classes.selected,
                        }}
                        disabled
                        hideOptional
                    />
                    {isV2 ? (
                        v2LinkSelector()
                    ) : (
                        <TextFieldWrapper
                            name="link"
                            type="text"
                            label="Enter the URL for the text selected."
                            value={link}
                            InputProps={{
                                className: classes.input,
                            }}
                            onChange={handleChange}
                            hideOptional
                            autoFocus
                        />
                    )}
                </div>
            </CaptureInfo>
        </Box>
    );
}

LinkButton.propTypes = {
    format: PropTypes.string,
    children: PropTypes.object,
    isV2: PropTypes.bool,
};

export default LinkButton;
