import { Editor, Element as SlateElement, Transforms } from 'slate';
import { isAlignActive } from '../validations';

const aligns = ['left', 'center', 'right'];

export const insertAlign = (editor, align) => {
    wrapAlign(editor, align);
};

export const unwrapAlign = (editor, align) => {
    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === 'paragraph' &&
            n.align === align,
    });
};

// wrap text with the link provided.
export const wrapAlign = (editor, align) => {
    if (!editor.selection) {
        return;
    }

    if (isAlignActive(editor, align)) {
        unwrapAlign(editor, align);
        return;
    }

    // unwrapping all if applied
    for (let i = 0; i < 3; i++) {
        unwrapAlign(editor, aligns[i]);
    }

    const alignSlate = {
        type: 'paragraph',
        align,
        children: [],
    };

    // Here, we have to make use of Transform.move to update editor.selection's focus to end of the editor.
    // https://docs.slatejs.org/v/v0.47/slate-core/commands
    const children = editor.children;
    const editorLength = JSON.stringify(children).match(/paragraph/g)?.length;
    for (let i = 0; i < editorLength; i++) {
        Transforms.move(editor, { unit: 'line', edge: 'focus' });
    }

    // this is to move to the start of editor.
    editor.selection = {
        ...editor.selection,
        anchor: {
            path: [0, 0],
            offset: 0,
        },
    };
    Transforms.wrapNodes(editor, alignSlate);
};
