import { Checkbox, Grid, MenuItem, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { countries as allCountries } from 'constants/countries';
import CustomThemeProvider from '../../../../common/theme/CustomThemeProvider';
import ButtonWrapper from '../../../../common/wrappers/ButtonWrapper';
import SelectWrapper from '../../../../common/wrappers/SelectWrapper';
import TableWrapper, { cellConstructor } from '../../../../common/wrappers/TableWrapper';
import TextFieldWrapper from '../../../../common/wrappers/TextFieldWrapper';
import BanksModal from '../../../../common/modals/configuration/features/banks/BanksModal';
import sortedCountries from 'helpers/sorted-countries/sorted-countries';

const f = (k) => k || '---';

function BankSearch({ t, FeatureStore, BanksModalStore, isEditable, readOnly }) {
    const {
        displayFeatures,
        fetchPermittedBanks,
        banks,
        handleChange,
        TableWrapperStore,
        getFilteredBanks,
        handleClear,
        checkIds,
        handleBankCheck,
        tableLoading,
        fetchBanksMemberId,
        handleTablePageChange,
    } = FeatureStore;

    const { countries, country, bankGroup, bankName } = displayFeatures;

    useEffect(() => {
        fetchPermittedBanks(isEditable, country);
    }, [country]);

    const [state, setState] = useState(Math.random());

    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
        fetchPermittedBanks(isEditable, country);
    };

    const openBankListModal = () => {
        BanksModalStore.initializeStore(displayFeatures, fetchBanksMemberId, () => {
            setOpen(true);
        });
    };

    const rowCheckBox = (row) => {
        const key = Math.random();
        const checkId = row.id || row.bankGroup;
        return (
            <TableCell key={key + state} align={'center'}>
                <CustomThemeProvider alt>
                    <Checkbox
                        checked={checkIds[checkId]}
                        name={checkId}
                        onChange={_handleBankCheck}
                    />
                </CustomThemeProvider>
            </TableCell>
        );
    };

    const _handleBankCheck = (e) => {
        handleBankCheck(e);
        setState(Math.random());
    };

    const headerCheckBox = () => {
        return (
            <CustomThemeProvider alt>
                <Checkbox checked={checkIds['all']} name={'all'} onChange={_handleBankCheck} />
            </CustomThemeProvider>
        );
    };

    const header = [t('country'), t('bankGroup'), t('bankName'), t('bankId')];
    const cellTemplates = [
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'country',
                value: f(row.country),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'bankGroup',
                value: f(row.bankGroup),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'name',
                value: f(row.name),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'id',
                value: f(row.id),
            }),
    ];

    // adding subCellTemplates
    const subCellTemplates = [
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'name',
                value: f(row.name),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'id',
                value: f(row.id),
            }),
    ];

    if (isEditable && banks.length > 0) {
        header.unshift(headerCheckBox());
        cellTemplates.unshift((row) => rowCheckBox(row));

        subCellTemplates.unshift((row) => rowCheckBox(row));
    }

    return (
        <>
            <CustomThemeProvider alt={isEditable}>
                <Grid container spacing={4} className={'bankList'}>
                    <Grid item xs={4}>
                        <SelectWrapper
                            name="country"
                            label={t('country')}
                            value={country}
                            onChange={(e) => handleChange(e, isEditable)}
                            required>
                            {sortedCountries(Object.keys(countries).map((c) => c)).map((c) => (
                                <MenuItem key={c} value={c}>
                                    {allCountries.filter((a) => a.code === c)[0].name}
                                </MenuItem>
                            ))}
                        </SelectWrapper>
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="bankGroup"
                            label={t('bankGroup')}
                            value={bankGroup}
                            onChange={handleChange}
                            hideOptional
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="bankName"
                            label={t('bankName')}
                            value={bankName}
                            onChange={handleChange}
                            hideOptional
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify="flex-end"
                    className={'filters'}
                    spacing={3}
                    style={{ marginTop: '20px' }}>
                    <Grid item xs={3}>
                        <ButtonWrapper
                            className={'clearButton'}
                            onClick={() => handleClear(isEditable)}>
                            {t('clear')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={3}>
                        <ButtonWrapper
                            className={'filterButton'}
                            onClick={() => getFilteredBanks()}>
                            {t('filter')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                {!readOnly && !isEditable && (
                    <Grid container justify="flex-end">
                        <Grid item xs={3} style={{ margin: '20px 0 -30px 0' }}>
                            <ButtonWrapper
                                className="changeBanks"
                                onClick={() => openBankListModal()}
                                tooltipText={t('selectBanksSubTitle')}>
                                {t('selectBanks')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                )}
                {!readOnly && !isEditable && banks.length > 0 && (
                    <Grid container justify="flex-start">
                        <Grid item xs={3} style={{ margin: '40px 0 -20px 0' }}>
                            <div className={'selectedBankTitle'}>{t('selectedBanksList')}</div>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TableWrapper
                        header={header}
                        loading={tableLoading}
                        cellTemplates={cellTemplates}
                        TableWrapperStore={TableWrapperStore}
                        noDataMessage={t('noBanks')}
                        disableRowSelect
                        canExpand
                        subCellTemplates={subCellTemplates}
                        pageCallback={(page) => handleTablePageChange(page)}
                    />
                </Grid>
                {open && (
                    <BanksModal
                        t={t}
                        title={t('chooseBanks')}
                        open={open}
                        onClose={() => onClose()}
                        FeatureStore={FeatureStore}
                        BanksModalStore={BanksModalStore}
                    />
                )}
            </CustomThemeProvider>
        </>
    );
}

BankSearch.propTypes = {
    t: PropTypes.func.isRequired,
    FeatureStore: PropTypes.object.isRequired,
    BanksModalStore: PropTypes.object,
    isEditable: PropTypes.bool,
    readOnly: PropTypes.bool,
};

BankSearch.defaultProps = {
    isEditable: false,
    readOnly: false,
};

export default observer(BankSearch);
