import escapeHtml from 'escape-html';
export default function escapeHTML(text) {
    let string = escapeHtml(text);
    // escaping out all the tags
    string = string
        .replace(/&lt;/g, '')
        .replace(/&amp;/g, '')
        .replace(/&gt;/g, '')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
    return string;
}
