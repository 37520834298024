module.exports = {
    getTAndCMessages: {
        AIS: {
            'en.aisp.consent.termsAndConditions.loader': {
                messageKey: 'en.aisp_tc',
                text: '',
            },
            'fr.aisp.consent.termsAndConditions.loader': {
                messageKey: 'fr.aisp_tc',
                text: '',
            },
            'cs.aisp.consent.termsAndConditions.loader': {
                messageKey: 'cs.aisp_tc',
                text: '',
            },
            'nl.aisp.consent.termsAndConditions.loader': {
                messageKey: 'nl.aisp_tc',
                text: '',
            },
            'de.aisp.consent.termsAndConditions.loader': {
                messageKey: 'de.aisp_tc',
                text: '',
            },
            'hu.aisp.consent.termsAndConditions.loader': {
                messageKey: 'hu.aisp_tc',
                text: '',
            },
            'it.aisp.consent.termsAndConditions.loader': {
                messageKey: 'it.aisp_tc',
                text: '',
            },
            'pl.aisp.consent.termsAndConditions.loader': {
                messageKey: 'pl.aisp_tc',
                text: '',
            },
            'pt.aisp.consent.termsAndConditions.loader': {
                messageKey: 'pt.aisp_tc',
                text: '',
            },
            'si.aisp.consent.termsAndConditions.loader': {
                messageKey: 'si.aisp_tc',
                text: '',
            },
            'es.aisp.consent.termsAndConditions.loader': {
                messageKey: 'es.aisp_tc',
                text: '',
            },
        },
        PIS: {
            'en.pisp.consent.termsAndConditions.loader': {
                messageKey: 'en.pisp_tc',
                text: '',
            },
            'fr.pisp.consent.termsAndConditions.loader': {
                messageKey: 'fr.pisp_tc',
                text: '',
            },
            'cs.pisp.consent.termsAndConditions.loader': {
                messageKey: 'cs.pisp_tc',
                text: '',
            },
            'nl.pisp.consent.termsAndConditions.loader': {
                messageKey: 'nl.pisp_tc',
                text: '',
            },
            'de.pisp.consent.termsAndConditions.loader': {
                messageKey: 'de.pisp_tc',
                text: '',
            },
            'hu.pisp.consent.termsAndConditions.loader': {
                messageKey: 'hu.pisp_tc',
                text: '',
            },
            'it.pisp.consent.termsAndConditions.loader': {
                messageKey: 'it.pisp_tc',
                text: '',
            },
            'pl.pisp.consent.termsAndConditions.loader': {
                messageKey: 'pl.pisp_tc',
                text: '',
            },
            'pt.pisp.consent.termsAndConditions.loader': {
                messageKey: 'pt.pisp_tc',
                text: '',
            },
            'si.pisp.consent.termsAndConditions.loader': {
                messageKey: 'si.pisp_tc',
                text: '',
            },
            'es.pisp.consent.termsAndConditions.loader': {
                messageKey: 'es.pisp_tc',
                text: '',
            },
        },
        VRP: {
            'en.vrp.consent.termsAndConditions.loader': {
                messageKey: 'en.vrp_tc',
                text: '',
            },
            'fr.vrp.consent.termsAndConditions.loader': {
                messageKey: 'fr.vrp_tc',
                text: '',
            },
            'cs.vrp.consent.termsAndConditions.loader': {
                messageKey: 'cs.vrp_tc',
                text: '',
            },
            'nl.vrp.consent.termsAndConditions.loader': {
                messageKey: 'nl.vrp_tc',
                text: '',
            },
            'de.vrp.consent.termsAndConditions.loader': {
                messageKey: 'de.vrp_tc',
                text: '',
            },
            'hu.vrp.consent.termsAndConditions.loader': {
                messageKey: 'hu.vrp_tc',
                text: '',
            },
            'it.vrp.consent.termsAndConditions.loader': {
                messageKey: 'it.vrp_tc',
                text: '',
            },
            'pl.vrp.consent.termsAndConditions.loader': {
                messageKey: 'pl.vrp_tc',
                text: '',
            },
            'pt.vrp.consent.termsAndConditions.loader': {
                messageKey: 'pt.vrp_tc',
                text: '',
            },
            'si.vrp.consent.termsAndConditions.loader': {
                messageKey: 'si.vrp_tc',
                text: '',
            },
            'es.vrp.consent.termsAndConditions.loader': {
                messageKey: 'es.vrp_tc',
                text: '',
            },
        },
        CBPII: {
            'en.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'en.cbpii_tc',
                text: '',
            },
            'fr.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'fr.cbpii_tc',
                text: '',
            },
            'cs.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'cs.cbpii_tc',
                text: '',
            },
            'nl.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'nl.cbpii_tc',
                text: '',
            },
            'de.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'de.cbpii_tc',
                text: '',
            },
            'hu.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'hu.cbpii_tc',
                text: '',
            },
            'it.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'it.cbpii_tc',
                text: '',
            },
            'pl.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'pl.cbpii_tc',
                text: '',
            },
            'pt.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'pt.cbpii_tc',
                text: '',
            },
            'si.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'si.cbpii_tc',
                text: '',
            },
            'es.cbpii.consent.termsAndConditions.loader': {
                messageKey: 'es.cbpii_tc',
                text: '',
            },
        },
    },
};
