import { useSlate } from 'slate-react';
import { Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React from 'react';
import { isAlignActive } from '../../helpers/validations';
import { insertAlign } from '../../helpers/align';
import PropTypes from 'prop-types';

function AlignButton({ format, children }) {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1} className={'AlignButton'}>
            <ToggleButton
                value={format}
                selected={isAlignActive(editor, format)}
                onMouseDown={(event) => {
                    event.preventDefault();
                    insertAlign(editor, format);
                }}
                style={{ lineHeight: 1 }}>
                {children}
            </ToggleButton>
        </Box>
    );
}

AlignButton.propTypes = {
    format: PropTypes.string,
    children: PropTypes.object,
};

export default AlignButton;
