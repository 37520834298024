import { Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import AppController from 'base/App.controller';

function Refunds({ AppStore, RefundsStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'refunds');

    const { allTabs, tabValue, handleTabChange, isTpp, isAdmin } = RefundsStore;
    const ActiveTab = allTabs[tabValue];

    return (
        <div className="Refunds">
            {!isTpp && !isAdmin && <h1>{t('title')}</h1>}
            <Tabs value={tabValue} onChange={handleTabChange}>
                {Object.keys(allTabs).map((key) => (
                    <Tab key={key} label={t(key)} value={key} />
                ))}
            </Tabs>
            <ActiveTab />
        </div>
    );
}

Refunds.propTypes = {
    AppStore: PropTypes.object.isRequired,
    RefundsStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'RefundsStore')(observer(Refunds));
