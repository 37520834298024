import { Grid } from '@material-ui/core';
import React, { useState } from 'react';

// helpers
import deserializeSlate from 'helpers/deserialize-slate/deserialize';
import serialize from 'helpers/serialize-slate/serialize.js';

import ButtonWrapper from '../../wrappers/ButtonWrapper';
import DialogWrapper from '../../wrappers/DialogWrapper';
import RichEditor, { Preview, Tags } from '../../wrappers/rich-editor';
// types
import { IRichEditorModalProps } from '../types';

const RichEditorModal: React.FC<IRichEditorModalProps> = ({
    open,
    onClose,
    title = 'Add HTML Text',
    plainText = '',
    disabled,
    placeholder,
    buttonText,
    onConfirm,
    displayKey,
    tags = [],
    tagTitle,
    formatHtml,
    hidePreview = false,
    maxWidth = 'lg',
}) => {
    const [value, setValue] = useState(deserializeSlate(plainText));

    const _onConfirm = () => {
        onConfirm(value);
        onClose();
    };

    const updatePlainText = () => {
        let serializedInput = '';
        value.map((v) => (serializedInput += serialize(v)));
        serializedInput = serializedInput === '<br>' ? '' : serializedInput;
        return serializedInput;
    };

    return (
        <DialogWrapper
            open={open}
            onClose={onClose}
            hideDivider
            maxWidth={maxWidth}
            className={'RichEditorModal'}>
            <h2>{title}</h2>
            {displayKey && <h3>{displayKey}</h3>}
            <Grid container spacing={2}>
                <Grid item xs={hidePreview ? 12 : 6}>
                    <RichEditor
                        value={value}
                        setValue={setValue}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </Grid>
                {!hidePreview && (
                    <Grid item xs={4} className={'preview'}>
                        <h2>{'Preview'}</h2>
                        <Preview
                            html={updatePlainText()}
                            formatHtml={formatHtml}
                            isOnlyDisplay={false}
                        />
                        <Tags tags={tags} title={tagTitle} formatTag={formatHtml} />
                    </Grid>
                )}
            </Grid>
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs={2}>
                    <ButtonWrapper color="secondary" className="closeButton" onClick={onClose}>
                        {'Cancel'}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={2}>
                    <ButtonWrapper className="confirmButton" onClick={() => _onConfirm()}>
                        {buttonText}
                    </ButtonWrapper>
                </Grid>
            </Grid>
        </DialogWrapper>
    );
};

export default RichEditorModal;
