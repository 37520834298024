import React from 'react';
import { BNPP, TOKEN } from '../../../constants/banks';
import { UNDER_RESELLER } from '../../../constants/clientType';
import {
    AIS,
    PIS,
    CBPII,
    STANDING_ORDER,
    SINGLE_IMMEDIATE_PAYMENT,
    FUTURE_DATED,
    BULK_TRANSFER,
} from '../../../constants/memberPermissions';
import { SUPER_ADMIN } from '../../../constants/roles';
import type { AppStore } from 'AppStore';
import { Translation } from 'types';

const { publicRuntimeConfig } = require('next/config').default();
const { externalLinks } = publicRuntimeConfig;

export default class DrawerController {
    static getExternalLinks(
        AppStore: AppStore,
        permissions: string[],
        t: Translation,
    ): React.ReactElement {
        const scope = AppStore.scope;

        switch (scope) {
            case BNPP:
                return (
                    <>
                        <div className="linkContainer">
                            <a
                                href={DrawerController.getDocumentationLink(AppStore, permissions)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={'documentationLink'}>
                                {t('documentation')}
                            </a>
                        </div>
                        <div className="linkContainer">
                            <a
                                href={externalLinks.support.zendesk}
                                target="_blank"
                                rel="noopener noreferrer">
                                {t('support')}
                            </a>
                        </div>
                    </>
                );
            case TOKEN:
                return (
                    <>
                        {AppStore?.client?.clientType !== UNDER_RESELLER && (
                            <>
                                {/*
                                // Note(Jules): APE-1247 temporarily removing this route
                                <div className="linkContainer">
                                    <LinkWrapper href={'/bank-connections'}>
                                        {t('bankConnections')}
                                    </LinkWrapper>
                                </div> */}
                                <div className="linkContainer">
                                    <a
                                        href={externalLinks.connectedBanksList}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {t('connectedBanksList')}
                                    </a>
                                </div>
                            </>
                        )}
                        {AppStore?.user?.role !== SUPER_ADMIN && (
                            <div className="linkContainer">
                                <div>{t('demos')}</div>
                                {permissions.length > 0 && permissions.includes(AIS) && (
                                    <div className={'subLinks'}>
                                        <a
                                            href={externalLinks.demos.aisp}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {t('aisp')}
                                        </a>
                                    </div>
                                )}
                                {permissions.length > 0 &&
                                    (permissions.includes(STANDING_ORDER) ||
                                        permissions.includes(SINGLE_IMMEDIATE_PAYMENT) ||
                                        permissions.includes(FUTURE_DATED) ||
                                        permissions.includes(BULK_TRANSFER) ||
                                        permissions.includes(PIS)) && (
                                        <div className={'subLinks'}>
                                            <a
                                                href={externalLinks.demos.merchant}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {t('pisp')}
                                            </a>
                                        </div>
                                    )}
                            </div>
                        )}
                        <div className="linkContainer">
                            <a
                                href={DrawerController.getDocumentationLink(AppStore, permissions)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={'documentationLink'}>
                                {t('documentation')}
                            </a>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <div className="linkContainer">
                            <a
                                href={DrawerController.getDocumentationLink(AppStore, permissions)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={'documentationLink'}>
                                {t('documentation')}
                            </a>
                        </div>
                    </>
                );
        }
    }

    static getDocumentationLink(AppStore: AppStore, permissions: string[]): string {
        const scope = AppStore.scope;
        let toBeDisplayed;
        const memberPermissions = permissions.filter(
            (permission) => permission === AIS || permission === PIS || permission === CBPII,
        );

        switch (scope) {
            case TOKEN:
                if (memberPermissions.length === 1) {
                    toBeDisplayed = memberPermissions[0];
                } else {
                    toBeDisplayed = 'ALL';
                }
                return externalLinks.documentation[toBeDisplayed];
            default:
                return externalLinks.documentation[scope];
        }
    }
}
