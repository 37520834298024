import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { IDefaultContainer } from './types';

function DefaultContainer({ children, className, maxWidth }: IDefaultContainer) {
    return (
        <Container
            maxWidth={maxWidth ?? 'lg'}
            className={`DefaultContainer${(className && ' ' + className) || ''}`}>
            {children ?? ''}
        </Container>
    );
}

DefaultContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default DefaultContainer;
