import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AuthenticationKeys from 'components/authentication-keys/AuthenticationKeys';

function TabAuthenticationKeys() {
    return (
        <div className={'additionalTab TabAuthenticationKeys'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <AuthenticationKeys refreshLinks={Function.prototype} />
                </Grid>
            </Grid>
        </div>
    );
}

TabAuthenticationKeys.propTypes = {
    AppStore: PropTypes.object.isRequired,
    AuthenticationKeysStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'AuthenticationKeysStore')(observer(TabAuthenticationKeys));
