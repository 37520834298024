import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import TextTranslations from './TextTranslations';

function TabTextTranslations({ ConfigurationStore }) {
    return (
        <div className={'TabTextTranslations'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <TextTranslations FeatureStore={ConfigurationStore.FeatureStore} />
                </Grid>
            </Grid>
        </div>
    );
}

TabTextTranslations.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabTextTranslations));
