module.exports = {
    getLanguages: [
        {
            code: 'cs',
            name: 'Czech',
        },
        {
            code: 'nl',
            name: 'Dutch',
        },
        {
            code: 'en',
            name: 'English',
        },
        {
            code: 'fr',
            name: 'French',
        },
        {
            code: 'de',
            name: 'German',
        },
        {
            code: 'hu',
            name: 'Hungarian',
        },
        {
            code: 'it',
            name: 'Italian',
        },
        {
            code: 'pl',
            name: 'Polish',
        },
        {
            code: 'pt',
            name: 'Portuguese',
        },
        {
            code: 'si',
            name: 'Slovenian',
        },
        {
            code: 'es',
            name: 'Spanish',
        },
    ],
};
