import { useFocused, useSelected } from 'slate-react';
import React from 'react';
import PropTypes from 'prop-types';

function ImageElement({ attributes, children, element, isSelected, isFocused }) {
    let selected = useSelected();
    let focused = useFocused();
    if (isSelected) selected = isSelected;
    if (isFocused) focused = isFocused;
    return (
        <div {...attributes} style={{ display: 'inline-block', margin: '5px 5px 0 0' }}>
            <span contentEditable={false}>
                <img
                    src={element.url}
                    style={{
                        display: 'block',
                        boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`,
                    }}
                    className={'img-consent'}
                />
            </span>
            {children}
        </div>
    );
}

ImageElement.propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.object,
    element: PropTypes.object,
    isSelected: PropTypes.bool,
    isFocused: PropTypes.bool,
};

ImageElement.defaultProps = {
    isSelected: false,
    isFocused: false,
};

export default ImageElement;
