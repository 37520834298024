import { Checkbox, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import successIcon from '../../../images/password-reset.svg';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import RenderKey from '../customization/features/helpers/render-key/RenderKey';
import { RESELLER } from 'constants/clientType';
import { BNPP } from 'constants/resellers';
const { publicRuntimeConfig } = require('next/config').default();
function General({ AppStore, GeneralStore, hideSave, memberId }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-general');
    const {
        forceMfa,
        profileName,
        handleChange,
        loading,
        handleFetch,
        handleSubmit,
        rootStore,
        saveDisabled,
    } = GeneralStore;

    const currentMemberId = memberId || rootStore.memberId;
    useEffect(() => {
        handleFetch(currentMemberId);
    }, []);

    const shouldRenderFeature = (feature) => {
        if (feature === 'login' && !publicRuntimeConfig.mfaLogin) return false;
        if (
            feature === 'refund' &&
            (!publicRuntimeConfig.forceMfaRefund ||
                (AppStore.scope === BNPP && AppStore.client?.clientType !== RESELLER))
        ) {
            return false;
        }
        return true;
    };

    const renderFeatureCheckbox = (feature) => (
        <div className="mfa" key={feature}>
            <Checkbox
                checked={forceMfa[feature]}
                onChange={handleChange}
                name={feature}
                title={feature}
            />
            <div className="checkBoxInfo">
                <span className="label">{t(`forceMfa${feature}`)}</span>
                <span className="helpText">{t(`forceMfa${feature}Help`)}</span>
            </div>
        </div>
    );

    const forceMfaSection = () => {
        if (hideSave) return null;

        const featuresToRender = Object.keys(forceMfa).filter(shouldRenderFeature);
        const hasMfaFeatures = featuresToRender.length > 0;

        return (
            <Grid container>
                <div className="forceMfaSection">
                    {GeneralStore.rootStore.hasForceMfa && hasMfaFeatures && (
                        <>
                            <h2 className="title">{t('security')}</h2>
                            {featuresToRender.map(renderFeatureCheckbox)}
                        </>
                    )}
                    <div className="forceMfaSave">
                        <ButtonWrapper
                            loading={loading}
                            className="submitButton"
                            onClick={() => handleSubmit(currentMemberId)}
                            disabled={saveDisabled}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </div>
                </div>
            </Grid>
        );
    };

    return (
        <div className={'General'}>
            <div className={'bankConsent'}>
                <h2 className={'title'}>{memberId ? t('general') : t('bankConsent')}</h2>
                <Grid spacing={4} container>
                    <Grid className={'displayName'}>
                        <RenderKey
                            obj={{ profileName }}
                            name="profileName"
                            label={t('displayName')}
                            placeholder={t('displayNamePlaceholder')}
                            onChange={handleChange}
                            helpText={t('bankConsentDescription')}
                            isCheck={false}
                            required
                        />
                    </Grid>
                </Grid>
                {forceMfaSection()}
            </div>
            <GenericSuccessModal
                Store={GeneralStore.GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('modalButton')}
                icon={successIcon}
            />
            <GenericErrorModal Store={GeneralStore.GenericErrorStore} />
        </div>
    );
}

General.propTypes = {
    AppStore: PropTypes.object.isRequired,
    GeneralStore: PropTypes.object.isRequired,
    hideSave: PropTypes.bool,
    memberId: PropTypes.string,
};

General.defaultProps = {
    hideSave: false,
};

export default inject('AppStore')(observer(General));
