import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import AlignButton from './align/AlignButton';
import { BlockButton, ImageButton, IndentButton, LinkButton, MarkButton } from './index';

function ButtonSelector({ AppStore, isDisabled, type, format, children }) {
    if (isDisabled) return <></>;
    if (type === 'align') {
        return <AlignButton format={format}>{children}</AlignButton>;
    }
    if (type === 'block') {
        return <BlockButton format={format}>{children}</BlockButton>;
    }
    if (type === 'image') {
        return (
            <ImageButton AppStore={AppStore} format={format}>
                {children}
            </ImageButton>
        );
    }
    if (type === 'link') {
        return <LinkButton format={format}>{children}</LinkButton>;
    }
    if (type === 'linkV2') {
        return (
            <LinkButton format={format} isV2>
                {children}
            </LinkButton>
        );
    }
    if (type === 'indent') {
        return <IndentButton format={format}>{children}</IndentButton>;
    }
    return <MarkButton format={format}>{children}</MarkButton>;
}

ButtonSelector.propTypes = {
    isDisabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
    store: PropTypes.object,
    AppStore: PropTypes.object.isRequired,
};

ButtonSelector.defaultProps = {
    isDisabled: false,
};

export default inject('AppStore')(observer(ButtonSelector));
