import { Editor, Element as SlateElement, Range, Transforms } from 'slate';
import { isLinkActive } from '../validations';

export const insertLink = (editor, url) => {
    if (!url) return;
    if (editor.selection) {
        wrapLink(editor, url);
    }
};

export const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    });
};

// wrap text with the link provided.
export const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    // when alert tag closed.
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};
