import { Box, Tooltip } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useSlate } from 'slate-react';

import snackbar from 'hooks/useSnackbar';
import { isImageActive } from '../../helpers/validations';
import { acceptImage } from '../../helpers/images';
import AppController from 'base/App.controller';

const { useSnackbar } = snackbar;
function ImageButton({ AppStore, format, children }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-consent');
    const editor = useSlate();
    const { addSnack } = useSnackbar();
    return (
        <Box ml={1} mt={1} className={'imageButton'}>
            <Tooltip title={t('imageLimit')}>
                <ToggleButton
                    value={format}
                    active={String(isImageActive(editor))}
                    onMouseDown={(event) => {
                        event.preventDefault();
                        const input = document.createElement('input');
                        input.type = 'file';
                        /* istanbul ignore next */
                        input.onchange = (e) => acceptImage(editor, e.target.files[0], addSnack);
                        input.click();
                        input.remove();
                    }}
                    style={{ lineHeight: 1 }}>
                    {children}
                </ToggleButton>
            </Tooltip>
        </Box>
    );
}

ImageButton.propTypes = {
    format: PropTypes.string,
    children: PropTypes.object,
    AppStore: PropTypes.object.isRequired,
};

export default ImageButton;
