import { Paper, Divider, Grid, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import AppController from 'base/App.controller';
import CountryModal from 'components/common/modals/configuration/features/countries/CountryModal';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import LinkWrapper from 'components/common/wrappers/LinkWrapper';
import BankSearch from '../bankSearch/BankSearch';

// helpers
import RenderKey from '../helpers/render-key/RenderKey';
import SectionSave from '../helpers/section-save/SectionSave';
import _isEmpty from 'lodash/isEmpty';

// constants
import { CUSTOM, DISABLE_ALL, ENABLE_ALL } from 'constants/bankSelectionPresets';
import { isoAlpha3 } from 'constants/countries';

function BankSelection({ AppStore, ConfigurationStore }) {
    const classes = useStyles();
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-features');
    const { BanksModalStore, FeatureStore } = ConfigurationStore;
    const {
        displayFeatures,
        handleChange,
        loading,
        handleBankSelectionSave,
        openCountryModal,
        CountryModalStore,
        getPrevFeatures,
        permittedCountries,
        fetchPermittedCountries,
        updateLoading,
        displayFieldsForBankSelection,
        handleCheck,
        handleClear,
        rootStore,
        memberType,
        handleBankSelectionPresetChange,
        bankSelectionPreset,
    } = FeatureStore;
    const customConfigRef = useRef(null);

    useEffect(() => {
        getPrevFeatures();
        fetchPermittedCountries();
    }, []);

    const { countries, country } = displayFeatures;

    const formatCountries = () => {
        const existing = Object.keys(countries).map((c) => c);
        return Object.keys(isoAlpha3)
            .filter((c) => existing.includes(c))
            .map((c) => isoAlpha3[c])
            .join(' | ');
    };

    const _renderRow = (k) => {
        return (
            <Grid key={k} className={'renderRow'}>
                <RenderKey
                    obj={displayFeatures}
                    name={k}
                    label={t(k)}
                    onCheck={handleCheck}
                    isCheck
                />
            </Grid>
        );
    };

    const _handleSave = async () => {
        await handleBankSelectionSave();
        await handleClear();
        rootStore.GenericSuccessStore.openModal();
    };

    return (
        <div className={'BankSelection'}>
            <Grid container item xs={12}>
                <Grid className="bankSelectionPresetSection" item xs={12} lg={8}>
                    <h2>{t('bankConfigGeneralSettings')}</h2>
                    <p className={'subTitle'}>{t('enableAllBanksInfo')}</p>
                    <p className={'subTitle'}>{t('disableAllBanksInfo')}</p>
                    <p className={'subTitle'}>{t('customConfigInfo')}</p>
                    <RadioGroup
                        aria-label="outage status"
                        name="status"
                        value={bankSelectionPreset}
                        onChange={(e) =>
                            handleBankSelectionPresetChange(e.target.value, customConfigRef)
                        }
                        className={'radioGroup'}>
                        <FormControlLabel
                            value={ENABLE_ALL}
                            control={<Radio />}
                            label={t('enableAllRadio')}
                            className="bankSelectionEnableAllRadio"
                        />
                        <FormControlLabel
                            value={DISABLE_ALL}
                            control={<Radio />}
                            label={t('disableAllRadio')}
                            className="bankSelectionDisableAllRadio"
                        />
                        <FormControlLabel
                            value={CUSTOM}
                            control={<Radio />}
                            label={t('customConfigRadio')}
                            className="bankSelectionCustomRadio"
                        />
                    </RadioGroup>
                    {bankSelectionPreset !== CUSTOM && (
                        <SectionSave
                            loading={loading}
                            onClick={() => _handleSave()}
                            saveButtonText={t('common:save')}
                        />
                    )}
                </Grid>
                {bankSelectionPreset === CUSTOM && (
                    <Grid ref={customConfigRef} item xs={12} lg={8}>
                        <h2>{t('customBankConfiguration')}</h2>
                        <h3>{t('listActiveCountries')}</h3>
                        <div className={'subTitle'}>{t(`subTitle.${memberType}`)}</div>
                        {!updateLoading && (
                            <Grid container spacing={4}>
                                {permittedCountries.length === 0 ? (
                                    <div className={'noCountries'}>
                                        <span className={'start'}>{t('noCountries_start')}</span>
                                        <span>
                                            <LinkWrapper
                                                target="_blank"
                                                rel="noopener"
                                                href={'/certificate-management'}>
                                                {t('certificateManagement')}
                                            </LinkWrapper>
                                        </span>
                                        <span>{t('noCountries_end')}</span>
                                    </div>
                                ) : (
                                    <>
                                        {!_isEmpty(countries) && (
                                            <Grid className={'countries'}>{formatCountries()}</Grid>
                                        )}
                                        <Grid item xs={2}>
                                            <ButtonWrapper
                                                className={'updateCountriesButton'}
                                                loading={updateLoading}
                                                onClick={() => openCountryModal()}>
                                                {t('selectCountries')}
                                            </ButtonWrapper>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                        {displayFieldsForBankSelection().map((k) => _renderRow(k))}

                        {Object.keys(countries).length > 0 && country ? (
                            <>
                                <h3>{t('listActiveCountries')}</h3>
                                <div className={'subTitle'}>{t(`banksSubTitle.${memberType}`)}</div>
                                <BankSearch
                                    t={t}
                                    FeatureStore={FeatureStore}
                                    BanksModalStore={BanksModalStore}
                                />
                            </>
                        ) : (
                            <>
                                <h3>{t('listActiveBanks')}</h3>
                                <div className={'subTitle'}>{t(`banksSubTitle.${memberType}`)}</div>
                                <Paper className={classes.paper}>{t('noPermittedCountries')}</Paper>
                            </>
                        )}
                        <Grid className={'divider'} item xs={12}>
                            <Divider />
                        </Grid>
                        <SectionSave
                            loading={loading}
                            onClick={() => _handleSave()}
                            saveButtonText={t('common:save')}
                        />
                        {CountryModalStore.isOpen && (
                            <CountryModal
                                title={t('selectCountries')}
                                Store={CountryModalStore}
                                buttonText={t('confirm')}
                                onSave={(val) => {
                                    handleChange({
                                        target: {
                                            name: 'countries',
                                            value: val,
                                        },
                                    });
                                }}
                                countries={Object.keys(countries)
                                    .map((c) => c)
                                    .slice()}
                                permittedCountries={permittedCountries}
                                t={t}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

BankSelection.propTypes = {
    AppStore: PropTypes.object.isRequired,
    ConfigurationStore: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
        },
    };
});

export default inject('AppStore', 'ConfigurationStore')(observer(BankSelection));
