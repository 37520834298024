import { Transforms } from 'slate';
import { MAX_IMAGE_SIZE } from '../constants';

export const insertImage = (editor, url) => {
    if (!url) return false;
    const text = { text: '' };
    Transforms.insertNodes(editor, { type: 'image', url, children: [text] });
};

export const acceptImage = (editor, file, addSnack) => {
    const reader = new FileReader();
    const [mime] = file.type.split('/');

    if (file.size > MAX_IMAGE_SIZE) {
        addSnack('Please upload image(s) less then 100KB', { variant: 'info', persist: false });
        return;
    }
    if (mime === 'image') {
        reader.addEventListener('load', () => {
            const url = reader.result;
            insertImage(editor, url);
        });

        reader.readAsDataURL(file);
    }
};
