import React from 'react';
import PropTypes from 'prop-types';

function Leaf({ attributes, children, leaf }) {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
}

Leaf.propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.object,
    leaf: PropTypes.object,
};

export default Leaf;
