import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type ConfigurationStore from '../ConfigurationStore';
import PisPaymentNetwork from './PisPaymentNetwork';

interface ITabPisPaymentNetworks {
    ConfigurationStore?: ConfigurationStore;
}

const TabPisPaymentNetwork: React.FC<ITabPisPaymentNetworks> = ({ ConfigurationStore }) => {
    return (
        <div className={'TabPisPaymentNetworks'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <PisPaymentNetwork
                        PisPaymentNetworkStore={ConfigurationStore!.PisPaymentNetworkStore}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabPisPaymentNetwork));
