import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';

function SectionSave({ loading, onClick, saveButtonText, disabled, tooptipText }) {
    return (
        <Grid className={'buttonContainer'} container spacing={4}>
            <Grid item xs={3}>
                <ButtonWrapper
                    className="saveButton"
                    loading={loading}
                    onClick={onClick}
                    disabled={disabled}
                    tooltipText={tooptipText}
                    showTooltip>
                    {saveButtonText}
                </ButtonWrapper>
            </Grid>
        </Grid>
    );
}

SectionSave.propTypes = {
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    saveButtonText: PropTypes.string,
    disabled: PropTypes.bool,
    tooptipText: PropTypes.string,
};

export default SectionSave;
