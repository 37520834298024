import { Editor, Element as SlateElement, Transforms } from 'slate';
import { isIndentActive } from '../validations';

export const increaseIndent = (editor, indent, indentPadding) => {
    wrapIndent(editor, indent, indentPadding);
};

export const decreaseIndent = (editor, indent) => {
    const p = editor.selection.anchor.path[0];
    const preValue = editor.children[p].indentPadding;

    const indentSlate = {
        type: 'paragraph',
        indent: 'indent-increase',
        indentPadding: editor.children[p].indentPadding - 10,
        children: [],
    };

    unwrapIndent(editor, indent);
    if (preValue > 10) {
        Transforms.wrapNodes(editor, indentSlate);
    }
};

export const unwrapIndent = (editor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) => {
            return (
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'paragraph' &&
                n.indent === 'indent-increase'
            );
        },
    });
};

// wrap text with the link provided.
export const wrapIndent = (editor, indent, indentPadding) => {
    const indentSlate = {
        type: 'paragraph',
        indent,
        indentPadding,
        children: [],
    };
    if (isIndentActive(editor, indent)) {
        unwrapIndent(editor);
        Transforms.wrapNodes(editor, indentSlate);
        return;
    }

    Transforms.wrapNodes(editor, indentSlate);
};
