import { Grid, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';
import RenderKey from 'components/configuration/customization/features/helpers/render-key/RenderKey';

// theme
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';

// wrappers
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import CustomDivider from 'components/common/wrappers/CustomDivider';

function TppFeatureControl({ AppStore, ConfigurationStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'tpp-feature-control');
    const {
        tppFeatureControlForm,
        fetchTppFeatureControl,
        setTppFeatureControl,
        handleTppControlFormCheck,
        settingsLoading,
    } = ConfigurationStore;

    useEffect(() => {
        fetchTppFeatureControl(AppStore);
    }, []);

    return (
        <div className="TppFeatureControl">
            <DefaultContainer>
                <h1 className={'sectionTitle'}>{t('title')}</h1>
                <div className={'subTitle'}>{t('subTitle')}</div>
                {Object.keys(tppFeatureControlForm).map((f) => (
                    <Paper elevation={4} key={f} className={'paper'}>
                        <CustomThemeProvider alt>
                            <Grid container className={'fields'} spacing={2} key={f} item xs={12}>
                                <RenderKey
                                    obj={{ ...tppFeatureControlForm }}
                                    name={f}
                                    isCheck
                                    helpText={t(`helpText.${f}`)}
                                    onCheck={handleTppControlFormCheck}
                                    label={t(f)}
                                    alt
                                />
                            </Grid>
                        </CustomThemeProvider>
                    </Paper>
                ))}
                <CustomDivider />
                <div className={'buttonContainer'}>
                    <ButtonWrapper
                        className="saveButton"
                        onClick={() => setTppFeatureControl(AppStore)}
                        loading={settingsLoading}>
                        {t('common:save')}
                    </ButtonWrapper>
                </div>
            </DefaultContainer>
        </div>
    );
}

TppFeatureControl.propTypes = {
    AppStore: PropTypes.object.isRequired,
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TppFeatureControl));
