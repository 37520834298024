import React from 'react';
import PropTypes from 'prop-types';
import Image from './image/ImageElement';

// customized Element to wrap specific text as per the format provided
const Element = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'link':
            return (
                <a {...attributes} href={element.url}>
                    {children}
                </a>
            );
        case 'image':
            return <Image {...props}>{children}</Image>;
        default:
            return <div {...attributes}>{children}</div>;
    }
};

Element.propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.any,
    element: PropTypes.object,
};

export default Element;
