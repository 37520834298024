import { Divider, Grid, MenuItem, Paper, Tooltip } from '@material-ui/core';
import SettingsBackupRestoreSharpIcon from '@material-ui/icons/SettingsBackupRestoreSharp';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';

import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';

// constants
import { getTranslations } from 'constants/defaultWebAppTranslations';
import { getLanguages } from 'constants/languages';

function TextTranslations({ AppStore, FeatureStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration');

    const {
        filteredFeatures,
        pageName,
        language,
        handleTextTranslationChange,
        defaultWebAppTranslations,
        handleDisplayWebAppTextTranslationChange,
        populateWebAppTranslations,
        setToDefault,
        handleDisplayWebAppTranslationsSave,
        rootStore,
    } = FeatureStore;

    useEffect(() => {
        populateWebAppTranslations(
            filteredFeatures().regionalConfigurations['default'].translations,
        );
    }, []);

    const _handleSave = async () => {
        await handleDisplayWebAppTranslationsSave();
        rootStore.GenericSuccessStore.openModal();
    };

    return (
        <div className={'TextTranslations'}>
            <DefaultContainer>
                <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    justify="flex-start"
                    className={'webAppText'}>
                    <Grid item xs={3} className={'webAppTextLanguage'}>
                        <SelectWrapper
                            name="language"
                            label={t('language')}
                            onChange={handleTextTranslationChange}
                            value={language}
                            required>
                            {getLanguages.map((c) => (
                                <MenuItem key={c.code} value={c.code}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </SelectWrapper>
                    </Grid>
                    <Grid item xs={3} className={'webAppTextPage'}>
                        <SelectWrapper
                            name="pageName"
                            label={t('page')}
                            value={pageName}
                            onChange={handleTextTranslationChange}
                            required>
                            {Object.keys(getTranslations).map((c) => (
                                <MenuItem key={c} value={c}>
                                    {t(c)}
                                </MenuItem>
                            ))}
                        </SelectWrapper>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    justify="flex-end"
                    className={'webAppText'}>
                    <Grid item className={'restoreFields'}>
                        <Tooltip title={t('restore')}>
                            <StyledIcon className={'restore'} onClick={setToDefault}>
                                <SettingsBackupRestoreSharpIcon />
                            </StyledIcon>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    justify="flex-start"
                    className={'webAppTextTranslationsHeaders'}>
                    <Grid item xs={6} className={'defaultText'}>
                        {t('defaultText')}
                    </Grid>
                    <Grid item xs={6} className={'displayText'}>
                        {t('displayText')}
                    </Grid>
                </Grid>
                <CustomThemeProvider alt>
                    <Paper className={'translationRows'}>
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            justify="flex-start"
                            className={'webAppTextTranslationsRows'}>
                            {Object.keys(getTranslations[pageName][language]).map((key) => (
                                <Grid
                                    container
                                    spacing={2}
                                    item
                                    xs={12}
                                    justify="flex-start"
                                    key={key}>
                                    <Grid item xs={6} className={'defaultText'}>
                                        <TextFieldWrapper
                                            name={key}
                                            type="text"
                                            value={getTranslations[pageName][language][key]}
                                            disabled
                                            hideOptional
                                        />
                                    </Grid>
                                    <Grid item xs={6} className={'displayText'}>
                                        <TextFieldWrapper
                                            name={key}
                                            type="text"
                                            value={defaultWebAppTranslations[key]}
                                            onChange={handleDisplayWebAppTextTranslationChange}
                                            hideOptional
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </CustomThemeProvider>
                <Grid className={'divider'} item xs={12}>
                    <Divider />
                </Grid>
                <Grid className={'buttonContainer'} container spacing={4}>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                        <ButtonWrapper className="submitButton" onClick={_handleSave}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

TextTranslations.propTypes = {
    AppStore: PropTypes.object.isRequired,
    FeatureStore: PropTypes.object.isRequired,
};

export default inject('AppStore')(observer(TextTranslations));
