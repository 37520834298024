// NOTE: Update src/variables/_webappConfigurations.scss too while updating the default css.
module.exports = {
    getDefaultCss: {
        'color-dark': '#1A262C',
        'color-text': '#1C0F36',
        'color-default-text': '#FF1A262CE0',
        'color-spinner': '#1C0F36',
        'color-link': '#1C0F36',
        'color-primary': '#1C0F36',
        'color-light': '#FFFFFF',
        'color-primary-text': '#FFFFFF',
        'color-transparent': 'transparent',
        'color-secondary': '#261747',
        'color-dividers': '#E9EAF0',
        'color-sub-title-text': '#2E3038',
        'color-body-text': '#2E3038',
        'color-primary-focus': '#190E31',
        'color-primary-press': '#a49faf',
        'color-primary-hover': '#605772',
        'color-secondary-focus': 'transparent',
        'color-secondary-press': 'transparent',
        'color-secondary-hover': 'transparent',
        'color-delete-error': '#EB5757',
        'color-error-hover': '#E12619',
        'color-error-press': '#F39791',
        'color-error-focus': '#B31E14',
        'color-focus-outline': '#1C0F36',
        'color-light-accent': '#61D1FC',
        'color-light-secondary-accent': '#61D1FC',
        'color-light-status-success': '#00C851',
        'color-light-status-submitted': '#edb312',
        'color-light-status-failure': '#F58C78',
        'color-dark-accent': '#61D1FC',
        'color-dark-secondary-accent': '#61D1FC',
        'color-dark-status-success': '#00C851',
        'color-dark-status-submitted': '#edb312',
        'color-dark-status-failure': '#F58C78',
    },
    getDefaultFontSize: {
        'font-size-xlarge': '24',
        'font-size-large': '18',
        'font-size-medium': '17',
        'font-size-small': '15',
        'font-size-xsmall': '17',
    },
};
