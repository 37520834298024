import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
} from '@material-ui/core';

import AppController from 'base/App.controller';

import Spinner from 'components/common/spinner/Spinner';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import successIcon from 'images/password-reset.svg';

import type { Itranslation } from 'types';
import type { IPisPaymentNetwork as IProps } from './types';

const PisPaymentNetwork: React.FC<IProps> = ({ PisPaymentNetworkStore, AppStore }) => {
    const { t }: Itranslation = AppController.getTranslation(
        AppStore.scope,
        'configuration-pis-payment-networks',
    );

    const {
        form,
        loading,
        GenericErrorStore,
        GenericSuccessStore,
        paymentNetworkOptions,
        handleFetch,
        handleCheck,
        handleSave,
    } = PisPaymentNetworkStore!;

    useEffect(() => {
        const cancellablePromise = handleFetch();
        return () => {
            cancellablePromise.cancel();
        };
    }, []);

    return (
        <div className="PaymentNetwork">
            {loading ? (
                <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl className={'options'}>
                                <FormLabel className={'paymentNetworkLabel'}>
                                    {t('enablePaymentNetwork')}
                                </FormLabel>
                                <FormGroup className={'checkboxGroup'}>
                                    <Grid container>
                                        {paymentNetworkOptions.map((key) => (
                                            <Grid item xs={12} key={key}>
                                                <FormControlLabel
                                                    className={'paymentNetworkCheckbox'}
                                                    control={
                                                        <Checkbox
                                                            onChange={handleCheck}
                                                            name={key}
                                                            checked={!!form[key]}
                                                        />
                                                    }
                                                    label={t(key)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={'divider'} item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid className={'buttonContainer'} container spacing={4}>
                        <Grid item xs={3}>
                            <ButtonWrapper className="submitButton" onClick={handleSave}>
                                {t('common:save')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                    <GenericSuccessModal
                        Store={GenericSuccessStore}
                        title={t('modalTitle')}
                        subtitle={t('modalDescription')}
                        buttonText={t('modalButton')}
                        icon={successIcon}
                    />
                </>
            )}
            <GenericErrorModal Store={GenericErrorStore} />
        </div>
    );
};

export default inject('AppStore')(observer(PisPaymentNetwork));
