import { Checkbox, Divider, Grid, MenuItem, TableCell } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';

//Icon
import successIcon from 'images/success.svg';

import { ICountry, IGeolocationProps, IRegionList } from './types';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TableWrapper, { cellConstructor } from 'components/common/wrappers/TableWrapper';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import GenericWarningModal from 'components/common/modals/warning/GenericWarning';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import { ADD } from 'constants/restrictions';
const GeoLocationComponent: React.FC<IGeolocationProps> = ({ AppStore, GeoLocationStore, t }) => {
    const {
        loading,
        handleChange,
        updateSelectRow,
        setDefaultCountry,
        setCountry,
        handleFetch,
        tableWrapper,
        countries,
        regions,
        country,
        addRestriction,
        region,
        isDefault,
        handleSave,
        type,
        isDraft,
        selectCountry,
        selectRegion,
        setDefaultRegion,
        checkIds,
        handleCheck,
        fetchCountriesList,
        setAllKeywordValue,
    } = GeoLocationStore;

    useEffect(() => {
        if (t(selectCountry) !== 'defaultCountry') {
            setDefaultRegion({ name: t(selectRegion), id: '', country: '' });
            fetchCountriesList();
            handleFetch(AppStore);
            setAllKeywordValue(t('All'));
            setDefaultCountry({ name: t(selectCountry), code: '' });
            setCountry({ name: t(selectCountry), code: '' });
        }
        return () => {};
    }, [t]);

    const cellTemplates = [
        (row: any) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'country',
                className: '',
            }),
        (row: any) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'countryCode',
                className: '',
            }),
        (row: any) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'text',
                className: '',
            }),
        (row: any) => rowCheckBox(row),
    ];
    const _handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleCheck(e);
    };

    const rowCheckBox = (row: any) => {
        const key = Math.random();
        const checkId = row.country || row.text;
        return (
            <TableCell key={key} align={'center'}>
                <CustomThemeProvider alt>
                    <Checkbox
                        className="checkDelete"
                        checked={checkIds[checkId]}
                        name={checkId}
                        onChange={_handleCheck}
                    />
                </CustomThemeProvider>
            </TableCell>
        );
    };

    const header = [t('country'), t('countryCode'), t('text'), t('action')];
    return (
        <div className="geoLocation">
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <h2>{t('title')}</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectWrapper
                                value={country.name}
                                onChange={handleChange}
                                name="countries"
                                label={t('country')}
                                required>
                                {[{ name: t(selectCountry), code: '' }, ...countries].map(
                                    (c: ICountry, index: number) => (
                                        <MenuItem key={index} value={c.name}>
                                            {c.name.toUpperCase()}
                                        </MenuItem>
                                    ),
                                )}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectWrapper
                                onChange={handleChange}
                                value={region.name}
                                name="region"
                                label={t('region')}
                                required>
                                {[{ name: t(selectRegion), id: '', country: '' }, ...regions].map(
                                    (c: IRegionList, index: number) => (
                                        <MenuItem key={index} value={c.name}>
                                            {c.name.toUpperCase()}
                                        </MenuItem>
                                    ),
                                )}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonWrapper
                                disabled={isDefault()}
                                onClick={addRestriction}
                                className={'searchLocation'}>
                                {t('add')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="blockListTableTitle" container item xs={12}>
                    <h2>{t('blockListTableTitle')}</h2>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={2}>
                            <ButtonWrapper onClick={handleFetch} className={'refresh'}>
                                {t('refresh')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWrapper
                            header={header}
                            disableRowSelect={false}
                            enablePagination
                            cellTemplates={cellTemplates}
                            noDataMessage={t('noRestrictions')}
                            TableWrapperStore={tableWrapper}
                            loading={loading}
                            callback={updateSelectRow}
                            canExpand
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={'divider'} item xs={12}>
                <Divider />
            </Grid>
            <Grid className={'buttonContainer'} container spacing={4} justify="flex-end">
                <Grid item xs={3}>
                    <ButtonWrapper
                        loading={loading}
                        onClick={handleSave}
                        className="submitButton"
                        disabled={!isDraft}>
                        {t('common:save')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <GenericSuccessModal
                Store={GeoLocationStore.genericSuccessModal}
                title={t('successModalTitle')}
                subtitle={t(`${type === ADD ? 'successMessage' : 'deleteMessage'}`)}
                buttonText={t('common:okay')}
                icon={successIcon}
            />
            <GenericErrorModal Store={GeoLocationStore.genericErrorModal} />
            <GenericWarningModal
                Store={GeoLocationStore.genericWarningModal}
                onClick={() => GeoLocationStore.handleDelete(AppStore)}
                title={t('warningModalTitle') || ''}
                subtitle={t('warningText')}
                buttonText={t('action') || ''}
                loading={GeoLocationStore.genericWarningModal.loading}
            />
        </div>
    );
};

export default inject('AppStore')(observer(GeoLocationComponent));
