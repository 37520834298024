module.exports = {
    TRANSFER_STATUS_CHANGED: 'TRANSFER_STATUS_CHANGED',
    BULK_TRANSFER_STATUS_CHANGED: 'BULK_TRANSFER_STATUS_CHANGED',
    STANDING_ORDER_STATUS_CHANGED: 'STANDING_ORDER_STATUS_CHANGED',
    REFUND_STATUS_CHANGED: 'REFUND_STATUS_CHANGED',
    VRP_STATUS_CHANGED: 'VRP_STATUS_CHANGED',
    VRP_CONSENT_STATUS_CHANGED: 'VRP_CONSENT_STATUS_CHANGED',
    BANK_AIS_OUTAGE_STATUS_CHANGED: 'BANK_AIS_OUTAGE_STATUS_CHANGED',
    BANK_SIP_OUTAGE_STATUS_CHANGED: 'BANK_SIP_OUTAGE_STATUS_CHANGED',
    PAYMENT_STATUS_CHANGED: 'PAYMENT_STATUS_CHANGED',
};
