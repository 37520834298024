import axios from 'axios';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';

import ModalStore from 'components/common/modals/ModalStore';
import PaymentInitiationRestrictionsModalStore from 'components/common/modals/payment-initiation-restrictions/PaymentInitiationRestrictionsModalStore';
import TableWrapperStore from 'components/common/wrappers/TableWrapperStore';

// constants
import { SINGLE_IMMEDIATE_PAYMENT } from 'constants/restrictions';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class PaymentInitiationRestrictionsStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
        this.GenericErrorStore = new ModalStore();
        this.TableWrapperStore = new TableWrapperStore();
        this.PaymentInitiationRestrictionsModalStore = new PaymentInitiationRestrictionsModalStore(
            this,
        );
    }
    PaymentInitiationRestrictionsWarningModal = new ModalStore();

    @observable
    targetMemberId = this.rootStore.memberId;
    @observable
    restrictionId = '';
    @observable
    restrictions = '';
    @observable
    selectedRestriction = '';
    @observable
    queryRule = '';
    @observable
    loading = true;
    @observable
    ruleName = '';
    @observable
    isRule = false;
    @observable
    rowId = '';

    @action
    updateSelectRow = (row) => {
        this.selectedRestriction = row;
        if (this.selectedRestriction?.text) {
            this.setValues(
                row.restrictionId,
                true,
                this.selectedRestriction.text,
                this.selectedRestriction.name,
                row.id,
            );
        } else {
            this.setValues(
                row?.id,
                false,
                this.selectedRestriction?.childrenList?.[0]?.text,
                this.selectedRestriction?.childrenList?.[0]?.name,
            );
        }
    };

    @action
    setValues = (restrictionId, isRule, ruleText, ruleName, ruleId = '') => {
        this.restrictionId = restrictionId;
        this.isRule = isRule;
        this.queryRule = ruleText;
        this.ruleName = ruleName;
        this.ruleId = ruleId;
    };

    @action
    deletePaymentRestrictions = () => {
        this.PaymentInitiationRestrictionsWarningModal.openModal();
    };

    @action
    handleDelete = flow(
        function* (type) {
            this.loading = true;
            try {
                const memberId = this.rootStore.memberId;
                type === 'deleteRule'
                    ? yield axios.delete(
                          `/api/member/restriction-rule?memberId=${memberId}&ruleId=${this.ruleId}&restrictionId=${this.restrictionId}`,
                    )
                    : yield axios.delete(
                          `/api/member/payment-initiation-restrictions?memberId=${memberId}&restrictionId=${this.restrictionId}`,
                    );

                this.PaymentInitiationRestrictionsWarningModal.closeModal();
                yield this.handleFetch();
                this.handleResetValues();
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleFetch = flow(
        function* () {
            this.loading = true;
            this.selectedRestriction = '';
            try {
                const byTargetMemberId = this.rootStore.memberId;
                const byRestrictionId = this.restrictionId;

                // API to fetch all restrictions for a member
                const res = yield axios.get(
                    `/api/member/payment-initiation-restrictions?byTargetMemberId=${byTargetMemberId}&byRestrictionId=${byRestrictionId}`,
                );
                this.restrictions = res.data.restrictions;
                this.restrictions = this.restrictions.filter(
                    ({ productType }) => productType == SINGLE_IMMEDIATE_PAYMENT,
                );
                const restrictionList = this.formatRestrictionList(this.restrictions);
                this.TableWrapperStore.setData(restrictionList);
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    addRestriction = () => {
        this.PaymentInitiationRestrictionsModalStore.openModal('addRestriction');
    };

    /* istanbul ignore next */
    @action
    modifyPaymentRestrictionRule = (type) => {
        let queryRule = this.queryRule.replace('SipRestrictedFields(', '');
        const lastIndex = queryRule.lastIndexOf(')');
        if (lastIndex !== -1) {
            queryRule = queryRule.substring(0, lastIndex);
        }
        this.PaymentInitiationRestrictionsModalStore.setPreviousRule(queryRule, this.ruleName);
        this.PaymentInitiationRestrictionsModalStore.setSelectedRestrictionRow(
            this.selectedRestriction,
        );
        this.PaymentInitiationRestrictionsModalStore.openModal(type);
        this.selectedRestriction = '';
    };

    @action
    addPaymentRestrictionRule = () => {
        this.PaymentInitiationRestrictionsModalStore.openModal('addRule');
    };

    @action
    deletePaymentRestrictionRule = () => {
        this.PaymentInitiationRestrictionsWarningModal.openModal('deleteRule');
    };

    @action
    handleResetValues = () => {
        this.selectedRestriction = '';
        this.ruleId = '';
        this.restrictionId = '';
        this.isRule = false;
    };

    formatRestrictionList = (restrictionList) => {
        restrictionList.map((restriction, index) => {
            restrictionList[index] = {
                ...restriction,
                childrenList: this.formatRuleList(restriction.rules, restriction.id),
            };
            delete restrictionList[index].rules;
        });
        return restrictionList;
    };

    formatRuleList = (ruleList, id) => {
        ruleList.map((rule, index) => {
            ruleList[index] = {
                ...rule,
                childrenList: [],
                restrictionId: id,
            };
        });
        return ruleList;
    };
}

export default PaymentInitiationRestrictionsStore;
