import { Box, Divider } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Toolbar = React.forwardRef(function Toolbar({ className, ...props }, ref) {
    return (
        <>
            <Box
                display="flex"
                className={className}
                direction="row"
                justify="flex-start"
                alignItems="center"
                flexWrap="wrap"
                ref={ref}>
                {props.children}
            </Box>
            <Box pt={2}>
                <Divider style={{ marginBottom: '20px' }} />
            </Box>
        </>
    );
});

Toolbar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.array,
};

export default Toolbar;
