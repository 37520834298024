import { Checkbox, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { countries as allCountries, isoAlpha3 } from 'constants/countries';
import compareArrays from 'helpers/compare-arrays/compare-arrays';

import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';
import { ICountry, ICountryModalProps } from 'components/common/modals/types';

const CountryModal: React.FC<ICountryModalProps> = ({
    Store,
    buttonText,
    onSave,
    title,
    countries,
    permittedCountries,
    t,
}) => {
    const getSelectedCountries = (selectedCountries: string[]) => {
        const cObj: { [k: string]: boolean } = {
            all: false,
        };
        permittedCountries.map((c) => {
            cObj[c] = selectedCountries.includes(c);
        });

        if (compareArrays(selectedCountries, permittedCountries)) {
            cObj['all'] = true;
        }
        return cObj;
    };
    const [selected, setSelected] = useState(getSelectedCountries(countries));

    const _onClose = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        Store.closeModal();
    };

    const _onSave = (e: React.MouseEvent) => {
        e.preventDefault();
        onSave(Object.keys(selected).filter((s) => selected[s] === true));
        Store.closeModal();
    };

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        if (name === 'all') {
            const temp = { ...selected };
            Object.keys(temp).map((t) => {
                selected[t] = checked;
            });
        }
        selected[name] = checked;
        setSelected({ ...selected });
    };

    return (
        <DialogWrapper
            open={Store.isOpen}
            onClose={_onClose}
            hideDivider
            className={'CountryModal'}>
            <Grid container spacing={4} className={'heading'}>
                <Grid className={'title'}>
                    <h2>{title}</h2>
                </Grid>
            </Grid>
            <Grid container spacing={4} className={'all'}>
                <React.Fragment key={'all'}>
                    <Grid>
                        <CustomThemeProvider alt>
                            <Checkbox
                                checked={selected['all']}
                                onChange={handleCheck}
                                name={'all'}
                            />
                        </CustomThemeProvider>
                    </Grid>
                    <Grid item xs={9} className={'text'}>
                        {t('all')}
                    </Grid>
                </React.Fragment>
            </Grid>
            <Grid container spacing={4}>
                {Object.keys(selected).map(
                    (c) =>
                        c !== 'all' && (
                            <React.Fragment key={c}>
                                <Grid>
                                    <CustomThemeProvider alt>
                                        <Checkbox
                                            checked={selected[c]}
                                            onChange={handleCheck}
                                            name={c}
                                        />
                                    </CustomThemeProvider>
                                </Grid>
                                <Grid item xs={9} className={'text'}>
                                    {allCountries.filter((f: ICountry) => f.code === c)[0].name +
                                        ' (' +
                                        isoAlpha3[c] +
                                        ')'}
                                </Grid>
                            </React.Fragment>
                        ),
                )}
            </Grid>
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs={4}>
                    <ButtonWrapper color="secondary" className="closeButton" onClick={_onClose}>
                        {'Cancel'}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={4}>
                    <ButtonWrapper className="confirmButton" onClick={_onSave}>
                        {buttonText}
                    </ButtonWrapper>
                </Grid>
            </Grid>
        </DialogWrapper>
    );
};

export default observer(CountryModal);
