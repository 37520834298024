import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import IpAddressModal from 'components/common/modals/ip-address/IpAddressModal';
import GenericWarningModal from 'components/common/modals/warning/GenericWarning';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import TableWrapper, { cellConstructor } from 'components/common/wrappers/TableWrapper';

function ManageIpAddresses({ AppStore, ManageIpAddressesStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-manageIpAddress');

    const {
        handleFetch,
        loading,
        updateIpRestrictionRule,
        openIpAddressModal,
        openDeleteIpAddressModal,
        restrictions,
        GenericErrorStore,
        TableWrapperStore,
    } = ManageIpAddressesStore;

    useEffect(() => {
        handleFetch(AppStore);
    }, []);

    const cellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'id', field: 'id' }),
        (row) =>
            cellConstructor.text({
                row: row,
                keyId: 'id',
                field: 'productType',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'name',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'text',
            }),
    ];

    const header = [t('id'), t('productType'), t('name'), t('ip')];

    const _renderButtons = () => {
        return (
            <>
                {!restrictions.length ? (
                    <Grid item xs={2}>
                        <ButtonWrapper
                            className="addRuleButton"
                            onClick={() => openIpAddressModal('addIpRestriction')}>
                            {t('add')}
                        </ButtonWrapper>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={2}>
                            <ButtonWrapper
                                className="modifyRuleButton"
                                onClick={() => updateIpRestrictionRule('modifyIpRule')}>
                                {t('modifyIpRule')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonWrapper
                                className="deleteRuleButton"
                                onClick={() => openDeleteIpAddressModal('deleteIpRule')}>
                                {t('deleteIpRule')}
                            </ButtonWrapper>
                        </Grid>
                    </>
                )}
            </>
        );
    };
    return (
        <div className={'ManageIpAddress'}>
            <h2 className={'title'}>{t('manageIpAddress')}</h2>
            <p className={'description'}>{t('manageIpAddressDescription')}</p>
            <DefaultContainer>
                <Grid container justify="flex-end" spacing={3}>
                    {!loading ? _renderButtons() : ''}
                </Grid>
                <TableWrapper
                    header={header}
                    cellTemplates={cellTemplates}
                    noDataMessage={t('noIpRestrictions')}
                    TableWrapperStore={TableWrapperStore}
                    loading={loading}
                    disableRowSelect
                />
            </DefaultContainer>
            <CustomThemeProvider alt>
                <GenericWarningModal
                    Store={ManageIpAddressesStore.IpAddressRestrictionWarningModal}
                    onClick={() =>
                        ManageIpAddressesStore.handleDelete(
                            ManageIpAddressesStore.IpAddressRestrictionWarningModal.type,
                        )
                    }
                    title={t('warningModalTitle')}
                    buttonText={t('delete')}
                    loading={ManageIpAddressesStore.loading}
                />
                <IpAddressModal IpAddressModalStore={ManageIpAddressesStore.IpAddressModalStore} />
                <GenericErrorModal Store={GenericErrorStore} />
            </CustomThemeProvider>
        </div>
    );
}

ManageIpAddresses.propTypes = {
    AppStore: PropTypes.object.isRequired,
    ManageIpAddressesStore: PropTypes.object.isRequired,
};

export default inject('AppStore')(observer(ManageIpAddresses));
