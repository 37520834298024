import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import CertificateManagementController from 'components/certificate-management/CertificateManagement.controller';

// constants
import { UNDER_RESELLER } from 'constants/clientType';

function TabBankConnections({ AppStore, CertificateManagementStore }) {
    const { page } = CertificateManagementStore;
    const { scope } = AppStore;
    return (
        <div className={'additionalTab TabBankConnections'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    {CertificateManagementController.getComponent(page, scope, UNDER_RESELLER)}
                </Grid>
            </Grid>
        </div>
    );
}

TabBankConnections.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(TabBankConnections));
