import { Divider, Grid, IconButton, MenuItem, Tooltip, withStyles } from '@material-ui/core';
import SettingsBackupRestoreSharpIcon from '@material-ui/icons/SettingsBackupRestoreSharp';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import successIcon from 'images/password-reset.svg';

import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import {
    Preview,
    Tags,
    RichEditorWrapper as RichEditor,
} from 'components/common/wrappers/rich-editor';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';

// helpers
import conditional from 'helpers/conditional/conditional';
import serialize from 'helpers/serialize-slate/serialize.js';

// constants
import { AISTags, PISTags } from 'constants/editorTags';
import { AIS, VRP } from 'constants/permissions';
import { getLanguages } from 'constants/languages';
import { VRPTags } from 'constants/editorTags';

const StyledIcon = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
})(IconButton);

function Consent({ AppStore, CustomizationStore, type }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-consent');
    const {
        displayName,
        expirationDate,
        consent,
        isConsentFormFilled,
        handleChange,
        loading,
        handleFetch,
        previewText,
        handleSave,
        handleReversePaymentSupportChange,
        hideConsent,
        reversePaymentSupportText,
        formatTags,
        language,
        setLanguage,
        restoreDefaultConsentText,
        setConsentText,
        onConsentToggle,
        consentToggle,
        region,
        setRegion,
        regionalConfigurations,
        isReversePayment,
        getConsentText,
        flowType,
    } = CustomizationStore;

    useEffect(() => {
        handleChange(
            {
                target: {
                    name: 'text',
                    value: '',
                },
            },
            type,
        );
        handleReversePaymentSupportChange('', type);
        handleFetch(type);
    }, []);

    const _handleChange = (value) => {
        let serializedInput = '';
        value.map((v) => (serializedInput += serialize(v)));
        handleChange(
            {
                target: {
                    name: 'text',
                    value: serializedInput,
                },
            },
            type,
        );
    };

    const _handleReversePaymentSupportChange = (value) => {
        let serializedInput = '';
        value.map((v) => (serializedInput += serialize(v)));
        handleReversePaymentSupportChange(serializedInput, type);
    };

    const _handleLanguageChange = (e) => {
        const lang = e.target.value;
        const consentType = flowType(type);
        const value =
            regionalConfigurations[region].translations[`${lang}.${consentType}.consent.text`];
        const text = consentType === 'pisp' ? getConsentText(value) : value;
        setLanguage(e.target.value);
        setConsentText(text);
        onConsentToggle();
    };

    const _handleRegionChange = (e) => {
        const region = e.target.value;
        const consentType = flowType(type);
        setRegion(region);
        setLanguage(language);
        const value =
            regionalConfigurations[region].translations[`${language}.${consentType}.consent.text`];
        const text = consentType === 'pisp' ? getConsentText(value) : value;
        setConsentText(text);
        onConsentToggle();
    };

    const _restoreToDefault = () => {
        restoreDefaultConsentText(language, type);
        onConsentToggle();
    };

    const _successModalSubtitle = () => `${t(`modalDescription.${type}`)}`;

    const _displayTags = () => {
        if (type === VRP) {
            return VRPTags;
        }
        return conditional(type === AIS, AISTags, PISTags);
    };

    const consentPreviewText = `<div class='preview-consent-text'>${previewText}</div>`;

    return (
        <div className={'Consent'}>
            <h2>{t('tags')}</h2>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name="name"
                        type="text"
                        label={t('businessName')}
                        value={consent.name}
                        onChange={(e) => handleChange(e, type)}
                        hideOptional
                        readOnly
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWrapper
                        name="pispName"
                        type="text"
                        label={t('displayName')}
                        value={displayName}
                        onChange={Function.prototype}
                        hideOptional
                        disabled
                    />
                </Grid>
                {(type === AIS || type === VRP) && (
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="expirationDate"
                            type="text"
                            label={t('expirationDate')}
                            value={expirationDate}
                            onChange={Function.prototype}
                            hideOptional
                            disabled
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} className={'language'}>
                    <SelectWrapper
                        name="region"
                        label={t('region')}
                        value={region}
                        onChange={_handleRegionChange}
                        required>
                        {['eu', 'gb'].map((c) => (
                            <MenuItem key={c} value={c}>
                                {c.toUpperCase()}
                            </MenuItem>
                        ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={4} className={'language'}>
                    <SelectWrapper
                        name="language"
                        label={t('language')}
                        value={language}
                        onChange={_handleLanguageChange}
                        required>
                        {getLanguages.map((c) => (
                            <MenuItem key={c.code} value={c.code}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={4} className={'restoreDefaultConsent'}>
                    <Tooltip title={t('restoreDefaultConsent')}>
                        <StyledIcon
                            className={'restoreDefaultConsentIcon'}
                            onClick={_restoreToDefault}
                            aria-label={'Restore Defaults'}>
                            <SettingsBackupRestoreSharpIcon />
                        </StyledIcon>
                    </Tooltip>
                </Grid>
            </Grid>
            {!hideConsent && (
                <Grid container spacing={2} justify="space-between">
                    <Grid item xs={6}>
                        <h2>{t('consent')}</h2>
                        <RichEditor
                            plainText={consent.text}
                            onChange={_handleChange}
                            placeholder={t(`consentPlaceholder.${type}`)}
                            deps={consentToggle}
                        />
                        {type !== AIS && type !== VRP && !!consent.text && isReversePayment && (
                            <>
                                <h2>{t('reversePaymentSupport')}</h2>
                                <RichEditor
                                    plainText={reversePaymentSupportText}
                                    onChange={_handleReversePaymentSupportChange}
                                    autoFocus={false}
                                    disabled={[
                                        'block-quote',
                                        'code',
                                        'left',
                                        'right',
                                        'center',
                                        'indent-increase',
                                        'indent-decrease',
                                        'linkV2',
                                    ]}
                                    deps={consentToggle}
                                />
                            </>
                        )}
                    </Grid>
                    <Grid className={'webAppPreview'} item xs={5}>
                        <h2>{t('preview')}</h2>
                        <Preview html={consentPreviewText} />
                        <div className={'consent-tags'}>
                            <Tags tags={_displayTags()} formatTag={formatTags} />
                        </div>
                    </Grid>
                </Grid>
            )}
            <Grid className={'divider'} item xs={12}>
                <Divider />
            </Grid>
            <Grid className={'buttonContainer'} container spacing={4} justify="flex-end">
                <Grid item xs={3}>
                    <ButtonWrapper
                        loading={loading}
                        onClick={() => handleSave(type, 'consent')}
                        className="submitButton"
                        disabled={!isConsentFormFilled}>
                        {t('common:save')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <GenericSuccessModal
                Store={CustomizationStore.GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={_successModalSubtitle()}
                buttonText={t('common:okay')}
                icon={successIcon}
            />
        </div>
    );
}

Consent.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CustomizationStore: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

export default inject('AppStore')(observer(Consent));
