import { Grid, List, ListItem, ListItemText, Paper, TextField } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import { IPaymentInitiationRestrictionsModalProps } from '../types';
import { Itranslation } from 'types';

const PaymentInitiationRestrictionsModal: React.FC<IPaymentInitiationRestrictionsModalProps> = ({
    AppStore,
    PaymentInitiationRestrictionsModalStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'configuration-paymentInit',
        'common',
    ]);

    const {
        queryOptionsList,
        query,
        handleClose,
        setQuery,
        handleFilterOptions,
        checkValueSuggestionType,
        showSuggestions,
        setShowSuggestions,
        activeSuggestion,
        setActiveSuggestion,
        appendQueryRule,
        type,
        handleSubmit,
        loading,
        isValidParenthesis,
        errorText,
        previousQuery,
        handleModify,
        onClick,
        handleChange,
        isDisable,
        isPreviousRule,
        name,
        previousRuleName,
    } = PaymentInitiationRestrictionsModalStore;

    const _handleClose = () => {
        PaymentInitiationRestrictionsModalStore.closeModal();
        handleClose();
    };

    const _handleFilter = () => {
        handleFilterOptions();
    };

    const _onChange = (event: React.ChangeEvent<any>) => {
        setQuery(event.target.value);
        checkValueSuggestionType();
        _handleFilter();
    };

    const handleSelect = (event: React.ChangeEvent<any>) => {
        appendQueryRule(event.currentTarget.innerText);
        setActiveSuggestion(0);
        setShowSuggestions(false);
        checkValueSuggestionType();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            appendQueryRule(queryOptionsList[activeSuggestion]);
            setActiveSuggestion(0);
            setShowSuggestions(false);
            checkValueSuggestionType();
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1);
        } else if (e.keyCode === 40) {
            if (activeSuggestion === queryOptionsList.length - 1) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1);
        }
    };

    return (
        <DialogWrapper
            open={PaymentInitiationRestrictionsModalStore.isOpen}
            className={'PaymentInitiationRestrictionsModal'}
            title={t(`${PaymentInitiationRestrictionsModalStore.type}`)}
            maxWidth={'md'}
            onClose={_handleClose}
            hideDivider>
            <div className={'formContainer'}>
                <h4 className={'description'}>{t('description')}</h4>
                {(type === 'modifyRestriction' || type === 'modifyRule') && !isPreviousRule && (
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextFieldWrapper
                                name={'previousRuleName'}
                                label={t('previousRuleName')}
                                value={previousRuleName}
                                disabled
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={12} className={'previousQuery'}>
                            <TextField
                                name={'previousQuery'}
                                value={previousQuery}
                                label={t('previousQuery')}
                                disabled
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextFieldWrapper
                            name="name"
                            type="text"
                            label={t('name')}
                            value={name}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name={'query'}
                            value={query}
                            label={t('query')}
                            onChange={_onChange}
                            onKeyDown={handleKeyDown}
                            onFocus={onClick}
                            autoComplete={'off'}
                            placeholder={t('enterQuery')}
                            error={!isValidParenthesis}
                            onClick={onClick}
                            helperText={!isValidParenthesis ? errorText : ''}
                        />
                        {showSuggestions && queryOptionsList.length > 0 && (
                            <Paper elevation={3} className={'optionsList'}>
                                <List>
                                    {queryOptionsList.map((value, index) => {
                                        let className = '';
                                        if (index === activeSuggestion) {
                                            className = 'activeSuggestion';
                                        }
                                        return (
                                            <ListItem
                                                className={`optionItem ${className}`}
                                                key={value}
                                                disableGutters
                                                onClick={handleSelect}>
                                                <ListItemText primary={`${value}`} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={_handleClose}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            className={'saveButton'}
                            onClick={
                                type === 'addRestriction' || type === 'addRule'
                                    ? handleSubmit
                                    : handleModify
                            }
                            loading={loading}
                            disabled={isDisable}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore')(observer(PaymentInitiationRestrictionsModal));
