import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import PaymentInitiationRestrictions from './PaymentInitiationRestrictions';

function TabPaymentInitiationRestrictions({ ConfigurationStore }) {
    return (
        <div className={'TabPaymentInitiationRestrictions'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <PaymentInitiationRestrictions
                        PaymentInitiationRestrictionsStore={
                            ConfigurationStore.PaymentInitiationRestrictionsStore
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
}

TabPaymentInitiationRestrictions.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabPaymentInitiationRestrictions));
