import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { VRP } from 'constants/permissions';
import Consent from '../Consent';

function TabVRPConsent({ ConfigurationStore }) {
    return (
        <div className={'TabVRPConsent'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <Consent
                        CustomizationStore={ConfigurationStore.CustomizationStore}
                        type={VRP}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

TabVRPConsent.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabVRPConsent));
