import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import CodeIcon from '@material-ui/icons/Code';
import LinkIcon from '@material-ui/icons/Link';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

// align
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';

// indent
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';

import React from 'react';

export default {
    bold: {
        icon: <FormatBoldIcon />,
        type: 'mark',
    },
    italic: {
        icon: <FormatItalicIcon />,
        type: 'mark',
    },
    underline: {
        icon: <FormatUnderlinedIcon />,
        type: 'mark',
    },
    link: {
        icon: <LinkIcon />,
        type: 'link',
    },
    linkV2: {
        icon: <LinkIcon />,
        type: 'linkV2',
    },
    code: {
        icon: <CodeIcon />,
        type: 'mark',
    },
    'heading-one': {
        icon: <LooksOneIcon />,
        type: 'block',
    },
    'heading-two': {
        icon: <LooksTwoIcon />,
        type: 'block',
    },
    'block-quote': {
        icon: <FormatQuoteIcon />,
        type: 'block',
    },
    'numbered-list': {
        icon: <FormatListNumberedIcon />,
        type: 'block',
    },
    'bulleted-list': {
        icon: <FormatListBulletedIcon />,
        type: 'block',
    },
    image: {
        icon: <InsertPhotoIcon />,
        type: 'image',
    },
    left: {
        icon: <FormatAlignLeftIcon />,
        type: 'align',
    },
    center: {
        icon: <FormatAlignCenterIcon />,
        type: 'align',
    },
    right: {
        icon: <FormatAlignRightIcon />,
        type: 'align',
    },
    'indent-increase': {
        icon: <FormatIndentIncreaseIcon />,
        type: 'indent',
    },
    'indent-decrease': {
        icon: <FormatIndentDecreaseIcon />,
        type: 'indent',
        value: 10,
    },
};
