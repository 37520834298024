import _get from 'lodash/get';
import { acceptedTags, closingTags, mappedTags, property, type } from './util';
import getAllMatchedGroups from '../get-all-matched-groups/get-all-matched-groups';
import escapeHTML from '../escape-html/escape-html';

/*
    FLOW TO DESERIALIZE:
    Look at the specs file to have an idea for the possible inputs and outputs.
    Here, the idea is to break down the html into all the childs we have for the type tags and then wrap all the property tags into them.
    To make this happen, we basically take out all the html tags with the same rank and then iterate over the inner tags.
    Specific type tags are explicitly mentioned as they all need an extra work compared to the rest.

    As of now, dated 13th Aug 2021, we are now supporting aligns (left, center, right) within editor.
    To make it happen we have to wrap all the types within a single div with approrpriate class (rt-left, rt-center, rt-right).
*/
export default function deserializeSlate(text) {
    if (text.includes('<div class="TextArea-flex-box"')) {
        return deserializeMinSlate(text);
    } else if (text.includes('<div class=')) {
        const initValue = [];
        const topOrder = {};
        topOrder.type = 'paragraph';
        const classRegex = /class="(.*?)"/gi;
        const align = getAllMatchedGroups(classRegex, text);
        topOrder.align = align[0].split('-')[1];
        const firstClose = text.indexOf('>');
        const lastOpen = text.lastIndexOf('<');
        topOrder.children = deserializeMinSlate(text.slice(firstClose + 1, lastOpen));
        initValue.push(topOrder);
        return initValue;
    }
    return deserializeMinSlate(text);
}

function deserializeMinSlate(text) {
    const initValue = [];
    const startTags = getOrderedList([], text.trim());
    if (startTags.length > 0) {
        startTags.map((tag) => {
            const value = {};
            value.type = _get(type, tag.tag, 'paragraph');
            value.children = populateChildren(tag, text);
            if (tag.tag === '<div class="TextArea-flex-box">') {
                value.indent = 'indent-increase';
                value.indentPadding = tag.indentPadding;
            }
            initValue.push(value);
        });
    } else {
        initValue.push({
            type: 'paragraph',
            children: [{ text: escapeHTML(text) }],
        });
    }
    return initValue;
}

const populateChildren = (tag, text, props = undefined) => {
    const children = [];
    const childs = getOrderedList([], getChildren(tag.tag, text, tag.rank));
    if (childs.length > 0) {
        childs.forEach((c) => {
            if (c.tag === '<a href=' || c.tag === '<a target="_blank" href=') {
                const urlRegex = /href="(.*?)"/gi;
                const url = getAllMatchedGroups(urlRegex, getChildren(tag.tag, text, tag.rank));
                // check for just immediate text before pushing
                if (!(children.length > 0 && children[children.length - 1].text)) {
                    children.push({
                        text: '',
                    });
                }
                const mainChild = {};
                mainChild.type = 'link';
                mainChild.url = url[c.rank];
                mainChild.children = populateChildren(
                    c,
                    getChildren(tag.tag, text, tag.rank, props),
                );
                children.push(mainChild);
                children.push({
                    text: '',
                });
            } else if (c.tag === '<li>') {
                const mainChild = {};
                mainChild.type = 'list-item';
                mainChild.children = populateChildren(
                    c,
                    getChildren(tag.tag, text, tag.rank, props),
                );
                children.push(mainChild);
            } else if (c.tag === 'text') {
                const tagText = c.tagText;
                if (
                    children.length > 0 &&
                    Object.prototype.hasOwnProperty.call(children[children.length - 1], 'text') &&
                    !(
                        Object.prototype.hasOwnProperty.call(
                            children[children.length - 1],
                            'bold',
                        ) ||
                        Object.prototype.hasOwnProperty.call(
                            children[children.length - 1],
                            'italic',
                        ) ||
                        Object.prototype.hasOwnProperty.call(
                            children[children.length - 1],
                            'code',
                        ) ||
                        Object.prototype.hasOwnProperty.call(
                            children[children.length - 1],
                            'underline',
                        )
                    )
                ) {
                    children[children.length - 1].text += tagText;
                } else {
                    children.push({
                        text: tagText,
                    });
                }
            } else if (c.tag === '<image src=' || c.tag === '<img class="img-consent" src=') {
                const urlRegex = /src="(.*?)"/gi;
                const url = getAllMatchedGroups(urlRegex, getChildren(tag.tag, text, tag.rank));
                const mainChild = {};
                mainChild.type = 'image';
                mainChild.url = url[c.rank];
                mainChild.children = [{ text: '' }];
                children.push(mainChild);
            } else if (c.tag === '<span class=') {
                children.push(...populateChildren(c, getChildren(tag.tag, text, tag.rank)));
            } else {
                let newProps = property[c.tag];
                if (props) {
                    newProps = {
                        ...props,
                        ...property[c.tag],
                    };
                }
                children.push(
                    ...populateChildren(c, getChildren(tag.tag, text, tag.rank), newProps),
                );
            }
        });
    } else {
        if (props) {
            children.push({
                text: getChildren(tag.tag, text, tag.rank),
                ...props,
            });
        } else if (tag.tag === '<br>') {
            children.push({
                text: '',
            });
        } else {
            children.push({
                text: getChildren(tag.tag, text, tag.rank) || text,
            });
        }
    }
    return children;
};

const getChildren = (tag, text, rank) => {
    if (tag === '<br>') {
        return '';
    }
    const regex = RegExp(`${_get(mappedTags, tag, tag)}(.*?)${closingTags[tag]}`, 'ig');
    const children = getAllMatchedGroups(regex, text);
    return children[rank];
};

const getOrderedList = (list, text, prevIndex = 0, parent = text, afterImgTag = false) => {
    if (!text) return [];
    if (afterImgTag && !text.includes('<')) {
        const appendHtml = '/image>';
        text = appendHtml.concat(text);
    }
    if (text.indexOf('>') === -1 && text.indexOf('<') === -1) {
        return [];
    }
    // starting index
    let start = text.indexOf('<');

    // populate inner text
    let endOfTag = 0;
    if (text.indexOf('<') !== -1 && text.indexOf('>') > text.indexOf('<')) {
        endOfTag = 0;
    } else endOfTag = text.indexOf('>') + 1;
    if (text.indexOf('<') === -1) {
        start = text.length;
    }

    // grab all the text within the tag
    const beforeTagText = text.slice(endOfTag, start);
    if (beforeTagText) {
        list.push({
            tag: 'text',
            rank: 0,
            tagText: beforeTagText,
        });
    }
    let tag = '<';
    for (let i = start + 1; i < text.length; i++) {
        if (acceptedTags.includes(tag)) {
            break;
        }
        tag += text[i];
    }
    if (closingTags[tag]) {
        // check for already existed tag
        let rank = 0;
        const existingList = list.filter((l) => l.tag === tag);
        if (existingList.length > 0) {
            rank = existingList.length;
        }
        if (prevIndex > 0) {
            const prev = parent.slice(0, parent.indexOf(text));
            const newRank = getAllMatchedGroups(
                RegExp(`${_get(mappedTags, tag, tag)}(.*?)${closingTags[tag]}`, 'ig'),
                prev,
            ).length;
            if (newRank > rank) {
                rank = newRank;
            }
        }
        const element = {
            tag,
            rank,
        };
        if (tag === '<div class="TextArea-flex-box">') {
            const classRegex = /class="(.*?)"/gi;
            const indentClasses = getAllMatchedGroups(classRegex, text);
            const indentPadding = indentClasses.find((element) => element.includes('Indent-'));
            element['indentPadding'] = parseInt(indentPadding.split('-')[1]);
        }
        list.push(element);
        const newIndex = text.indexOf(closingTags[tag]) + 1;
        if (newIndex !== 0 && (text[newIndex - 1] !== '>' || text[newIndex] !== undefined)) {
            const afterImgTag = text[newIndex - 1] === '>';
            list = getOrderedList(
                list,
                text.slice(newIndex, text.length),
                newIndex,
                parent,
                afterImgTag,
            );
        }
    }
    return list;
};
