import React, { useEffect, useState } from 'react';

import RichEditor from 'components/common/wrappers/rich-editor';

// helpers
import deserializeSlate from 'helpers/deserialize-slate/deserialize';
import { Descendant } from 'slate';

type DeserializeSlate =
    | {
          type: string;
          children: {
              text: string;
          }[];
      }[]
    | {
          type: string;
          align: any;
          children: {
              type: string;
              children: {
                  text: string;
              }[];
          }[];
      }[];

interface Props {
    plainText: string;
    onChange: (x: DeserializeSlate) => void;
    placeholder: string;
    disabled: string[];
    autoFocus: boolean;
    deps: string;
}

function RichEditorWrapper({ plainText, onChange, placeholder, disabled, autoFocus, deps }: Props) {
    useEffect(() => {
        const x = deserializeSlate(plainText);
        onChange(x);
        setValue(x);
    }, [deps]);

    const [value, setValue] = useState(deserializeSlate(plainText));

    const _onChange = (v: Descendant[] | any) => {
        onChange(v);
        setValue(v);
    };

    return (
        <RichEditor
            value={value}
            setValue={_onChange}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    );
}

export default RichEditorWrapper;
