import { Grid } from '@material-ui/core';
import React from 'react';
import ProductVersions from './ProductVersions';
import { AppStoreProps } from 'types';
import ConfigurationStore from '../ConfigurationStore';
import { inject, observer } from 'mobx-react';
interface props extends AppStoreProps {
    ConfigurationStore?: ConfigurationStore;
}

const TabProductVersions: React.FC<props> = ({ ConfigurationStore }) => {
    return (
        <div className={'TabWebhook'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <ProductVersions ProductVersionsStore ={ConfigurationStore!.ProductVersionsStore}  />
                </Grid>
            </Grid>
        </div>
    );
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabProductVersions));
