import { Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';

import GenericErrorModal from 'components/common/modals/error/GenericError';
import GenericWarningModal from 'components/common/modals/warning/GenericWarning';
import TableWrapper, { cellConstructor } from 'components/common/wrappers/TableWrapper';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import PaymentInitiationRestrictionsModal from 'components/common/modals/payment-initiation-restrictions/PaymentInitiationRestrictionsModal';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';

function PaymentInitiationRestrictions({ AppStore, PaymentInitiationRestrictionsStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration-paymentInit');

    const {
        TableWrapperStore,
        handleFetch,
        loading,
        restrictions,
        updateSelectRow,
        selectedRestriction,
        deletePaymentRestrictions,
        addRestriction,
        GenericErrorStore,
        restrictionId,
        isRule,
        addPaymentRestrictionRule,
        modifyPaymentRestrictionRule,
        deletePaymentRestrictionRule,
    } = PaymentInitiationRestrictionsStore;

    useEffect(() => {
        handleFetch(AppStore);
    }, []);

    const cellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'id', field: 'id' }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'productType',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'name',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'text',
            }),
    ];

    const header = [t('id'), t('productType'), t('name'), t('text')];

    const _renderButtons = () => {
        return (
            <>
                {restrictionId && (
                    <Grid item xs={2}>
                        <ButtonWrapper
                            className="addRuleButton"
                            onClick={addPaymentRestrictionRule}>
                            {t('addRule')}
                        </ButtonWrapper>
                    </Grid>
                )}
                {isRule ? (
                    <>
                        <Grid item xs={2}>
                            <ButtonWrapper
                                className="modifyRuleButton"
                                onClick={() => modifyPaymentRestrictionRule('modifyRule')}>
                                {t('modifyRule')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonWrapper
                                className="deleteRuleButton"
                                onClick={deletePaymentRestrictionRule}>
                                {t('deleteRule')}
                            </ButtonWrapper>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={2}>
                            <ButtonWrapper
                                className="modifyButton"
                                disabled={
                                    isEmpty(selectedRestriction) ||
                                    isEmpty(selectedRestriction.childrenList)
                                }
                                onClick={() => modifyPaymentRestrictionRule('modifyRestriction')}>
                                {t('modify')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={2}>
                            <ButtonWrapper
                                className="deleteRestrictionButton"
                                disabled={isEmpty(selectedRestriction)}
                                onClick={deletePaymentRestrictions}>
                                {t('delete')}
                            </ButtonWrapper>
                        </Grid>
                    </>
                )}
            </>
        );
    };

    return (
        <div className={'PaymentInitiationRestrictionsMain'}>
            <h2 className={'title'}>{t('paymentRestriction')}</h2>
            <DefaultContainer>
                <Grid container justify="flex-end" spacing={3}>
                    <Grid item xs={2}>
                        <ButtonWrapper className="addButton" onClick={addRestriction}>
                            {t('add')}
                        </ButtonWrapper>
                    </Grid>
                    {restrictions.length && !loading ? _renderButtons() : ''}
                </Grid>
                <TableWrapper
                    header={header}
                    enablePagination
                    cellTemplates={cellTemplates}
                    noDataMessage={t('noRestrictions')}
                    TableWrapperStore={TableWrapperStore}
                    loading={loading}
                    callback={updateSelectRow}
                    disableRowSelect={false}
                    canExpand
                />
            </DefaultContainer>
            <CustomThemeProvider alt>
                <GenericWarningModal
                    Store={
                        PaymentInitiationRestrictionsStore.PaymentInitiationRestrictionsWarningModal
                    }
                    onClick={() =>
                        PaymentInitiationRestrictionsStore.handleDelete(
                            PaymentInitiationRestrictionsStore
                                .PaymentInitiationRestrictionsWarningModal.type,
                        )
                    }
                    title={t('warningModalTitle')}
                    buttonText={t('delete')}
                    loading={PaymentInitiationRestrictionsStore.loading}
                />
                <PaymentInitiationRestrictionsModal
                    PaymentInitiationRestrictionsModalStore={
                        PaymentInitiationRestrictionsStore.PaymentInitiationRestrictionsModalStore
                    }
                />
                <GenericErrorModal Store={GenericErrorStore} />
            </CustomThemeProvider>
        </div>
    );
}

PaymentInitiationRestrictions.propTypes = {
    AppStore: PropTypes.object.isRequired,
    PaymentInitiationRestrictionsStore: PropTypes.object.isRequired,
};

export default inject('AppStore')(observer(PaymentInitiationRestrictions));
