import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ManageIpAddresses from 'components/configuration/manage-ip-addresses/ManageIpAddresses';

function TabManageIpAddresses({ ConfigurationStore }) {
    return (
        <div className={'TabManageIpAddresses'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={9}>
                    <ManageIpAddresses
                        ManageIpAddressesStore={ConfigurationStore.ManageIpAddressesStore}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

TabManageIpAddresses.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabManageIpAddresses));
