export default function escapeEmptyTags(str) {
    return emptyTags.includes(str) ? '' : str;
}

const emptyTags = [
    '<ol><li></li></ol>',
    '<div></div>',
    '<ul><li></li></ul>',
    '<h1></h1>',
    '<h2></h2>',
    '<blockquote><div></div></blockquote>',
];
