import { acceptImage } from '../helpers/images';

const withDragAndDropImages = (editor, addSnack, captureImage) => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = (element) => {
        return element.type === 'image' ? true : isInline(element);
    };

    editor.isVoid = (element) => {
        if (element.type === 'image') {
            return true;
        }
        return isVoid(element);
    };

    editor.insertData = async (data) => {
        const { files } = data;
        if (files && files.length > 0 && captureImage) {
            for (const file of files) {
                acceptImage(editor, file, addSnack);
            }
        } else {
            insertData(data);
        }
    };

    return editor;
};

export default withDragAndDropImages;
