import ModalStore from 'components/common/modals/ModalStore';
import { action, observable } from 'mobx';
import ConfigurationStore from '../ConfigurationStore';
import axios from 'axios';

class ProductVersionsStore {
    constructor(public rootStore: ConfigurationStore) {
        this.rootStore = rootStore;
    }

    GenericSuccessStore = new ModalStore();
    GenericErrorStore = new ModalStore();
    @observable
    form: { [k: string]: boolean } = {
        SIPV1: false,
        SIPV2: false,
    };

    @observable
    isFormFilled = false;
    @observable
    clientId = '';
    @observable
    permissions:string[] = [];

    @action
    handleFetch = async () => {
        try {
            const res =  await axios.get(`/api/admin/product-versions/${this.clientId}`);
            action(() => {
                this.form = {...res.data};})();

        } catch (err) {
            console.error(err);
        }

    }

    @action
    handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.form[event.target.name] = event.target.checked;
        this.isFormFilled = Object.values(this.form).includes(true) ? true : false;
    };

    @action
    handleSubmit = async () => {
        try {
            const body = {
                form: this.form,
                clientId: this.clientId,
            };

            await axios.post('/api/admin/product-versions',body);
            this.GenericSuccessStore.openModal();
        } catch (error) {
            console.error(error);
            this.GenericErrorStore.openErrorModal(error);
        }
    }

    @action
    setPermissionsAndClientId = (permissions: string[], clientId: string) => {
        this.permissions = permissions;
        this.clientId = clientId;
    }
}

export default ProductVersionsStore;
