import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { PIS } from '../../../../../constants/permissions';
import Consent from '../Consent';

function TabPISConsent({ ConfigurationStore }) {
    return (
        <div className={'TabPISConsent'}>
            <Grid container item xs={12}>
                <Grid item xs={12} lg={8}>
                    <Consent
                        CustomizationStore={ConfigurationStore.CustomizationStore}
                        type={PIS}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

TabPISConsent.propTypes = {
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(TabPISConsent));
