module.exports = {
    LIST_TYPES: ['numbered-list', 'bulleted-list'],
    MAX_IMAGE_SIZE: 102400,
    HOTKEYS: {
        'mod+b': 'bold',
        'mod+i': 'italic',
        'mod+u': 'underline',
    },
    IGNORED_HOTKEYS: ['&', '<', '>'],
    MAX_IMAGES: 3,
    CLIPBOARD_LIMIT: 1700, // limits upto 518 bytes of image to be pasted and allows max 1700 characters for plain text.
    MAX_INDENT_PADDING: 30,
};
