import React from 'react';

// material-ui
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// helpers
import sanitizeHTML from 'helpers/sanitize-html/sanitize-html';

interface IProps {
    html: string;
    isOnlyDisplay: boolean;
    formatHtml: (t: string) => string | Node;
    disabled?: boolean;
}

function Preview({ html, isOnlyDisplay, formatHtml, disabled }: IProps) {
    const classes = useStyles({ disabled });
    if (!html) {
        return null;
    }

    return (
        <Paper className={isOnlyDisplay ? classes.displayOnly : classes.preview} elevation={3}>
            <div
                id={'custom-text'}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(formatHtml(html)),
                }}></div>
        </Paper>
    );
}

Preview.defaultProps = {
    formatHtml: (html: string) => html,
};

const useStyles = makeStyles(() => ({
    preview: {
        width: '390px',
        minHeight: '150px',
        padding: '15px 15px',
        opacity: (props: { disabled?: boolean }) => (props.disabled ? '50%' : '100%'),
    },
    displayOnly: {
        width: '431px',
        minHeight: '150px',
        padding: '15px 15px',
        whitesSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        opacity: (props: { disabled?: boolean }) => (props.disabled ? '50%' : '100%'),
    },
}));

export default Preview;
