import axios from 'axios';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';

import GeneralStore from '../../general/GeneralStore';
import ModalStore from 'components/common/modals/ModalStore';
import TableWrapperStore from 'components/common/wrappers/TableWrapperStore';

//helpers
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import conditional from 'helpers/conditional/conditional';
import getKeyArray from 'helpers/get-key-array/get-key-array';
import getUniqueRecordsByKey from 'helpers/get-unique-by-key/get-unique-by-key';
import sortedCountries from 'helpers/sorted-countries/sorted-countries';
import asyncReadFile from 'helpers/async-read-file/asyncReadFile';
import modifyGlobalCssProperty from 'helpers/modify-global-css-property/modifyGlobalCssProperty';

// constants
import { CUSTOM, ENABLE_ALL } from 'constants/bankSelectionPresets';
import { BANK_SELECTION } from 'constants/configurationTypes';
import { TERMS_AND_CONDITIONS_KEYS } from 'constants/translationMessageKeys';
import { RESELLER, UNDER_RESELLER } from 'constants/clientType';
import { getTranslations } from 'constants/defaultWebAppTranslations';
import { getTAndCMessages } from 'constants/tAndCMessages';
import {
    AIS,
    PIS,
    CBPII,
    VARIABLE_RECURRING_PAYMENT,
    STANDING_ORDER,
    SINGLE_IMMEDIATE_PAYMENT,
    FUTURE_DATED,
    BULK_TRANSFER,
} from 'constants/memberPermissions';

const { publicRuntimeConfig } = require('next/config').default();
const { apiUrl } = publicRuntimeConfig;

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

const partialMatch = (a, b) => {
    if (!a || !b) return false;
    return a.toLowerCase().includes(b.toLowerCase());
};

class FeatureStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
        this.CountryModalStore = new ModalStore();
        this.TableWrapperStore = new TableWrapperStore();
        this.GeneralStore = new GeneralStore(this);
    }

    httpClient = axios.create({
        baseURL: apiUrl,
        headers: {
            'token-dev-key': 'global-test',
        },
    });
    defaultFeatures = {
        colors: {},
        fonts: {},
        enableTspConfig: false,
        supportPsr44Flow: false,
        customWebAppUrl: '',
        supportRecentBanks: false,
        displayLogoOnBankRedirectScreen: false,
        isEnfuse: false,
        enableWebApp: false,
        displayBeneficiaryDetails: false,
        footerText: '',
        enableBankSelectionForTpps: false,
        countries: {},
        enableSplashScreen: false,
        splashScreen: '',
        tppLogo: null,
        ais: null,
        pis: null,
        displayWebAppBackButton: true,
        country: '',
        bankGroup: '',
        bankName: '',
        modifyPreSelectedBank: false,
        enableTextInsteadOfLogo: false,
        productName: '',
        productLogo: null,
        tppLogoSplashScreen: null,
        webAppButtonsAlignment: 'ROW',
        regionalConfigurations: {},
        hideDefaultCountriesAndBanks: false,
        enableAcceptButtonWithCustomText: false,
    };
    classifiedFeatures = {
        supportPsr44Flow: false,
        supportRecentBanks: false,
        enableBankSelectionForTpps: false,
        displayBeneficiaryDetails: false,
        displayLogoOnBankRedirectScreen: false,
        enableSplashScreen: false,
        displayWebAppBackButton: false,
        hideDefaultCountriesAndBanks: false,
    };
    rowsLimit = 200;
    rowsPerPage = 25;
    helpTextFields = [
        'supportPsr44Flow',
        'customWebAppUrl',
        'webAppButtonsAlignment',
        'enableAcceptButtonWithCustomText',
    ];

    initialFeatures = null;
    @observable
    displayName = '';
    @observable
    features = { ...this.defaultFeatures };
    @observable
    displayFeatures = { ...this.defaultFeatures };
    @observable
    realmFeatures = { ...this.defaultFeatures };
    @observable
    memberFeatures = { ...this.defaultFeatures };
    @observable
    permittedCountries = [];
    @observable
    banks = [];
    @observable
    paginatedBanks = [];
    @observable
    checkIds = {};
    @observable
    bankGroups = [];

    @observable
    clientType = '';
    @observable
    memberId = '';
    @observable
    memberEmail = '';
    @observable
    realmId = '';
    @observable
    permissions = [];
    @observable
    maxPages = 0;
    @observable
    showNoConfigurations = undefined;
    @observable
    resellerLogo = {};
    @observable
    loading = false;
    @observable
    tableLoading = false;
    @observable
    updateLoading = false;
    @observable
    fetchBanksMemberId = '';
    @observable
    rootLoading = false;
    @observable
    language = 'en';
    @observable
    pageName = 'bankSelector';
    @observable
    defaultWebAppTranslations = {};
    @observable
    webAppTranslations = {};
    @observable
    region = 'eu';
    @observable
    regionalConfigurations = {};
    @observable
    tAndCTranslations = {};
    @observable
    parentCssData = {}; // Reseller CSS Data
    @observable
    tppCssData = {}; // Under Reseller Css Data
    @observable
    scope = '';
    @observable
    bankSelectionPreset = ENABLE_ALL;
    @observable
    parentBankSelectionPreset = '';

    @action
    setLanguage = (lang) => {
        this.language = lang;
    };
    @action
    setRegion = (region) => {
        this.region = region;
    };

    @action
    populateWebAppTranslations = (translations) => {
        Object.keys(getTranslations).map((page) => {
            Object.keys(getTranslations[page]).map((lang) => {
                this.defaultWebAppTranslations = {
                    ...this.defaultWebAppTranslations,
                    ...getTranslations[page][lang],
                };
            });
        });
        this.defaultWebAppTranslations = {
            ...this.defaultWebAppTranslations,
            ...translations,
        };
        this.webAppTranslations = translations;
    };
    @action
    setToDefault = () => {
        const defaultTranslations = getTranslations[this.pageName][this.language];
        this.defaultWebAppTranslations = {
            ...this.defaultWebAppTranslations,
            ...defaultTranslations,
        };
        this.webAppTranslations = {
            ...this.webAppTranslations,
            ...getTranslations[this.pageName][this.language],
        };
    };
    @action
    handleDisplayWebAppTextTranslationChange = (event) => {
        this.defaultWebAppTranslations[event.target.name] = event.target.value;
        this.webAppTranslations[event.target.name] = event.target.value;
    };
    @action
    handleTextTranslationChange = (event) => {
        this[event.target.name] = event.target.value;
    };
    @action
    handleUpload = flow(
        function* (event, type) {
            const file = event.target.files[0];
            const data = yield asyncReadFile(file, true);
            this.displayFeatures[type] = {
                data,
                mimeType: file.type,
            };
        }.bind(this),
    );

    @action
    openCountryModal = () => {
        this.CountryModalStore.openModal();
    };

    @action
    getPrevFeatures = () => {
        this.displayFeatures = _cloneDeep(this.features);
        this.regionalConfigurations = this.displayFeatures.regionalConfigurations;
    };

    @action
    populateTAndCRegionalConfigurations = () => {
        const defaultRegion = 'default';
        Object.keys(getTAndCMessages).map((type) => {
            ['eu', 'gb'].map((region) => {
                this.tAndCTranslations = {
                    ...this.tAndCTranslations,
                    [region]: {
                        translations: {
                            ...this.tAndCTranslations[region]?.translations,
                            ...getTAndCMessages[type],
                        },
                    },
                };
            });
        });
        if ('default' in this.regionalConfigurations) {
            ['eu', 'gb'].map((reg) => {
                const { translations } = this.regionalConfigurations['default'];
                Object.keys(translations).forEach((tr) => {
                    if (tr.includes('consent.termsAndConditions.loader')) {
                        if (
                            (tr === 'aisp.consent.termsAndConditions.loader' &&
                                'en.aisp.consent.termsAndConditions.loader' in
                                    this.regionalConfigurations[defaultRegion].translations) ||
                            (tr === 'pisp.consent.termsAndConditions.loader' &&
                                'en.pisp.consent.termsAndConditions.loader' in
                                    this.regionalConfigurations[defaultRegion].translations) ||
                            (tr === 'cbpii.consent.termsAndConditions.loader' &&
                                'en.cbpii.consent.termsAndConditions.loader' in
                                    this.regionalConfigurations[defaultRegion].translations)
                        ) {
                            // do nothing
                        } else if (
                            (tr === 'aisp.consent.termsAndConditions.loader' &&
                                !(
                                    'en.aisp.consent.termsAndConditions.loader' in
                                    this.regionalConfigurations[defaultRegion].translations
                                )) ||
                            (tr === 'cbpii.consent.termsAndConditions.loader' &&
                                !(
                                    'en.cbpii.consent.termsAndConditions.loader' in
                                    this.regionalConfigurations[defaultRegion].translations
                                )) ||
                            (tr === 'pisp.consent.termsAndConditions.loader' &&
                                !(
                                    'en.pisp.consent.termsAndConditions.loader' in
                                    this.regionalConfigurations[defaultRegion].translations
                                ))
                        ) {
                            this.tAndCTranslations[reg] = {
                                translations: {
                                    ...this.tAndCTranslations[reg].translations,
                                    ['en.' + tr]: {
                                        ...this.tAndCTranslations[reg].translations['en.' + tr],
                                        text: this.regionalConfigurations[defaultRegion]
                                            .translations[tr],
                                    },
                                },
                            };
                        } else {
                            this.tAndCTranslations[reg] = {
                                translations: {
                                    ...this.tAndCTranslations[reg].translations,
                                    [tr]: {
                                        ...this.tAndCTranslations[reg].translations[tr],
                                        text: this.regionalConfigurations[defaultRegion]
                                            .translations[tr],
                                    },
                                },
                            };
                        }
                    }
                });
            });
        }

        Object.keys(this.regionalConfigurations).forEach((reg) => {
            if (reg !== 'default') {
                const { translations } = this.regionalConfigurations[reg];
                Object.keys(translations).forEach((tr) => {
                    if (tr.includes('consent.termsAndConditions.loader')) {
                        this.tAndCTranslations[reg] = {
                            translations: {
                                ...this.tAndCTranslations[reg].translations,
                                [tr]: {
                                    ...this.tAndCTranslations[reg].translations[tr],
                                    text: this.regionalConfigurations[reg].translations[tr],
                                },
                            },
                        };
                    }
                });
            }
        });
    };

    @action
    fetchPermittedCountries = flow(
        function* () {
            this.updateLoading = true;

            try {
                const realmFeatureCountries =
                    this.realmId && this.realmFeatures.countries
                        ? Object.keys(this.realmFeatures.countries)
                        : [];

                let realmCountries = [];
                if (this.realmId && this.parentBankSelectionPreset === ENABLE_ALL) {
                    const realmCountriesRes = yield axios.get(`/countries/${this.realmId}`);
                    realmCountries = _get(realmCountriesRes, 'data.countries', []);
                }
                const memberCountriesRes = yield axios.get(`/countries/${this.memberId}`);
                const memberCountries = _get(memberCountriesRes, 'data.countries', []);

                this.fetchBanksMemberId = conditional(
                    this.refId,
                    this.memberId,
                    conditional(
                        this.realmId && this.rootStore.memberType === 'TYPE_ONE',
                        this.realmId,
                        conditional(memberCountries.length, this.memberId, this.realmId),
                    ),
                );

                const memberFeatureCountries = conditional(
                    this.memberFeatures?.countries,
                    Object.keys(this.memberFeatures.countries),
                    [],
                );

                const countries = conditional(
                    this.refId,
                    conditional(
                        this.parentBankSelectionPreset === ENABLE_ALL,
                        memberCountries,
                        memberFeatureCountries,
                    ),
                    conditional(
                        this.realmId && this.rootStore.memberType === 'TYPE_ONE',
                        conditional(
                            this.parentBankSelectionPreset === ENABLE_ALL,
                            realmCountries,
                            realmFeatureCountries,
                        ),
                        conditional(
                            memberCountries.length,
                            memberCountries,
                            conditional(
                                this.parentBankSelectionPreset === ENABLE_ALL,
                                realmCountries,
                                realmFeatureCountries,
                            ),
                        ),
                    ),
                );

                // Prevents a type 2 tpp with configurations that inherited a reseller's TI config to show the reseller's countries values.
                // Eliminates the possibility for a type 2 tpp to have access to countries without configurations
                if (
                    Object.keys(this.initialFeatures).length === 0 &&
                    memberCountries.length &&
                    this.rootStore.memberType === 'TYPE_TWO'
                ) {
                    this.displayFeatures = {
                        ...this.displayFeatures,
                        countries: {},
                    };
                    // Also updates features only to prevent the "Unsaved Changes" tab from displaying
                    this.features = {
                        ...this.displayFeatures,
                        countries: {},
                    };
                }

                if (Object.keys(this.displayFeatures.countries)[0]) {
                    this.displayFeatures = {
                        ...this.displayFeatures,
                        country: Object.keys(this.displayFeatures.countries)[0],
                    };
                }

                this.permittedCountries = sortedCountries(countries.filter((c) => c !== 'US'));
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.updateLoading = false;
            }
        }.bind(this),
    );

    @action
    handleTranslationChange = (name, value) => {
        this.regionalConfigurations = {
            ...this.regionalConfigurations,
            [this.region]: {
                translations: {
                    ...this.regionalConfigurations[this.region]?.translations,
                    [`${this.language}.${name}`]: value,
                },
            },
        };
    };

    @action
    handleConsentSave = (key, value) => {
        this.displayFeatures.regionalConfigurations = {
            ...this.displayFeatures.regionalConfigurations,
            [this.region]: {
                translations: {
                    ...this.displayFeatures.regionalConfigurations[this.region]?.translations,
                    [`${this.language}.${key}`]: value,
                },
            },
        };
        this.tAndCTranslations[this.region].translations[`${this.language}.${key}`].text = value;
    };

    @action
    handleBankSelectionPresetChange = (selection, ref) => {
        this.setBankSelectionPreset(selection);
        if (selection === CUSTOM) {
            setTimeout(() => {
                ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500);
        }
    };

    @action
    setBankSelectionPreset = (bankSelectionPresetValue) => {
        this.bankSelectionPreset = bankSelectionPresetValue;
    };

    @action
    handleChange = (event) => {
        if (event.target.name === 'countries') {
            const value = event.target.value;
            const tempCountries = { ...this.displayFeatures.countries };
            const countries = {};
            value
                .filter((f) => f !== 'all')
                .forEach((v) => {
                    countries[v] = tempCountries[v] || [];
                });

            const existing = Object.keys(countries).map((c) => c);

            this.displayFeatures = {
                ...this.displayFeatures,
                countries,
                country: conditional(existing[0], existing[0], ''),
            };

            return;
        }

        this.displayFeatures[event.target.name] = event.target.value;

        if (event.target.name === 'country') {
            this.displayFeatures = {
                ...this.displayFeatures,
                country: event.target.value,
            };
        }
    };

    @action
    handleBanksSave = async (checkIds, country) => {
        this.displayFeatures.country = country;
        Object.values(this.displayFeatures.countries[this.displayFeatures.country]).map((bank) => {
            if (!(bank in checkIds)) {
                checkIds[bank] = true;
            }
        });
        const banks = Object.keys(checkIds)
            .filter((c) => c !== 'all')
            .filter((c) => !this.bankGroups.includes(c))
            .filter((c) => checkIds[c] === true)
            .map((c) => c);

        this.displayFeatures.countries[this.displayFeatures.country] = banks;
        await this.handleSectionSave();
        this.handleClear();
    };

    @action
    handleCheck = (event) => {
        if (event.target.name === 'webAppButtonsAlignment') {
            if (event.target.checked) {
                this.displayFeatures[event.target.name] = 'COLUMN';
            } else {
                this.displayFeatures[event.target.name] = 'ROW';
            }
        } else {
            this.displayFeatures[event.target.name] = event.target.checked;
        }
    };

    @action
    handleBankCheck = (e) => {
        const { name, checked } = e.target;
        const filtered = this.paginatedBanks;
        if (name === 'all') {
            this.getNonBankGroupBanks(filtered)
                .map((b) => b.id)
                .map((b) => {
                    this.checkIds[b] = checked;
                });
            this.bankGroups.map((bG) => {
                this.handleBankCheck({
                    target: {
                        name: bG,
                        checked,
                    },
                });
            });
        } else if (this.bankGroups.includes(name)) {
            const bankIds = filtered.filter((b) => b.bankGroup === name).map((b) => b.id);
            bankIds.map((b) => {
                this.checkIds[b] = checked;
            });
        }
        this.checkIds[name] = checked;

        // bank-group bubble check
        this.bankGroups.map((bG) => {
            const bankIds = filtered.filter((b) => b.bankGroup === bG).map((b) => b.id);
            this.checkIds[bG] = bankIds.reduce((acc, value) => acc && this.checkIds[value], true);
        });
    };

    @action
    handleSectionSave = flow(
        function* () {
            this.loading = true;
            try {
                this.features = this.filteredFeatures();
                const response = yield this.getFeatures(this.getCurrentFeatureId());
                const {
                    configurations: { regionalConfigurations: prevRegionalConfigurations },
                    bankSelectionPreset,
                } = response.data;
                this.features.regionalConfigurations = {
                    ...prevRegionalConfigurations,
                };
                yield axios.post('/api/tppIntegration/features', {
                    memberId: this.getCurrentFeatureId(),
                    features: this.features,
                    bankSelectionPreset,
                });
                const res = yield this.getFeatures(this.getCurrentFeatureId());
                this.setFeatures(res.data.configurations);
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleBankSelectionSave = flow(
        function* () {
            this.loading = true;
            try {
                this.features = this.filteredFeatures();
                const response = yield this.getFeatures(this.getCurrentFeatureId());
                const {
                    configurations: { regionalConfigurations: prevRegionalConfigurations },
                } = response.data;
                this.features.regionalConfigurations = {
                    ...prevRegionalConfigurations,
                };
                yield axios.post('/api/tppIntegration/features', {
                    memberId: this.getCurrentFeatureId(),
                    features: this.features,
                    bankSelectionPreset: this.bankSelectionPreset,
                    configurationType: BANK_SELECTION,
                });
                const res = yield this.getFeatures(this.getCurrentFeatureId());
                const { configurations, bankSelectionPreset } = res.data;
                this.setFeatures(configurations);
                this.setBankSelectionPreset(bankSelectionPreset);
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleTAndCSectionSave = flow(
        function* () {
            this.loading = true;
            try {
                this.features = this.filteredFeatures();
                const response = yield this.getFeatures(this.getCurrentFeatureId());
                const {
                    configurations: { regionalConfigurations: prevRegionalConfigurations },
                    bankSelectionPreset,
                } = response.data;
                let translations = {};
                Object.values(this.consentKeys()).map((key) => {
                    const value = this.regionalConfigurations?.[this.region]?.translations?.[
                        `${this.language}.${key}`
                    ];
                    translations = {
                        ...translations,
                        ...(value && {
                            [`${this.language}.${key}`]: value,
                        }),
                    };
                });
                this.features.regionalConfigurations = {
                    ...prevRegionalConfigurations,
                    [this.region]: {
                        translations: {
                            ...this.features.regionalConfigurations?.[this.region]?.translations,
                            ...translations,
                        },
                    },
                };
                yield axios.post('/api/tppIntegration/features', {
                    memberId: this.getCurrentFeatureId(),
                    features: this.features,
                    bankSelectionPreset,
                });
                const res = yield this.getFeatures(this.getCurrentFeatureId());
                this.setFeatures(res.data.configurations);
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleDisplayWebAppTranslationsSave = flow(
        function* () {
            this.loading = true;
            try {
                this.features = this.filteredFeatures();
                const response = yield this.getFeatures(this.getCurrentFeatureId());
                const {
                    configurations: { regionalConfigurations: prevRegionalConfigurations },
                    bankSelectionPreset,
                } = response.data;
                const temp = {};
                Object.keys(this.webAppTranslations).map((t) => {
                    if (!(t.includes('aisp.consent.text') || t.includes('pisp.consent.text'))) {
                        temp[t] = this.webAppTranslations[t];
                    }
                });
                this.features.regionalConfigurations = {
                    ...prevRegionalConfigurations,
                    ['default']: {
                        translations: {
                            ...prevRegionalConfigurations?.['default']?.translations,
                            ...temp,
                        },
                    },
                };

                yield axios.post('/api/tppIntegration/features', {
                    memberId: this.getCurrentFeatureId(),
                    features: this.features,
                    bankSelectionPreset,
                });
                const res = yield this.getFeatures(this.getCurrentFeatureId());
                this.setFeatures(res.data.configurations);
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    filteredFeatures = () => {
        const { regionalConfigurations } = this.displayFeatures;
        let temp = {};
        Object.keys(regionalConfigurations).map((reg) => {
            Object.keys(regionalConfigurations[reg].translations).map((trans) => {
                if (regionalConfigurations[reg].translations[trans]) {
                    temp = {
                        ...temp,
                        [reg]: {
                            translations: {
                                ...regionalConfigurations[reg].translations,
                                [trans]: regionalConfigurations[reg].translations[trans],
                            },
                        },
                    };
                }
            });
        });
        temp['default'] = {
            translations: {
                ...temp['default']?.translations,
                ...this.webAppTranslations,
            },
        };
        return {
            ...this.displayFeatures,
            regionalConfigurations: temp,
            splashScreen: conditional(
                !this.displayFeatures.enableSplashScreen,
                '',
                this.displayFeatures.splashScreen,
            ),
        };
    };

    @action
    handleTablePageChange = (currentPage, oldBanks = this.filteredBanks()) => {
        this.maxPages = oldBanks.length;
        const page = (currentPage - 1) * this.rowsPerPage;
        let max = page + this.rowsPerPage;
        if (max > this.maxPages) max = this.maxPages;
        this.paginatedBanks = oldBanks.slice(page, max);
        this.updateTableData(currentPage);
    };

    @action
    fetchPermittedBanks = flow(
        function* (isEditable, country) {
            const countries = this.displayFeatures.countries;

            let response = [];
            if (country) {
                response = yield this.fetchBanks();
                response = response.filter((b) => b.countries?.includes(country));
                if (!isEditable) {
                    response = response.filter((b) =>
                        conditional(
                            countries[country] && countries[country].length > 0,
                            countries[country].includes(b.id),
                            false,
                        ),
                    );
                }
            }
            response = getUniqueRecordsByKey(response, 'id');
            this.banks = response;
            this.banks = this.filteredBanks();
            this.maxPages = this.banks.length;
            this.paginatedBanks = this.banks.slice(0, this.rowsPerPage);
            this.checkIds = {};
            this.updateTableData(1);
        }.bind(this),
    );

    @action
    updateTableData = (currentPage) => {
        const countries = this.displayFeatures.countries;
        const { country } = this.displayFeatures;
        this.bankGroups = getKeyArray(this.paginatedBanks, 'bankGroup');
        const customizedFilteredBanks = this.getCustomizedBanks(this.paginatedBanks);
        this.paginatedBanks.map((b) => {
            this.checkIds[b.id] = countries[country] ? countries[country].includes(b.id) : false;
        });
        this.bankGroups.map((bG) => {
            const bankIds = this.paginatedBanks.filter((b) => b.bankGroup === bG).map((b) => b.id);
            this.checkIds[bG] = bankIds.reduce((acc, value) => acc && this.checkIds[value], true);
        });
        this.checkIds['all'] = false;
        const pageNumber = Math.ceil(this.maxPages / this.rowsPerPage);
        this.TableWrapperStore.setPageNumber(pageNumber, currentPage);
        this.TableWrapperStore.setMaxRows(this.rowsPerPage);
        this.TableWrapperStore.setData(customizedFilteredBanks);
    };

    @action
    getFilteredBanks = () => {
        this.handleTablePageChange(1, this.filteredBanks());
    };

    @action
    fetchBanks = flow(
        function* () {
            this.tableLoading = true;
            try {
                const banksPromises = [];
                let url = this.constructUrl();
                const res = yield this.httpClient.get(`/banks?${url.toString()}`);
                const resBanks = _get(res, 'data.banks', []);
                const paging = _get(res, 'data.paging', {
                    pageCount: 0,
                });
                const maxPages = paging.pageCount;

                for (let i = 2; i <= maxPages; i++) {
                    url = this.constructUrl(i);
                    banksPromises.push(this.httpClient.get(`/banks?${url.toString()}`));
                }
                const banks = [];
                const response = yield Promise.all(banksPromises);

                banks.push(...resBanks);
                response.forEach((l) => {
                    return banks.push(...l.data.banks);
                });
                return banks;
            } catch (e) {
                console.error(e);
                this.tableLoading = false;
                return [];
            } finally {
                this.tableLoading = false;
            }
        }.bind(this),
    );

    @action
    updateCssFeaturesHierarchically = (currentMemberData, parentData) => {
        const { configurations: parentDataConfigurations } = parentData.data;
        this.parentCssData = parentDataConfigurations;
        const { configurations: currentMemberDataConfigurations } = currentMemberData.data;
        const currentMemberConfigurations = {
            ...currentMemberDataConfigurations,
            colors: {
                ...parentDataConfigurations.colors,
                ...currentMemberDataConfigurations.colors,
            },
            fonts: {
                ...parentDataConfigurations.fonts,
                ...currentMemberDataConfigurations.fonts,
            },
            tppLogo: {
                ...parentDataConfigurations.tppLogo,
                ...currentMemberDataConfigurations.tppLogo,
            },
        };
        currentMemberData.data = {
            ...currentMemberData.data,
            configurations: currentMemberConfigurations,
        };
        return currentMemberData;
    };

    updateWebappPreviewStyles = () => {
        modifyGlobalCssProperty(
            '--webapp-font-size-xsmall',
            this.features.fonts?.['font-size-xsmall'],
        );
        modifyGlobalCssProperty(
            '--webapp-font-size-medium',
            this.features.fonts?.['font-size-medium'],
        );
        modifyGlobalCssProperty(
            '--webapp-font-weight-light',
            this.features.fonts?.['font-weight-light'],
        );
        modifyGlobalCssProperty('--webapp-font-family', this.features.fonts?.['font-primary']);
        modifyGlobalCssProperty(
            '--webapp-color-body-text',
            this.features.colors?.['color-body-text'],
        );
        modifyGlobalCssProperty('--webapp-color-link', this.features.colors?.['color-link']);
        modifyGlobalCssProperty(
            '--webapp-color-focus-outline',
            this.features.colors?.['color-focus-outline'],
        );
    };

    @action
    handleFetchFeatures = flow(
        function* () {
            try {
                this.rootLoading = true;
                // init all required fields for feature tabs to work
                this.clientType = this.rootStore.clientType;
                this.memberId = this.rootStore.memberId;
                this.refId = this.rootStore.refId;
                this.memberEmail = this.rootStore.memberEmail;
                this.realmId = this.rootStore.realmId;
                this.permissions = this.rootStore.permissions;
                this.memberType = this.rootStore.memberType;
                this.scope = this.rootStore.scope;

                let res = yield this.getFeatures(this.getCurrentFeatureId());
                const { configurations, bankSelectionPreset } = res.data;
                this.initialFeatures = configurations;
                this.setBankSelectionPreset(bankSelectionPreset);
                if (this.refId) {
                    const memberRes = yield this.getFeatures(this.memberId);
                    const {
                        configurations: memberConfigurations,
                        bankSelectionPreset,
                    } = memberRes.data;
                    this.parentBankSelectionPreset = bankSelectionPreset;
                    this.memberFeatures = memberConfigurations;
                    if (_isEmpty(configurations)) {
                        res = memberRes;
                    }
                    this.tppCssData = memberConfigurations;
                    res = this.updateCssFeaturesHierarchically(res, memberRes);
                }
                if (this.realmId) {
                    const realmRes = yield this.getFeatures(this.realmId);
                    const {
                        configurations: resellerConfigurations,
                        bankSelectionPreset,
                    } = realmRes.data;
                    this.parentBankSelectionPreset = conditional(
                        this.parentBankSelectionPreset,
                        this.parentBankSelectionPreset,
                        bankSelectionPreset,
                    );
                    this.realmFeatures = resellerConfigurations;
                    this.resellerLogo = this.customizeLogo(resellerConfigurations?.tppLogo);
                    if (_isEmpty(res.data.configurations)) {
                        res = realmRes;
                    }
                    res = this.updateCssFeaturesHierarchically(res, realmRes);
                }
                this.setFeatures({ ...this.defaultFeatures, ...res.data.configurations });
                this.rootLoading = false;
                this.populateWebAppTranslations(
                    this.filteredFeatures().regionalConfigurations['default'].translations,
                );
                this.updateWebappPreviewStyles();
            } catch (e) {
                console.error(e);
            }
        }.bind(this),
    );

    @action
    setFeatures = (data) => {
        this.features = _isEmpty(data) ? { ...this.defaultFeatures } : data;
        this.features = {
            ...this.features,
            regionalConfigurations: {
                ...this.regionalConfigurations,
                ...this.features.regionalConfigurations,
            },
            country: '',
            bankGroup: '',
            bankName: '',
        };
        this.features.tppLogo = this.customizeLogo(this.features.tppLogo);
        this.features.productLogo = this.customizeLogo(this.features.productLogo);
        this.features.tppLogoSplashScreen = this.customizeLogo(this.features.tppLogoSplashScreen);
        this.displayFeatures = _cloneDeep(this.features);
    };

    @action
    getFeatures = async (memberId) => {
        return axios.get(`/api/tppIntegration/features/${memberId}`);
    };

    @action
    handleClear = (isEditable = false) => {
        const existing = Object.keys(this.displayFeatures.countries).map((c) => c);
        this.displayFeatures = {
            ...this.displayFeatures,
            country: existing.length > 0 ? existing[0] : '',
            bankGroup: '',
            bankName: '',
        };
        this.fetchPermittedBanks(isEditable, existing.length > 0 ? existing[0] : '');
    };

    @action
    initializeStore = (displayFeatures, fetchBanksMemberId, cb) => {
        this.displayFeatures = displayFeatures;
        this.fetchBanksMemberId = fetchBanksMemberId;
        cb();
    };

    @action
    fetchResellersLogo = flow(
        function* () {
            if (this.clientType === UNDER_RESELLER) {
                const realmFeatures = yield this.getFeatures(this.realmId);
                const { configurations } = realmFeatures.data;
                this.resellerLogo = this.customizeLogo(configurations?.tppLogo);
            }
        }.bind(this),
    );

    @action
    setDisplayFeatures = (displayFeatures) => {
        this.displayFeatures = { ...displayFeatures };
    };

    getCurrentFeatureId = () => {
        return this.refId ? this.refId : this.memberId;
    };

    constructUrl = (page = 1) => {
        const url = new URLSearchParams();
        url.append('countries', this.displayFeatures.country);
        url.append('memberId', this.fetchBanksMemberId);
        url.append('page', page);
        url.append('perPage', this.rowsLimit);

        return url;
    };

    // fetch consentKeys as per the permissions provided
    consentKeys = () => {
        const permissions = this.permissions;
        const keys = [];

        if (permissions.includes(AIS)) {
            keys.push(TERMS_AND_CONDITIONS_KEYS[AIS]);
        }

        if (
            // PIS permission is a set of STANDING_ORDER, SINGLE_IMMEDIATE_PAYMENT, FUTURE_DATED, BULK_TRANSFER
            permissions.includes(STANDING_ORDER) ||
            permissions.includes(SINGLE_IMMEDIATE_PAYMENT) ||
            permissions.includes(FUTURE_DATED) ||
            permissions.includes(BULK_TRANSFER) ||
            permissions.includes(PIS)
        ) {
            keys.push(TERMS_AND_CONDITIONS_KEYS[PIS]);
        }

        if (permissions.includes(VARIABLE_RECURRING_PAYMENT)) {
            keys.push(TERMS_AND_CONDITIONS_KEYS[VARIABLE_RECURRING_PAYMENT]);
        }

        if (permissions.includes(CBPII)) {
            keys.push(TERMS_AND_CONDITIONS_KEYS[CBPII]);
        }

        return keys;
    };

    customizeLogo = (logo) => {
        if (!logo?.data) return null;
        return {
            data: `data:${logo.type};base64,${logo.data}`,
            mimeType: logo.type,
        };
    };

    displayFieldsForBankSelection = () => {
        const fields = [];
        if (_isEmpty(this.displayFeatures.countries)) {
            fields.push('hideDefaultCountriesAndBanks');
        }
        fields.push('supportRecentBanks');
        if (this.clientType === RESELLER) {
            fields.push('enableBankSelectionForTpps');
        }
        return fields;
    };

    filteredBanks = () => {
        let fB = this.banks;
        const { bankGroup, bankName } = this.displayFeatures;
        if (bankGroup) {
            fB = fB.filter((b) => partialMatch(b.bankGroup, bankGroup));
        }
        if (bankName) {
            fB = fB.filter((b) => partialMatch(b.name, bankName));
        }
        return fB;
    };

    getNonBankGroupBanks = (filteredBanks) => {
        return filteredBanks.filter(
            (b) => !Object.prototype.hasOwnProperty.call(b, 'bankGroup') || b.bankGroup === '',
        );
    };

    getCustomizedBanks = (filteredBanks) => {
        const nonBankGroupBanks = this.getNonBankGroupBanks(filteredBanks);
        const customizedFilteredBanks = [...nonBankGroupBanks];
        getKeyArray(filteredBanks, 'bankGroup').forEach((bG) => {
            const banks = filteredBanks.filter((b) => b.bankGroup === bG);
            customizedFilteredBanks.push({
                country: banks[0].country,
                bankGroup: bG,
                section: banks, // compulsory for collapsible table
                isSectionOpen: true, // compulsory for collapsible table
            });
        });
        return customizedFilteredBanks;
    };

    // only for testing
    @action
    updateDisplayCountries = (countries) => {
        this.displayFeatures.countries = countries;
    };
}

export default FeatureStore;
